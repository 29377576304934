import React from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  AudioTransformDevice,
  Device,
  VoiceFocusModelName,
  VoiceFocusTransformDevice,
} from 'amazon-chime-sdk-js'
import {
  BackgroundBlurProvider,
  BackgroundReplacementProvider,
  MeetingProvider,
  useVoiceFocus,
  VoiceFocusProvider,
} from 'amazon-chime-sdk-component-library-react'
import { DeviceSetup, Meeting } from '../../views'
import meetingConfig from '../../meetingConfig'
import { useMeetingState } from '../../providers/MeetingStateProvider'
import { VideoFiltersCpuUtilization } from '../../types'
import LaunchWebinar from '../../views/LaunchWebinar'
import { NavigationProvider } from '../../providers/NavigationProvider'
import MeetingEventObserver from '../MeetingEventObserver'
import NoMeetingRedirect from '../NoMeetingRedirect'

const MeetingProviderWithDeviceReplacement: React.FC = ({ children }) => {
  const { addVoiceFocus } = useVoiceFocus()

  const onDeviceReplacement = (
    nextDevice: string,
    currentDevice: Device | AudioTransformDevice
  ): Promise<Device | VoiceFocusTransformDevice> => {
    if (currentDevice instanceof VoiceFocusTransformDevice) {
      return addVoiceFocus(nextDevice)
    }
    return Promise.resolve(nextDevice)
  }

  const meetingConfigValue = {
    ...meetingConfig,
    enableWebAudio: true,
    onDeviceReplacement,
  }

  return <MeetingProvider {...meetingConfigValue}>{children}</MeetingProvider>
}

const MeetingProviderWrapper: React.FC = () => {
  const { isWebAudioEnabled, videoTransformCpuUtilization, imageBlob, joinInfo } = useMeetingState()

  const isFilterEnabled = videoTransformCpuUtilization !== VideoFiltersCpuUtilization.Disabled

  const meetingConfigValue = {
    ...meetingConfig,
    enableWebAudio: isWebAudioEnabled,
  }


  const getMeetingProviderWrapper = () => {
    return (
      <>
        <NavigationProvider>
          <Routes>
            <Route path={'/'} element={<LaunchWebinar />} />
            <Route
              path={`/devices-selection`}
              element={
                <NoMeetingRedirect>
                  <DeviceSetup />
                </NoMeetingRedirect>
              }
            />
            <Route
              path={`/join`}
              element={
                <NoMeetingRedirect>
                  <MeetingModeSelector />
                </NoMeetingRedirect>
              }
            />
          </Routes>
        </NavigationProvider>
        <MeetingEventObserver />
      </>
    )
  }

  function voiceFocusName(name: string): VoiceFocusModelName {
    if (name && ['default', 'ns_es'].includes(name)) {
      return name as VoiceFocusModelName
    }
    return 'default'
  }

  function getVoiceFocusSpecName(): VoiceFocusModelName {
    if (joinInfo && joinInfo.meeting?.audio?.EchoReduction === 'AVAILABLE') {
      return voiceFocusName('ns_es')
    }
    return voiceFocusName('default')
  }

  const vfConfigValue = {
    spec: { name: getVoiceFocusSpecName() },
    createMeetingResponse: joinInfo,
  }

  const getMeetingProviderWrapperWithVF = (children: React.ReactNode) => {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <VoiceFocusProvider {...vfConfigValue}>
        <MeetingProviderWithDeviceReplacement>{children}</MeetingProviderWithDeviceReplacement>
      </VoiceFocusProvider>
    )
  }

  const getWrapperWithVideoFilter = (children: React.ReactNode) => {
    let filterCPUUtilization = parseInt(videoTransformCpuUtilization, 10)
    if (!filterCPUUtilization) {
      filterCPUUtilization = 40
    }
    return (
      <BackgroundBlurProvider options={{ filterCPUUtilization }}>
        <BackgroundReplacementProvider options={{ imageBlob, filterCPUUtilization }}>
          {children}
        </BackgroundReplacementProvider>
      </BackgroundBlurProvider>
    )
  }

  const getMeetingProvider = (children: React.ReactNode) => {
    return <MeetingProvider {...meetingConfigValue}>{children}</MeetingProvider>
  }

  const getMeetingProviderWithFeatures = (): React.ReactNode => {
    let children = getMeetingProviderWrapper()

    if (isFilterEnabled) {
      children = getWrapperWithVideoFilter(children)
    }
    if (isWebAudioEnabled) {
      children = getMeetingProviderWrapperWithVF(children)
    } else {
      children = getMeetingProvider(children)
    }
    return children
  }

  return (
    <>{imageBlob === undefined ? <div>Loading Assets</div> : getMeetingProviderWithFeatures()}</>
  )
}

const MeetingModeSelector: React.FC = () => {
  return <Meeting />
}

export default MeetingProviderWrapper
