import React, { useMemo } from 'react'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'

import CourseProgressionGrid, { CourseProgressionSkeleton } from 'components/CourseProgressionGrid'
import { useLoadCourseFilteredList } from 'services/query'
import { filterFromQuery } from 'services/navigation/course'

import NoContent from 'components/NoContent'

const CoursesContainer: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const filter = useMemo(() => filterFromQuery(search), [search])
  const {
    isFetched,
    isLoading,
    isError,
    data: filteredCourseList,
  } = useLoadCourseFilteredList(filter)


  if (isError) {
    return null
  }

  if (!isFetched || isLoading) {
    return <CourseProgressionSkeleton />
  }

  if (filteredCourseList.length <= 0) {
    return <NoContent text={t('courses.noCourseCategory')} />
  }
  return <CourseProgressionGrid courses={filteredCourseList} />
}

CoursesContainer.displayName = 'CoursesContainer'

export default CoursesContainer
