export const userList = () => ['USER_LIST']
export const currentUser = () => [...userList(), 'CURRENT_USER']
export const currentUserAgreement = () => [...userList(), 'CURRENT_USER', 'AGREEMENT']
export const userById = (userId: number) => [...userList(), userId]
export const categoryList = () => 'CATEGORY_LIST'
export const companyList = () => ['COMPANY_LIST'];
export const companyById = (companyId: number) => [...companyList(), companyId];
export const courseList = () => ['COURSES']
export const enrolledCourseList = () => [...courseList(), 'ENROLLED']
export const quizCourse = (courseId) => [...courseList(), courseId, 'QUIZ']
export const quizLearningPath = (learningPathId) => ['LEARNING_PATH_LIST', learningPathId, 'QUIZ']
export const courseDetailByCode = (code: string) => [...courseList(), 'DETAIL', code]
export const learningPathDetailByCode = (code: number) => [...learningPathList(), 'DETAIL', code]
export const bscCertificateByTransactionHash = (transactionHash: string) => ['BSC', 'CERTIFICATE', transactionHash]
export const webinarList = () => ['WEBINAR_LIST']
export const webinarDetailByCode = (code: string) => [...webinarList(), 'DETAIL', code]
export const usersByCourseCodeAndWebinarCode = (courseCode: string, editionCode: string) => [...userList(), ...courseList(), ...webinarList(), courseCode, editionCode]
export const webinarEditionList = (code: string) => [...webinarDetailByCode(code), 'EDITIONS']
export const learningPathList = () => ['LEARNING_PATH_LIST']
export const settings = () => 'SETTINGS'
