import React from 'react'
import { WebinarDetail as WebinarDetailType } from 'types/store'
import Info from './Info'
import { Col } from 'antd'
import Thumbnail from './Thumbnail'

interface Props {
  webinar: WebinarDetailType
}

const WebinarDescription: React.FC<Props> = ({ webinar }) => {
  return (
    <Col>
      <Thumbnail webinar={webinar} />
      <Col span={24}>
        <Info webinar={webinar} />
      </Col>
    </Col>
  )
}

WebinarDescription.displayName = 'WebinarDescription'

export default WebinarDescription
