import React, { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalHeader,
  PrimaryButton,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react'

import { getErrorContext } from '../../providers/ErrorProvider'
import Card from '../../components/Card'
import Spinner from '../../components/icons/Spinner'
import DevicePermissionPrompt from '../DevicePermissionPrompt'
import { useMeetingState } from '../../providers/MeetingStateProvider'
import { MeetingMode } from '../../types'
import routes from '../../config/routes'
import { endMeetingForAll, endWebinar, joinWebinar, launchWebinar } from '../../api/webinar'
import { currentEditionSelector } from '../../store/Course/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useLoadCurrentUser, useLoadWebinarByCode } from '../../services/query'
import { editionStatuses } from '../../constants'
import { getAttendeeNameByWebinarCodeAndEditionCodeAndAttendeeId } from '../../api/user'
import { GetAttendeeResponse } from '../../providers/VideoTileGridProvider/state'
import { Space } from 'antd'
import { useTranslation } from 'react-i18next'
import * as appActions from '../../store/App/actions'

export const createGetAttendeeCallback =
  (webinarCode: string, editionCode: string) =>
  (attendeeId: string): Promise<GetAttendeeResponse> =>
    getAttendeeNameByWebinarCodeAndEditionCodeAndAttendeeId(webinarCode, editionCode, attendeeId)

const MeetingForm: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const meetingManager = useMeetingManager()
  const { code } = useParams()
  const currentEdition = useSelector(currentEditionSelector)
  const { data: webinar } = useLoadWebinarByCode(code)
  const { data: user } = useLoadCurrentUser()

  const isWebinarTeacher = user.id === webinar.teacher.id

  const { meetingId, meetingMode, setMeetingMode, setMeetingId, setLocalUserName } =
    useMeetingState()

  const [isLoading, setIsLoading] = useState(false)
  const { errorMessage, updateErrorMessage } = useContext(getErrorContext())
  const history = useNavigate()

  const handleJoinMeeting = async (e: React.FormEvent) => {
    dispatch(appActions.setApplicationHeader(false))

    e.preventDefault()

    setIsLoading(true)

    try {
      let joinInfo

      if (currentEdition.status === editionStatuses.started)
        joinInfo = await joinWebinar(code, currentEdition.code)
      else if (isWebinarTeacher && currentEdition.status === editionStatuses.notStarted)
        joinInfo = await launchWebinar(code, currentEdition.code)
      else return

      meetingManager.getAttendee = createGetAttendeeCallback(webinar.code, currentEdition.code)
      setLocalUserName(`${user.name} ${user.surname}`)

      await meetingManager.join({
        meetingInfo: joinInfo?.meeting,
        attendeeInfo: joinInfo?.attendee,
      })

      if (meetingMode === MeetingMode.Spectator) {
        await meetingManager.start()
        history(routes.webinar.url)
      } else {
        setMeetingMode(MeetingMode.Attendee)
        history(routes.deviceSelection.url)
      }
    } catch (error) {
      updateErrorMessage((error as Error).message)
    }
  }

  const closeError = (): void => {
    updateErrorMessage('')
    setMeetingId('')
    setLocalUserName('')
    setIsLoading(false)
  }

  const label = (): string => {
    if (isWebinarTeacher && currentEdition.status === editionStatuses.notStarted)
      return t('webinar.launchEdition')
    if (isWebinarTeacher && currentEdition.status === editionStatuses.started)
      return t('webinar.rejoinEdition')
    if (!isWebinarTeacher && currentEdition.status === editionStatuses.started)
      return t('webinar.joinEdition')
  }

  const endForAll = () => endMeetingForAll(code, currentEdition.code, meetingManager, history)

  setLocalUserName(`${user.name} ${user.surname}`)

  return (
    <form>
      <Heading tag="h1" level={4} css="display: flex; justify-content: center; align-items: center">
        Webinar Console
      </Heading>
      <Space style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {isLoading ? <Spinner /> : <PrimaryButton label={label()} onClick={handleJoinMeeting} />}
        {isWebinarTeacher && currentEdition.status === editionStatuses.started && (
          <PrimaryButton label={t('webinar.endEditionForAll')} onClick={endForAll} />
        )}
      </Space>
      {errorMessage && (
        <Modal size="md" onClose={closeError}>
          <ModalHeader title={`Webinar ID: ${meetingId}`} />
          <ModalBody>
            <Card
              title="Unable to join webinar"
              description="There was an issue finding that webinar. The webinar may have already ended, or your authorization may have expired."
              smallText={errorMessage}
            />
          </ModalBody>
        </Modal>
      )}
      <DevicePermissionPrompt />
    </form>
  )
}

export default MeetingForm
