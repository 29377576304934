import {Col, Typography} from 'antd'
import {EyeFilled, EyeInvisibleFilled} from '@ant-design/icons'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import style from './styles.module.scss'
import ReactPlayer from 'react-player'
import StillWatchingPage from "../../pages/StillWatchingPage/StillWatchingPage";
import * as faceapi from "face-api.js";


interface Props {
  url: string
  title: string
  caption: string
  checkUserAttention: boolean
  /**
   * @param progress the progress expressed as a fraction
   */
  onProgress: (progress: number) => void
  onCompleted: () => void
}

const CourseVideo: React.FC<Props> = ({
                                        url,
                                        title,
                                        caption,
                                        checkUserAttention,
                                        onProgress,
                                        onCompleted,
                                      }) => {

  const [mustCheckUser, setMustCheckUser] = useState(checkUserAttention)

  const [showGreenEye, setShowGreenEye] = useState(false)
  const [showRedEye, setShowRedEye] = useState(false)

  // const [userHasWebcam, setUserHasWebcam] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [pauseVideo, setPauseVideo] = useState(false)

  // const [webcamStreaming, setWebcamStreaming] = useState(false)
  const [webcam, setWebcam] = useState(null)

  const playerRef = useRef(null)

  const [checkWebcam, setCheckWebcam] = useState(false)
  const [checkAttention, setCheckAttention] = useState(false)
  // The two intervals are exclusive, I cannot set both intervals at the same time
  const [checkInterval, setCheckInterval] = useState(null)
  const [alreadyPlayed, setAlreadyPlayed] = useState(false)


  const setCheckWebcamInterval = () => {
    setShowRedEye(false)
    setShowGreenEye(true)
    const timer = setInterval(() => {
      setCheckWebcam((value) => !value)
    }, 5000);

    setCheckInterval(timer)
  }

  const clearCheckWebcamInterval = () => {
    clearInterval(checkInterval)
  }

  const setCheckAttentionInterval = () => {
    setShowRedEye(false)
    setShowGreenEye(true)
    const timer = setInterval(() => {
      setCheckAttention((value) => !value)
    }, 300000)

    setCheckInterval(timer)
  }

  const clearCheckAttentionInterval = () => {
    clearInterval(checkInterval)
  }

  const handlePlay = async () => {

    // code that must be executed the first time that the video is played
    if (!alreadyPlayed) {

      if (mustCheckUser) {
        const devices = await navigator.mediaDevices.enumerateDevices()
        const hasWebcam = !!devices.find(device => device.kind === "videoinput")
        // setUserHasWebcam(hasWebcam)

        if (hasWebcam) {
          try {
            const stream = await navigator.mediaDevices.getUserMedia({video: true});
            const video = document.createElement("video");
            video.srcObject = stream;
            await video.play();

            setWebcam(video);
            // setWebcamStreaming(true);
            setCheckWebcamInterval()

          } catch (e) {
            setCheckAttentionInterval()
          }
        } else {
          setCheckAttentionInterval()
        }

        setAlreadyPlayed(true)
      }
    }

    setPauseVideo(false);
  }

  const handlePause = () => {
    setPauseVideo(true)
  }


  useEffect(() => {
    if (!alreadyPlayed)
      return

    // console.log('USE: checking webcam')

    if (showDialog)
      return

    if (pauseVideo)
      return

    faceapi.nets.tinyFaceDetector.loadFromUri("/models").then(async () => {
      const detections = await faceapi.detectAllFaces(webcam, new faceapi.TinyFaceDetectorOptions())
      // console.log('detections', detections.length)
      const faceDetected = detections.length > 0

      setShowGreenEye(faceDetected);
      setShowRedEye(!faceDetected);
      setShowDialog(!faceDetected)
      setPauseVideo(!faceDetected)


      if (!faceDetected)
        clearCheckWebcamInterval()
    })
  }, [checkWebcam])

  useEffect(() => {
    // console.log('+++checking with dialog', {
    //   showDialog,
    //   showRedEye,
    //   showGreenEye,
    //   pauseVideo
    // })

    if (!alreadyPlayed)
      return

    // console.log('USE: checking attention')

    if (showDialog)
      return

    if (pauseVideo)
      return


    setShowDialog(true)
    setShowRedEye(true)
    setShowGreenEye(false)
    setPauseVideo(true)

    clearCheckAttentionInterval()

  }, [checkAttention])

  const onDismiss = async () => {
    await setPauseVideo(false)
    await setShowGreenEye(true)
    await setShowRedEye(false)
    await setShowDialog(false)

    if (webcam) {
      setCheckWebcamInterval()
    } else {
      setCheckAttentionInterval()
    }
  }

  const handleProgress = useCallback(
    (state: { played: number }) => {
      onProgress(state.played)
    },
    [onProgress]
  )

  return (
    <Col md={24} className={style['course-video']}>
      <StillWatchingPage onDismiss={onDismiss} isVisible={showDialog}></StillWatchingPage>
      <Typography.Title className="m-10 mb-20" level={3}>
        {title} {showGreenEye && <EyeFilled style={{color: 'green'}}/>} {showRedEye &&
        <EyeInvisibleFilled style={{color: 'red'}}/>}
      </Typography.Title>
      <ReactPlayer
        playing={alreadyPlayed && !pauseVideo}
        ref={playerRef}
        width="100%"
        controls={!showRedEye}
        url={url}
        onPlay={handlePlay}
        onPause={handlePause}
        onProgress={handleProgress}
        onEnded={onCompleted}
        progressInterval={5000}
      />

      <Typography.Text className="m-10 caption">{caption}</Typography.Text>
    </Col>
  )
}

CourseVideo.displayName = 'CourseVideo'

export default (CourseVideo)
