export enum RequestStatus {
  None = 0,
  Requesting = 1,
  Success = 2,
  Error = 3,
}

export enum ErrorCode {
  Unknown = 0,
  Api = 1,
  Parameters = 3,
  PageUndefined = 4,
  GraphQlApi = 11,
  BootstrapFailed = 101,
  Login = 102,
  Register = 103,
  RegisterCodeNotFound = 104,
  RegisterInvalidPassword = 105,
  RegisterEmailTaken = 106,
  UserAlreadyRegistered = 107,
  AuthenticationFailed = 108,
  GenericError = 999,
}

export enum LoadingState {
  Unset = 'unset',
  Loading = 'loading',
  Loaded = 'loaded',
  LoadFailure = 'load failure',
  Deleting = 'deleting',
  Deleted = 'deleted',
  DeleteFailed = 'deleting failed',
  Creating = 'creating',
  Created = 'created',
  CreateFailure = 'created failure',
  Updating = 'updating',
  Updated = 'updated',
  UpdateFailure = 'update failure',
}

export enum EventType {
  Course = 'COURSE',
  Webinar = 'WEBINAR',
}

export enum EventOrder {
  ByCreationDate = 'BY_CREATION_DATE',
  ByEnrollmentDate = 'BY_ENROLLMENT_DATE',
  Alphabetic = 'ALPHABETIC',
  AlphabeticInverted = 'ALPHABETIC_INVERTED',
}

export enum CourseStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Closed = 'CLOSED',
}
