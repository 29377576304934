import { getApplicationError } from 'core'
import Debug from 'debug'
import { BusinessUnitListData, CategoryListData, UserDetailData, UserListData } from 'types/api'
import { ErrorCode } from 'types/enums'
import { Category } from 'types/store'
import { BusinessUnit, Progression, User, UserRole } from 'types/store/user'
import { mapNumber, mapString } from './common'
import { mapWebinarEditionList } from './webinar'

const debug = Debug('services:map:user')

export const mapBusinessUnit = (data: BusinessUnitListData): BusinessUnit => {
  try {
    return {
      code: data.code,
      name: data.name,
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapRole = (data: UserListData['roles'][0]): UserRole => {
  switch (data) {
    case 'ADMIN':
      return UserRole.ADMIN
    case 'COMPANY_ADMIN':
      return UserRole.COMPANY_ADMIN
    case 'COMPANY_PM':
      return UserRole.COMPANY_PM
    case 'COMPANY_TEACHER':
      return UserRole.COMPANY_TEACHER
    case 'COMPANY_USER':
      return UserRole.COMPANY_USER
    case 'STANDARD_TEACHER':
      return UserRole.STANDARD_TEACHER
    case 'STANDARD_USER':
      return UserRole.STANDARD_USER
    default:
      throw getApplicationError(ErrorCode.Api, 'UserRole not recognized')
  }
}

export const mapRoleList = (data: UserListData['roles']): UserRole[] => {
  try {
    return data.map(mapRole)
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapUser = (data: UserListData): User => {
  try {
    return {
      id: data.id,
      companyId: data.companyId,
      email: mapString(data.email),
      name: mapString(data.name),
      surname: mapString(data.surname),
      businessUnit: data.businessUnit ? mapBusinessUnit(data.businessUnit) : null,
      roles: data.roles ? mapRoleList(data.roles) : [],
      editions: data.editions ? mapWebinarEditionList(data.editions) : [],
      userAgreement: data.userAgreement ? data.userAgreement : null,
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapProgression = (data: UserDetailData): Progression => {
  try {
    return {
      completedLessons: mapNumber(data.completedLectures),
      completedCourses: mapNumber(data.completedCourses),
      watchedWebinars: mapNumber(data.watchedWebinars),
      completedWebinars: mapNumber(data.completedWebinars),
      attendedSeconds: mapNumber(data.attendedSeconds),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCategoryCode = (data: CategoryListData['id']): Category['code'] => {
  try {
    return `${data}`
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCategory = (data: CategoryListData): Category => {
  try {
    const colorExp = /^#(?:[0-9a-fA-F]{3}){1,2}$/i
    const thumbnailImg = data.thumbnailUrl
    return {
      code: mapCategoryCode(data.id),
      name: mapString(data.name),
      description: data.description,
      thumbnailImg,
      color: colorExp.test(data.color) ? data.color : 'transparent',
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCategoryList = (data: CategoryListData[]): Category[] => {
  try {
    return data.map(mapCategory)
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}
