import { Button, Col, Row, Skeleton } from 'antd'
import React from 'react'
import style from './styles.module.scss'

const LearningPathListTitleSkeleton: React.FC = () => {
  return (
    <Row className={style['learning-path-list-title']}>
      <Col md={12}>
        <Skeleton active title paragraph={false} />
      </Col>
      <Button className="toggle-button" type="ghost">
        <img src="images/list_ul.svg" alt="List Toggle" />
      </Button>
    </Row>
  )
}

LearningPathListTitleSkeleton.displayName = 'LearningPathListTitleSkeleton'

export default LearningPathListTitleSkeleton
