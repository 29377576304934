import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  PrimaryButton,
  Flex,
  Label,
  useMeetingManager,
  Modal,
  ModalBody,
  ModalHeader,
} from 'amazon-chime-sdk-component-library-react'

import Card from '../components/Card'
import { useMeetingState } from '../providers/MeetingStateProvider'
import { useTranslation } from 'react-i18next'

const MeetingJoinDetails = () => {
  const { t } = useTranslation()
  const meetingManager = useMeetingManager()
  const history = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const { meetingId, localUserName } = useMeetingState()

  const handleJoinMeeting = async () => {
    setIsLoading(true)

    try {
      await meetingManager.start()
      setIsLoading(false)
      history(`../join`)
    } catch (error) {
      console.warn('error', error)
      setIsLoading(false)
      setError(error.message)
    }
  }

  return (
    <>
      <Flex container alignItems="center" flexDirection="column">
        <PrimaryButton label={t('webinar.joinEdition')} onClick={handleJoinMeeting} />
        <Label style={{ margin: '.75rem 0 0 0' }}>
          Joining meeting <b>{meetingId}</b> as <b>{localUserName}</b>
        </Label>
      </Flex>
      {error && (
        <Modal size="md" onClose={(): void => setError('')}>
          <ModalHeader title={`Meeting ID: ${meetingId}`} />
          <ModalBody>
            <Card
              title="Unable to join edition"
              description="There was an issue in joining this edition. Check your connectivity and try again."
              smallText={error}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default MeetingJoinDetails
