import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ControlBarButton,
  Phone,
  Modal,
  ModalBody,
  ModalHeader,
  ModalButton,
  ModalButtonGroup,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react'

import { StyledP } from './Styled'
import { endMeetingForAll, endWebinar, leaveMeeting } from '../../api/webinar'
import {useDispatch, useSelector} from 'react-redux'
import { currentEditionSelector } from '../../store/Course/selectors'
import { useLoadCurrentUser, useLoadWebinarByCode } from '../../services/query'
import { useTranslation } from 'react-i18next'
import * as appActions from '../../store/App/actions'

const EndMeetingControl: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentEdition = useSelector(currentEditionSelector)
  const { data: webinar } = useLoadWebinarByCode(currentEdition.courseCode)
  const { data: user } = useLoadCurrentUser()
  const isWebinarTeacher = user.id === webinar.teacher.id
  const [showModal, setShowModal] = useState(false)
  const toggleModal = (): void => setShowModal(!showModal)
  const meetingManager = useMeetingManager()
  const history = useNavigate()

  const leave = () => {
    leaveMeeting(meetingManager, history)
    dispatch(appActions.setApplicationHeader(true))

  }
  const endForAll = () => {
    endMeetingForAll(webinar.code, currentEdition.code, meetingManager, history)
    dispatch(appActions.setApplicationHeader(true))
  }

  const primaryButtons = [
    <ModalButton
      key="leave-meeting"
      onClick={leave}
      variant="primary"
      label={t('webinar.leaveEdition')}
      closesModal
    />,
    <ModalButton
      key="cancel-meeting-ending"
      variant="secondary"
      label={t('general.cancel')}
      closesModal
    />,
  ]

  if (isWebinarTeacher) {
    primaryButtons.splice(
      primaryButtons.length - 1,
      0,
      <ModalButton
        key="end-meeting-for-all"
        onClick={endForAll}
        variant="primary"
        label={t('webinar.endEditionForAll')}
        closesModal
      />
    )
  }

  return (
    <>
      <ControlBarButton icon={<Phone />} onClick={toggleModal} label="Leave" />
      {showModal && (
        <Modal size="md" onClose={toggleModal} rootId="modal-root">
          <ModalHeader title={t('webinar.leaveEdition')} />
          <ModalBody>
            <StyledP>{t('webinar.leaveExplanation')}</StyledP>
          </ModalBody>
          <ModalButtonGroup primaryButtons={primaryButtons} />
        </Modal>
      )}
    </>
  )
}

export default EndMeetingControl
