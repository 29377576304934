import React from 'react'
import {useTranslation} from 'react-i18next'
import style from './styles.module.scss'
import {Progress, Col, Typography} from 'antd'
import {Course} from 'types/store'
import CourseLink, {CourseLinkType} from 'components/CourseLink'
import WebinarLink from "../WebinarLink";

const {Paragraph, Title} = Typography

interface Props {
  course: Course
}

const CourseList: React.FC<Props> = ({course}) => {
  const {title, category, progressPercentage} = course
  const {t} = useTranslation()


  return (
    <Col className={style['list-container']}>
      <div>
        <Title level={5}>{title}</Title>
        <Paragraph>
          {t('general.category')}: {category.name}
        </Paragraph>
        <div className="info-list">
          <Progress percent={progressPercentage * 100}/>
          {course.courseType === 'Webinar' ? <WebinarLink type={CourseLinkType.discover} course={course}/> : <CourseLink type={CourseLinkType.discover} course={course}/>}

        </div>
      </div>
    </Col>
  )
}

CourseList.displayName = 'CourseList'

export default CourseList
