import {GivenAnswerCreateData, QuestionDone, QuestionFullData, QuizFullData} from "../../types/store";

export const mapCourseQuiz = (quiz: QuizFullData): any => {

  return {
      quizTitle: quiz.title,
      quizSynopsis:  "",
      nrOfQuestions: quiz.attemptQuestionsNumber,
      questions : quiz.questions.map(question => mapQuestion(question)),
      originalQuiz: quiz
  }
}

export const mapQuestion = (question: QuestionFullData): any => {

  const numberOfCorrectAnswers = question.answers.filter(answer => answer.correct).length

  return {
    question: question.questionText,
    questionType: 'text',
    questionPic: '',
    answerSelectionType: question.answers.filter(answer => answer.correct).length > 1 ? "multiple" : "single",
    point: "1",
    answers: question.answers.map(answer => answer.answerText),
    correctAnswer: numberOfCorrectAnswers > 1 ?
      question.answers.filter(answer => answer.correct).map(answer => question.answers.indexOf(answer) + 1) :
      (question.answers.indexOf(question.answers.find(answer => answer.correct)) + 1).toString()
  }

}


/**
 * UserInput: Ogni elemento della lista è il set di risposte dato alle domande in ordine. Index 0: Risposte prima domanda
 * Il valore è da intendersi come l'ordine in cui sono comparse le risposte (Indice 0: Risposta 1 eccetera)
 **/
export function mapGivenAnswers(
  originalQuestions: QuestionFullData[],
  doneQuestions: QuestionDone[],
  userInput: any[]
) {

  const givenAnswers: GivenAnswerCreateData[] = []

  doneQuestions.forEach((doneQuestion, index) => {

    // Vedo se ha solo una risposta giusta
    const singleAnswer = typeof (doneQuestion.correctAnswer) == "string"

    // Uso il testo della domanda per recuperare il suo ID
    const questionText = doneQuestion.question

    const questionId = originalQuestions.find(q => q.questionText === questionText).id

    // Salvo la risposta (o le risposte) in una lista. Questi non sono né indici né ID, sono il loro "ordine" (la prima, la seconda eccetera e.g. [1,2] risposta 1 e risposta 2)
    const correctAnswer = singleAnswer ? [+doneQuestion.correctAnswer] : (doneQuestion.correctAnswer as number[]);

    // Testi delle risposte
    const {answers} = doneQuestion

    // Prendo i testi delle risposte
    const userInputAnswers: number[] | number = userInput[index]


    // accedo alla/e risposta/e corrispondente/i sottraendo 1 per trattarlo come indice
    const answersTexts = singleAnswer ?
      [answers[(userInputAnswers as number) - 1]] :
      (userInputAnswers as number[]).map(userInputAnswer => answers[userInputAnswer - 1])

    // Con i testi, recupero i loro ID
    const answerIds = answersTexts.map(answersText => originalQuestions[index].answers.find(a => a.answerText === answersText).id)

    // Controllo se l'utente ha messo le stesse cose
    const correct = computeIsCorrect(singleAnswer ?
        [userInputAnswers as number] : userInputAnswers as number[],
      singleAnswer ? [+correctAnswer as number] : correctAnswer as number[])

    const answerText = answersTexts.map(answerText => answerText).toString().replace(",", ",\n")

    const givenAnswer: GivenAnswerCreateData = {
      questionText: questionText,
      answersIds: answerIds,
      questionId: questionId,
      answerText: answerText,
      correct: correct
    }
    givenAnswers.push(givenAnswer)

  })


  return givenAnswers

}

function computeIsCorrect(userInputAnswers: number[], correctAnswer: number[]) {
  const areSetsEqual = (a, b) => a.size === b.size && [...a].every(value => b.has(value));

  const userSet = new Set<number>(userInputAnswers)
  const questionSet = new Set<number>(correctAnswer)

  return areSetsEqual(userSet, questionSet)

}


