import React from 'react'
import UserMenu from 'components/Header/UserMenu'
import { MenuAction } from 'components/Header/types'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from 'store/App/actions'
import { clearUser } from 'core/localStore'
import { useQueryClient } from 'react-query'
import routes from 'config/routes'

const UserMenuContainer: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const onLogout = () => {
    clearUser()
    queryClient.clear()
    dispatch(logout())
    navigate(routes.login.url)
  }

  const onItemClick = (key: MenuAction) => {
    switch (key) {
      case MenuAction.Logout:
        onLogout()
        break
      case MenuAction.Help:
        break
      case MenuAction.Dashboard:
        navigate(routes.dashboard.url)
        break
      default:
        break
    }
  }

  return <UserMenu onItemClick={onItemClick} />
}

UserMenuContainer.displayName = 'UserMenuContainer'

export default UserMenuContainer
