import {Button, Col, PageHeader, Row} from 'antd'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import CourseCard from 'components/CourseCard'
import {Course} from 'types/store'
import style from './styles.module.scss'
import {generatePath, Link} from "react-router-dom";
import routes from "../../config/routes";

interface Props {
  code: string
  title: string
  dueDate: Date | null
  allCoursesCompleted: boolean
  successfulAttempt: boolean
  hasQuiz: boolean
  isQuizExpired: boolean
  courses: Course[]
}

const LearningPathStrip: React.FC<Props> = ({
                                              code,
                                              title,
                                              dueDate,
                                              successfulAttempt,
                                              allCoursesCompleted,
                                              isQuizExpired,
                                              hasQuiz,
                                              courses
                                            }) => {
  const {t} = useTranslation()

  return (
    <section className={style['learning-path-strip']}>
      <Row justify='space-between' align='middle'>

        <PageHeader
          title={t('courses.stripTitle', {p0: title, p1: courses.length, count: courses.length})}
          subTitle={
            dueDate && t('courses.stripDueDate', {p0: moment(dueDate).format('DD/MM/YYYY')})
          }
        />

        {hasQuiz && allCoursesCompleted && !successfulAttempt && isQuizExpired && <p>
          {t('courses.quizExpired')}
        </p>}

        {hasQuiz && allCoursesCompleted && !successfulAttempt && !isQuizExpired && <Button type="primary">
          <Link to={generatePath(routes.learningPathQuiz.url, {code})}>
            {t('courses.quiz')}
          </Link>
        </Button>}

        {hasQuiz && allCoursesCompleted && successfulAttempt && <Button type="primary">
          <Link to={generatePath(routes.learningPathCertificate.url, {code})}>
            {t('courses.completed')}
          </Link>
        </Button>}

      </Row>
      <Row gutter={[30, 30]}>
        {courses.map((course) => (
          <Col key={course.code} xs={24} md={12} lg={6} className="course-card-container">
            <CourseCard course={course}/>
          </Col>
        ))}
      </Row>
    </section>
  )
}

LearningPathStrip.displayName = 'LearningPathStrip'

export default LearningPathStrip
