import {useMemo} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {
  getBscCertificateByTransactionHash,
  getCourseDetailById,
  getCourseList,
  getEnrolledCoursesByUserId, getLearningPathDetailById,
  getLearningPathsByUser,
  putEnrolledCourseByUserId,
  updateEditionCompletedByCode as setEditionCompleted,
  updateLectureCompletedByCode as setLessonCompleted,
} from 'api/course'
import * as queryKeys from 'config/queryKeys'
import {
  Category,
  Course,
  CourseDetail,
  CourseEnrolled,
  CourseFilter,
  LearningPath,
  Lesson,
  WebinarEdition,
} from 'types/store'
import {filterByCategoryCode, filterByStatus} from 'services/filters/course'
import {blockchainCourseCertificationDataPlaceholder, courseDetailPlaceholder} from 'services/map/common'
import {
  mapCourseDetail,
  mapCourseList,
  mapEnrolledCourseList,
  mapFromCourseCode,
  mapFromLessonCode, mapLearningPath, mapLearningPathCode,
  mapLearningPathList,
  setLessonProgressOnEnrolledCourseData,
} from 'services/map/course'
import {sortByEventOrder} from 'services/sorters/course'
import {EventType} from 'types/enums'
import {useCurrentUserId, useLoadCategoryList} from './user'
import {
  BlockchainCourseCertificationData,
  CourseDetailData,
  CourseSearchData,
  EnrolledCourseListData,
  LearningPathProgressionData,
} from 'types/api'
import {mapFromEditionCode} from '../map/webinar'
import {getFullQuizByCourseId, getFullQuizByLearningPathId} from "../../api/quiz";
import {mapCourseQuiz} from "../map/quiz";

export function useLoadCourseList() {
  const enrolledCourses = useLoadEnrolledCourses()
  const categories = useLoadCategoryList()
  const queryFunction = () => getCourseList()
  return useQuery(queryKeys.courseList(), queryFunction, {
    placeholderData: null,
    enabled: enrolledCourses.isFetched && categories.isFetched,
    select: (courseSearch: CourseSearchData | null): Course[] => {
      if (courseSearch === null) {
        return []
      }
      const enrolledCourseMap = enrolledCourses.data.reduce((acc, course) => {
        acc[course.code] = course
        return acc
      }, {} as Record<Course['code'], Course>)
      const categoryMap = categories.data.reduce((acc, category) => {
        acc[category.code] = category
        return acc
      }, {} as Record<Category['code'], Category>)
      return mapCourseList(courseSearch.courses, {
        categoryMap,
        enrolledCourseMap,
      })
    },
  })
}

export function useLoadCourseByCode(code: Course['code']) {
  const enrolledCourses = useLoadEnrolledCourses()
  const categories = useLoadCategoryList()
  const queryFunction = () => getCourseDetailById(mapFromCourseCode(code))
  return useQuery(queryKeys.courseDetailByCode(code), queryFunction, {
    placeholderData: null,
    enabled: enrolledCourses.isFetched && categories.isFetched,
    select: (courseDetail: CourseDetailData | null): CourseDetail => {
      if (courseDetail === null) {
        return courseDetailPlaceholder
      }
      const enrolledCourseMap = enrolledCourses.data.reduce((acc, course) => {
        acc[course.code] = course
        return acc
      }, {} as Record<CourseEnrolled['code'], CourseEnrolled>)
      const categoryMap = categories.data.reduce((acc, category) => {
        acc[category.code] = category
        return acc
      }, {} as Record<Category['code'], Category>)
      return mapCourseDetail(courseDetail, {categoryMap, enrolledCourseMap})
    },
  })
}

export function useLoadLearningPathByCode(userId: number, code: number) {
  const queryFunction = () => getLearningPathDetailById(userId, code)

  return useQuery(queryKeys.learningPathDetailByCode(code), queryFunction, {
    placeholderData: null,
    enabled: true,
    select: (learningPathDetail: LearningPathProgressionData | null): LearningPathProgressionData => {
      return learningPathDetail
    },
  })
}

export function useLoadBscCertificateByTransactionHash(transactionHash: string) {
  const queryFunction = () => getBscCertificateByTransactionHash(transactionHash)

  return useQuery(queryKeys.bscCertificateByTransactionHash(transactionHash), queryFunction, {
    placeholderData: null,
    enabled: true,
    select: (blockchainCourseCertificationData: BlockchainCourseCertificationData | null): BlockchainCourseCertificationData => {
      if (blockchainCourseCertificationData === null) {
        return blockchainCourseCertificationDataPlaceholder
      }
      return  blockchainCourseCertificationData
    },
  })
}


export function useLoadCourseFullQuiz(courseId: number) {
  const queryFunction = () => getFullQuizByCourseId(courseId)

  return useQuery(queryKeys.quizCourse(courseId), queryFunction, {
    placeholderData: null,
    staleTime: Infinity,
    cacheTime: Infinity,
    select: (quiz) => mapCourseQuiz(quiz)
  });
}

export function useLoadEnrolledCourses() {
  const userId = useCurrentUserId()
  const categories = useLoadCategoryList()
  const queryFunction = () => getEnrolledCoursesByUserId(userId)
  return useQuery(queryKeys.enrolledCourseList(), queryFunction, {
    placeholderData: null,
    enabled: userId != null && categories.isFetched,
    staleTime: 1000,
    cacheTime: 1000,
    select: (enrolledCourseList: EnrolledCourseListData[] | null): CourseEnrolled[] => {
      if (enrolledCourseList === null) {
        return []
      }
      const categoryMap = categories.data.reduce((acc, category) => {
        acc[category.code] = category
        return acc
      }, {} as Record<Category['code'], Category>)
      return mapEnrolledCourseList(enrolledCourseList, {categoryMap})
    },
  })
}

export function useLoadCourseFilteredList(filter: CourseFilter) {
  const {data: courseList, ...coursesQuery} = useLoadCourseList()

  const filteredCourseList = useMemo(() => {
    let result = courseList
    if (filter.type === EventType.Webinar) {
      result = []
    }
    if (filter.category) {
      result = filterByCategoryCode(result, filter.category)
    }
    if (filter.status) {
      result = filterByStatus(result, filter.status)
    }
    if (filter.order) {
      result = sortByEventOrder(result, filter.order)
    }
    if (filter.search) {
      const regexp = new RegExp(filter.search, 'i')
      result = result.filter((course) => regexp.test(course.title))
    }
    return result
  }, [
    coursesQuery.dataUpdatedAt,
    filter.type,
    filter.order,
    filter.category,
    filter.status,
    filter.search,
  ])

  return {...coursesQuery, data: filteredCourseList}
}

export function useLoadCourseModuleList(courseCode: Course['code']) {
  const coursesQuery = useLoadCourseByCode(courseCode)
  const courseModuleList = useMemo(() => coursesQuery.data.modules, [coursesQuery.data])
  return {...coursesQuery, data: courseModuleList}
}

export function useLoadCourseLessonList(courseCode: string | null) {
  const coursesQuery = useLoadCourseByCode(courseCode)
  const courseLessonList = useMemo(
    () => coursesQuery.data.modules.reduce((acc, module) => acc.concat(module.lessons), []),
    [coursesQuery.data]
  )
  return {...coursesQuery, data: courseLessonList}
}

export function useMutateCourseLesson(courseCode: string | null) {
  const userId = useCurrentUserId()
  // NOTE: the enrolled courses brings the information about completable
  // So that we need to mutate it in order to propagate the completable information
  const enrolledCourses = queryKeys.enrolledCourseList()

  const queryFunction = async (data: { lessonCode: Lesson['code']; progress: number }) => {
    const {lessonCode, progress} = data
    console.assert(progress >= 0, 'progress must be a percentage value between 0 and 1')
    console.assert(progress <= 1, 'progress must be a percentage value between 0 and 1')
    await setLessonCompleted(mapFromLessonCode(lessonCode), userId, {
      timestamp: new Date().toISOString(),
      progress: Math.round(progress * 100),
    })
  }
  const queryClient = useQueryClient()
  const {mutate: mutateLesson} = useMutation(queryFunction, {
    onMutate: async (data: { lessonCode: Lesson['code']; progress: number }) => {
      const {lessonCode, progress} = data
      /* Optimistic update of course detail */
      await queryClient.cancelQueries(enrolledCourses)
      const previousValue: EnrolledCourseListData[] = queryClient.getQueryData(enrolledCourses)
      queryClient.setQueryData(enrolledCourses, (enrolledCourseData: EnrolledCourseListData[]) => {
        return enrolledCourseData.map((data) => {
          if (data.id === mapFromCourseCode(courseCode)) {
            return setLessonProgressOnEnrolledCourseData(
              data,
              mapFromLessonCode(lessonCode),
              progress
            )
          }
          return data
        })
      })
      return previousValue
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(enrolledCourses)
    },
    onError: (err, variables, previousValue: EnrolledCourseListData[]) => {
      queryClient.setQueryData(enrolledCourses, previousValue)
    },
  })
  return mutateLesson
}

export function useMutateWebinarEdition(webinarCode: string | null) {
  const userId = useCurrentUserId()
  // NOTE: the enrolled courses brings the information about completable
  // So that we need to mutate it in order to propagate the completable information
  const enrolledCourses = queryKeys.enrolledCourseList()

  const queryFunction = async (data: { editionCode: WebinarEdition['code']; progress: number }) => {
    const {editionCode, progress} = data
    console.assert(progress >= 0, 'progress must be a percentage value between 0 and 1')
    console.assert(progress <= 1, 'progress must be a percentage value between 0 and 1')
    console.log(progress)
    await setEditionCompleted(
      userId,
      mapFromEditionCode(webinarCode),
      mapFromEditionCode(editionCode),
      {
        timestamp: new Date().toISOString(),
        progress: Math.round(progress * 100),
      }
    )
  }

  const queryClient = useQueryClient()
  const {mutate: mutateEdition} = useMutation(queryFunction, {
    onMutate: async (data: { editionCode: WebinarEdition['code']; progress: number }) => {
      const {editionCode, progress} = data
      /* Optimistic update of course detail */
      await queryClient.cancelQueries(enrolledCourses)
      const previousValue: EnrolledCourseListData[] = queryClient.getQueryData(enrolledCourses)
      queryClient.setQueryData(enrolledCourses, (enrolledCourseData: EnrolledCourseListData[]) => {
        return enrolledCourseData.map((data) => {
          if (data.id === mapFromCourseCode(webinarCode)) {
            return setLessonProgressOnEnrolledCourseData(
              data,
              mapFromLessonCode(editionCode),
              progress
            )
          }
          return data
        })
      })
      return previousValue
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(enrolledCourses)
    },
    onError: (err, variables, previousValue: EnrolledCourseListData[]) => {
      queryClient.setQueryData(enrolledCourses, previousValue)
    },
  })
  return mutateEdition
}

export function useRollOutCourseByCode(courseCode: string | null) {
  const userId = useCurrentUserId()

  const queryFunction = async () => {
    const courseId = mapFromCourseCode(courseCode)
    await putEnrolledCourseByUserId(courseId, userId)
  }
  const queryClient = useQueryClient()
  return useMutation(queryFunction, {
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKeys.courseList())
      await queryClient.invalidateQueries(queryKeys.courseDetailByCode(courseCode))
    },
  })
}

export function useLoadLearningPathList() {
  const userId = useCurrentUserId()
  const enrolledCourses = useLoadEnrolledCourses()
  const categories = useLoadCategoryList()
  const queryFunction = () => getLearningPathsByUser(userId)
  return useQuery(queryKeys.learningPathList(), queryFunction, {
    placeholderData: null,
    enabled: userId != null && enrolledCourses.isFetched && categories.isFetched,
    select: (learningPathList: LearningPathProgressionData[] | null): LearningPath[] => {
      if (learningPathList === null) {
        return []
      }
      const enrolledCourseMap = enrolledCourses.data.reduce((acc, course) => {
        acc[course.code] = course
        return acc
      }, {} as Record<Course['code'], Course>)
      const categoryMap = categories.data.reduce((acc, category) => {
        acc[category.code] = category
        return acc
      }, {} as Record<Category['code'], Category>)
      return mapLearningPathList(learningPathList, {
        categoryMap,
        enrolledCourseMap,
      })
    },
  })
}

export function useLoadLearningPathFullQuiz(learningPathId: number) {
  const queryFunction = () => getFullQuizByLearningPathId(learningPathId)

  return useQuery(queryKeys.quizLearningPath(learningPathId), queryFunction, {
    placeholderData: null,
    staleTime: Infinity,
    cacheTime: Infinity,
    select: (quiz) => mapCourseQuiz(quiz)
  });
}
