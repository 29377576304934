import { Button } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'
import routes from 'config/routes'
import { Course } from 'types/store'

export enum CourseLinkType {
  discover = 'DISCOVER',
  rollOut = 'ROLL_OUT',
}
interface PropsDiscover {
  type: CourseLinkType.discover
  className?: string
  course: Course
  loading?: boolean
}

interface PropsRollOut {
  type: CourseLinkType.rollOut
  className?: string
  course: Course
  loading?: boolean
  onRollOut: (course: Course) => void
}

type Props = PropsDiscover | PropsRollOut

const CourseLink: React.FC<Props> = (props) => {
  const { type, className, course, loading = false } = props
  const { t } = useTranslation()
  const { started, rolledOut, progressPercentage, successfulAttempt, hasQuiz, isQuizExpired , quizExpirationDate} = course


  if (progressPercentage >= 1) {
    if (!hasQuiz || (hasQuiz && successfulAttempt))
      return (
        <Button className={className} type="primary" block loading={loading}>
          <Link to={generatePath(routes.certificate.url, { code: course.code })}>
            {t('courses.completed')}
          </Link>
        </Button>
      )
    if (hasQuiz && !successfulAttempt && !isQuizExpired)
      return (
        <Button className={className} type="primary" block loading={loading}>
          <Link to={generatePath(routes.courseQuiz.url, { code: course.code })}>
            {t('courses.quiz')}
          </Link>
        </Button>
      )

    if (hasQuiz && !successfulAttempt && isQuizExpired)
      return (
        <p className={className}>
            {t('courses.quizExpired')}
        </p>
      )
  }

  if (progressPercentage === null && !rolledOut) {
    if (type === CourseLinkType.rollOut) {
      const { onRollOut } = props
      return (
        <Button
          className={className}
          type="primary"
          block
          loading={loading}
          onClick={() => onRollOut(course)}
        >
          {t('courses.rollOut')}
        </Button>
      )
    }
    return (
      <Button className={className} type="primary" block loading={loading}>
        <Link to={generatePath(routes.courseDetail.url, { code: course.code })}>
          {t('courses.discover')}
        </Link>
      </Button>
    )
  }
  if (started) {
    return (
      <Button className={className} type="primary" block loading={loading}>
        <Link to={generatePath(routes.courseVideo.url, { code: course.code })}>
          {t('courses.continue')}
        </Link>
      </Button>
    )
  }

  return (
    <Button className={className} type="primary" block loading={loading}>
      <Link to={generatePath(routes.courseVideo.url, { code: course.code })}>
        {t('courses.start')}
      </Link>
    </Button>
  )
}

CourseLink.displayName = 'CourseLink'

export default CourseLink
