import React from 'react'
import { WebinarDetail as WebinarDetailType } from 'types/store'

import style from './styles.module.scss'
import WebinarDescription from './WebinarDescription'

interface Props {
  webinar: WebinarDetailType
}

const WebinarDetail: React.FC<Props> = ({ webinar }) => {
  return (
    <section className="app-container">
      <div className={style['webinar-detail']}>
        <WebinarDescription webinar={webinar} />
      </div>
    </section>
  )
}

WebinarDetail.displayName = 'WebinarDetail'

export default WebinarDetail
