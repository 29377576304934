import { CourseStatus } from 'types/enums'
import { Category, Course } from 'types/store'

export const filterByCategoryCode = (list: Course[], categoryCode: Category['code']) => {
  return list.filter((course) => course.category.code === categoryCode)
}

export const filterByStatus = (courseList: Course[], status: CourseStatus) => {
  return courseList.filter((course) => {
    switch (status) {
      case CourseStatus.InProgress:
        return course.started && course.progressPercentage < 1
      case CourseStatus.NotStarted:
        return !course.started
      default:
        return true
    }
  })
}
