import React from 'react'
import { useTranslation } from 'react-i18next'
import NoContent from 'components/NoContent'
import WebinarGrid from 'components/WebinarGrid'
import { useLoadWebinarList } from 'services/query'

const WebinarGridContainer: React.FC = () => {
  const { t } = useTranslation()
  const webinarQuery = useLoadWebinarList()

  const { data: webinarList } = webinarQuery
  if (webinarList.length <= 0) {
    return <NoContent text={t('userProfile.noWebinars')} />
  }
  return <WebinarGrid webinars={webinarList} />
}

WebinarGridContainer.displayName = 'WebinarGridContainer'

export default WebinarGridContainer
