import { Col, Row, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CourseProgressionInfo from 'components/CourseProgressionInfo'
import UserPropgressionInfo from 'components/UserPropgressionInfo'
import { useLoadCurrentUser, useLoadMyProgress } from 'services/query/user'
import { useSettingValue } from 'services/query'

export enum DashboardMode {
  Quizzes = 'Quizzes',
  Courses = 'COURSES',
  LearningPaths = 'LEARNING_PATHS',
  Webinar = 'WEBINAR',
}

interface Props {
  mode: DashboardMode
}

const DashboardHeaderContainer: React.FC<Props> = ({ mode }) => {
  const { t } = useTranslation()
  const { data: user } = useLoadCurrentUser()
  const { isFetched, isLoading, isError, data: progress } = useLoadMyProgress()

  let hourTotal = Number.parseInt(useSettingValue('progress.hourTotal'))
  if (!hourTotal) {
    hourTotal = 0
  }

  if (isError) {
    return null
  }

  if (isLoading || !isFetched) {
    return null
  }

  const secondCount = progress.attendedSeconds
  let hourCount = Number((secondCount / 3600).toFixed(1))
  if (Math.round(secondCount / 3600) > 0) {
    hourCount = Math.round(hourCount)
  }

  return (
    <Col>
      <Row className="p-40" justify="center">
        <Typography.Title level={1}>
          {mode === DashboardMode.Courses
            ? t('dashboard.titleCourses')
            : mode === DashboardMode.Webinar
            ? t('dashboard.titleWebinar')
            : t('dashboard.titleLearningPaths')}
        </Typography.Title>
      </Row>
      <Row gutter={[30, 30]}>
        <Col lg={14} md={24}>
          <UserPropgressionInfo user={user} />
        </Col>
        <Col lg={10} md={24}>
          <CourseProgressionInfo
            courseCount={progress.completedCourses}
            lessonCount={progress.completedLessons}
            webinarWatched={progress.watchedWebinars}
            webinarCount={progress.completedWebinars}
            hourCount={hourCount}
            hourTotal={hourTotal}
          />
        </Col>
      </Row>
    </Col>
  )
}

DashboardHeaderContainer.displayName = 'DashboardHeaderContainer'

export default DashboardHeaderContainer
