import { AuthError, getAuth, getIdToken, signOut } from 'firebase/auth'
import * as Store from 'types/store'
import {getMyUserAgreement} from "../api/user";
import {UserAgreementData} from "../types/api";

export const clearUser = async (): Promise<void> => {
  const auth = getAuth()
  signOut(auth)
}

export const getTokens = async (): Promise<Store.Tokens> => {
  try {
    const auth = getAuth()
    const refreshToken = auth.currentUser?.refreshToken ?? null
    if (!auth.currentUser) {
      console.log(`User not autenticated`)
      return {
        token: null,
        refreshToken: null,
      }
    }
    const token = await getIdToken(auth.currentUser)
    return {
      token,
      refreshToken,
    } as Store.Tokens
  } catch (error) {
    const authError = (error as AuthError).code?.startsWith('auth/') ? (error as AuthError) : null
    if (authError) {
      console.log(`Refresh token failed from firebase returning ${authError?.code}`)
    } else {
      console.error(error)
      console.error(error.stack)
    }
    return {
      token: null,
      refreshToken: null,
    }
  }
}

export const getAuthToken = async (): Promise<string> => {
  const user = await getTokens()
  return user?.token
}

export const getUserAgreement = async (): Promise<UserAgreementData> => {
  return await getMyUserAgreement()
}

export const getRefreshToken = async (): Promise<string> => {
  const user = await getTokens()
  return user?.refreshToken
}

export const saveUser = async (): Promise<Store.Tokens> => {
  // No additional operation required as Firebase will manage the refresh token autonomously
  return getTokens()
}
