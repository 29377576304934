import { Layout, Row, Col, Space, Input, Dropdown, Avatar } from 'antd'
import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'
import routes from 'config/routes'
import UserMenu from 'containers/Header/UserMenu'
import style from './styles.module.scss'

interface Props {
  name: string
  companyLogo?: string
  onSearch: (text: string) => void
}

const Header: React.FC<Props> = ({ name, companyLogo, onSearch }) => {
  const { t } = useTranslation()
  const searchRef = useRef<Input>(null)

  const search = useCallback(() => {
    onSearch(searchRef.current.state.value?.trim())
    searchRef.current.setValue('')
    searchRef.current.blur()
  }, [onSearch])
  const onSearchKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const keyCode = event.code || event.key
      if (keyCode == 'Enter' && searchRef) {
        search()
      }
    },
    [search]
  )
  const firstLetter = name.charAt(0)

  return (
    <Layout.Header className={style['header']}>
      <section className="app-container">
        <Row align="bottom">
          <Col className="logo-col">
            <Link to={routes.home.url}>
              <img className="logo-brand" src={companyLogo ?? '/images/logo.png'} alt="brand" />
            </Link>
          </Col>

          <Col className="info-col">
            <Space size="small">
              <Link className="courses" to={routes.coursesOverall.url}>
                {t('menu.allCourses')}
              </Link>
              <Input
                ref={searchRef}
                className="input-header"
                placeholder={t('general.search')}
                prefix={<SearchOutlined onClick={search} />}
                onKeyPress={onSearchKeyPress}
              />
              <Link to={routes.notifications.url}>
                <img src="/images/icons/notification_active.svg" alt="notification bell" />
              </Link>
              <Dropdown
                className="user-drop"
                overlay={<UserMenu />}
                trigger={['click']}
                placement="bottomRight"
              >
                <Avatar>{firstLetter}</Avatar>
              </Dropdown>
            </Space>
          </Col>
        </Row>
      </section>
    </Layout.Header>
  )
}

Header.displayName = 'Header'

export default Header
