import React, { useCallback, useEffect, useMemo } from 'react'

import CourseFilter from 'components/CourseFilter'
import { Category, CourseFilter as CourseFilterType } from 'types/store'
import { useLoadCategoryList } from 'services/query/user'
import { useNavigate, useLocation } from 'react-router'
import { filterFromQuery, filterToQuery } from 'services/navigation/course'

interface Props {
  defaultCategoryCode: Category['code'] | null
}

const CourseFilterContainer: React.FC<Props> = ({ defaultCategoryCode }) => {
  const navigate = useNavigate()
  const { search, pathname } = useLocation()
  const { data: categories, isPlaceholderData } = useLoadCategoryList()
  const filter = useMemo(() => filterFromQuery(search), [search])
  const onFilterChange = useCallback((filter: CourseFilterType) => {
    navigate(
      {
        pathname: pathname,
        search: filterToQuery(filter),
      },
      { replace: true }
    )
  }, [])
  const onFilterReset = useCallback(() => {
    navigate(
      {
        pathname: pathname,
      },
      { replace: true }
    )
  }, [])

  useEffect(() => {
    if (defaultCategoryCode && !isPlaceholderData) {
      const category = categories.find((category) => category.code === defaultCategoryCode)
      onFilterChange({ ...filter, category: category.code })
    }
  }, [isPlaceholderData])

  return (
    <CourseFilter
      categories={categories}
      filter={filter}
      onChange={onFilterChange}
      onReset={onFilterReset}
    />
  )
}

CourseFilterContainer.displayName = 'CourseFilterContainer'

export default CourseFilterContainer
