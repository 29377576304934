import { Card, Col, Divider, Row, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { User } from 'types/store'
import style from './styles.module.scss'

const { Title } = Typography

interface Props {
  user: User
}

const UserPropgressionInfo: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation()

  return (
    <Card className={style['user-propgression-info']}>
      <Row>
        <Col md={9}>
          <Card.Meta
            title={
              <Title className="p-10 text-align-center" level={4}>
                {t('dashboard.userProgressionTitle', { p0: user.name })}
              </Title>
            }
          />
        </Col>
        <Col md={1}>
          <Divider type="vertical" />
        </Col>
        <Col md={14}>
          <Card.Meta
            title={
              <Col>
                <ReactMarkdown className="markdown text-align-center">
                  {t('dashboard.userProgressionBody')}
                </ReactMarkdown>
              </Col>
            }
          />
        </Col>
      </Row>
    </Card>
  )
}

UserPropgressionInfo.displayName = 'UserPropgressionInfo'

export default UserPropgressionInfo
