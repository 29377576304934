import React from 'react'
import style from './styles.module.scss'

const CourseVideoPlaceholder: React.FC = () => {
  return (
    <div className={style['course-video']}>
      <img alt="cover" src={'/images/course-fallback.png'} style={{ objectFit: 'contain' }} />
    </div>
  )
}

CourseVideoPlaceholder.displayName = 'CourseVideoPlaceholder'

export default CourseVideoPlaceholder
