import { Layout } from 'antd'
import React from 'react'

const WINDOW_HEIGHT_NO_HEADER = window.innerHeight

const ServerNotAvailablePage: React.FC = () => {
  return (
    <Layout style={{ minHeight: WINDOW_HEIGHT_NO_HEADER, textAlign: 'center' }}>
      <section
        data-augmented-ui
        className="app-container"
        style={{ minHeight: WINDOW_HEIGHT_NO_HEADER }}
      >
        <Layout style={{ minHeight: WINDOW_HEIGHT_NO_HEADER }}>
          <span>{'Service not available'}</span>
        </Layout>
      </section>
    </Layout>
  )
}

export default ServerNotAvailablePage
