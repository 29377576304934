import { useDebugValue } from 'react'
import { useQuery } from 'react-query'

import {getCategories, getMyUser, getMyUserAgreement, getUserById} from 'api/user'
import * as queryKeys from 'config/queryKeys'
import { mapCategoryList, mapProgression, mapUser } from 'services/map/user'
import { progressionPlaceholder } from 'services/map/common'

export function useLoadCurrentUser() {
  const queryFunction = async () => {
    const currentUser = await getMyUser()
    return mapUser(currentUser)
  }
  return useQuery(queryKeys.currentUser(), queryFunction, {
    placeholderData: {
      id: null,
      companyId: null,
      email: '',
      name: '',
      surname: '',
      businessUnit: {
        code: null,
        name: '',
      },
      roles: [],
      editions: [],
      userAgreement: null,
    },
    cacheTime: Infinity,
  })
}

export function useLoadCurrentUserAgreement() {
  const queryFunction = async () => {
    return await getMyUserAgreement()
  }
  return useQuery(queryKeys.currentUserAgreement(), queryFunction, {
    placeholderData: {
      userPolicy: null,
      visitorPolicyA: null,
      visitorPolicyB: null,
      termsAndConditions: null,
      webinarRecording: null,
    },
  })
}

export function useLoadMyProgress() {
  const currentUserId = useCurrentUserId()
  const queryFunction = () => getUserById(currentUserId)
  return useQuery(queryKeys.userById(currentUserId), queryFunction, {
    placeholderData: null,
    cacheTime: 0,
    staleTime: 0,
    select: (user) => {
      if (user === null) {
        return progressionPlaceholder
      }
      return mapProgression(user)
    },
  })
}

export function useCurrentUserId() {
  const { isFetched, data } = useLoadCurrentUser()
  if (isFetched && data?.id) {
    return data.id
  }
  return null
}

export function useLoadCategoryList() {
  const queryFunction = async () => {
    const categoryList = await getCategories()
    return mapCategoryList(categoryList)
  }
  const query = useQuery(queryKeys.categoryList(), queryFunction, {
    placeholderData: [],
    cacheTime: Infinity,
  })
  useDebugValue(`query status is ${query.status}`)
  useDebugValue(`dataUpdatedAt ${query.dataUpdatedAt}`)
  return query
}
