const routes = {
  agreement: {
    key: 'agreement',
    url: '/agreement',
    private: true,
    menu: 'home',
  },
  launchWebinar: {
    key: 'webinar',
    url: '/webinar',
    private: true,
    menu: 'home',
  },
  deviceSelection: {
    key: 'webinar',
    url: 'devices-selection',
    private: true,
    menu: 'home',
  },
  webinar: {
    key: 'webinar',
    url: '/meeting',
    private: true,
    menu: 'home',
  },
  home: {
    key: 'home',
    url: '/',
    private: true,
    menu: 'home',
  },
  notifications: {
    key: 'notifications',
    url: '/notifications',
    private: true,
    menu: 'notifications',
  },
  courseDetail: {
    key: 'course-detail',
    url: '/courses/:code',
    private: true,
    menu: 'home',
  },
  coursesOverall: {
    key: 'courses-overall',
    url: '/courses',
    private: true,
    menu: 'home',
  },
  courseVideo: {
    key: 'course-video',
    url: '/courses/:code/video',
    private: true,
    menu: 'home',
  },
  dashboard: {
    key: 'dashboard',
    url: '/dashboard',
    private: true,
    menu: 'home',
  },
  quiz: {
    key: 'quiz',
    url: '/dashboard#quiz',
    private: true,
    menu: 'home',
  },
  playground: {
    key: 'playground',
    url: '/playground',
    private: true,
    menu: 'playground',
  },
  certifications: {
    key: 'certifications',
    url: '/certifications',
    private: true,
    menu: 'certifications',
  },
  userPage: {
    key: 'user-page',
    url: '/user-page',
    private: true,
    menu: '',
  },
  webinarDetail: {
    key: 'webinar-detail',
    url: '/webinar/:code/*',
    private: true,
    menu: 'home',
  },
  blockChainCertificate: {
    key: 'blockChainCertificate',
    url: '/blockchain-certificate/:transactionHash',
    private: false,
    menu: 'home'
  },
  certificate: {
    key: 'certificate',
    url: '/courses/:code/certificate',
    private: true,
    menu: 'home',
  },
  learningPathCertificate: {
    key: 'certificate',
    url: '/learning-path/:code/certificate',
    private: true,
    menu: 'home',
  },
  courseQuiz: {
    key: 'quiz',
    url: '/courses/:code/quiz',
    private: true,
    menu: 'home',
  },
  learningPathQuiz: {
    key: 'lp-quiz',
    url: '/learning-paths/:code/quiz',
    private: true,
    menu: 'home',
  },
  //Authentication Routes
  login: {
    key: 'login',
    url: '/login',
    private: false,
    menu: '',
  },
  forgotPassword: {
    key: 'forgot-password',
    url: '/forgot-password',
    private: false,
    menu: '',
  },
  selfRegistrationPage: {
    key: 'self-registration',
    url: '/invite/:token',
    private: false,
    menu: '',
  },
  //Error Routes
  notFoundPage: {
    key: 'not-found',
    url: '/page-not-found',
    private: false,
    menu: '',
  },
  errorPage: {
    key: 'error',
    url: '/error',
    private: false,
    menu: '',
  },
} as const

export default routes
