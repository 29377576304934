import React from 'react'
import {useLoadCourseFullQuiz, useLoadCourseModuleList, useLoadEnrolledCourses} from 'services/query'
import {CourseDetail} from 'types/store'
import CourseQuiz from "components/CourseQuiz";
import {Spinner} from "amazon-chime-sdk-component-library-react";
import {Button, Typography} from "antd";
import {generatePath, Link} from "react-router-dom";
import routes from "../../config/routes";
import {useTranslation} from "react-i18next";

interface Props {
  code: CourseDetail['code']
}

const CourseQuizContainer: React.FC<Props> = ({code}) => {
  const {t} = useTranslation()

  const {
    isFetched: isCourseModuleFetched,
    isLoading: isCourseModuleLoading,
    isError: isCourseModuleError,
    data: moduleList
  } = useLoadCourseModuleList(code)

  const {
    isFetched: isEnrolledFetched,
    isLoading: isEnrolledLoading,
    isError: isEnrolledError,
    data: enrolledCourses
  } = useLoadEnrolledCourses()

  const {
    data: quiz,
    isError: isQuizError,
    isLoading: isQuizLoading,
    isFetched: isQuizFetched
  } = useLoadCourseFullQuiz(+code)

  if (isQuizLoading || isEnrolledLoading || isCourseModuleLoading || !isQuizFetched || !isEnrolledFetched || !isEnrolledFetched)
    return <Spinner width="1.5rem" height="1.5rem"/>

  if (isQuizError || isEnrolledError || isCourseModuleError)
    return null

  const course = enrolledCourses.find(course => course.code === code)

  const isCourseCompleted = course.progressPercentage == 1
  const isQuizCompleted = course.successfulAttempt
  const {isQuizExpired, quizExpirationDate} = course


  const {nrOfQuestions} = quiz

  const questions = []

  quiz.questions.forEach((q, index) => {
    if (index < nrOfQuestions)
      questions.push(q)
  })

  quiz.questions = questions


  const message = quiz ?
    isCourseCompleted ?
      (isQuizCompleted ? `Hai già completato il quiz del corso "${course.title}"` : "") : `Devi completare il corso "${course.title}" per poter accedere al quiz` : `Non esiste un quiz per il corso ${course.title}`


  return (
    <>
      {
        isCourseCompleted && !isQuizCompleted && !isQuizExpired ? quiz &&
          <>
            {quizExpirationDate != null && <p>
              {t('courses.quizExpirationDate', {p0: quizExpirationDate.toString().split('T')[0]})}
            </p>
            }
            <CourseQuiz quizData={quiz}/>
          </>
          :
          (<Typography.Title level={2}>{message}</Typography.Title>)
      }
      {
        isQuizExpired &&
        (<Typography.Title level={2}>
          {t('courses.quizExpired')}
        </Typography.Title>)
      }
      {
        isCourseCompleted && isQuizCompleted &&
        <Button className={"mt-50"} type="primary" block>
          <Link to={generatePath(routes.certificate.url, {code: "" + code})}>
            {t('courses.completed')}
          </Link>
        </Button>
      }


    </>
  )


}

CourseQuizContainer.displayName = 'CourseQuizContainer'

export default CourseQuizContainer
