import CourseDetail from 'components/CourseDetail'
import React from 'react'
import { useLoadCourseByCode, useRollOutCourseByCode } from 'services/query'
import { Course } from 'types/store'

interface Props {
  code: Course['code']
}

const CourseDetailContainer: React.FC<Props> = ({ code }) => {
  const { isFetched, isLoading, isError, data: course } = useLoadCourseByCode(code)
  const { mutate: onRollOut, isLoading: isRollingOut } = useRollOutCourseByCode(code)

  if (isError) {
    return null
  }

  if (!isFetched || isLoading) {
    return null
  }

  return <CourseDetail course={course} isRollingOut={isRollingOut} onRollOut={() => onRollOut()} />
}

CourseDetailContainer.displayName = 'CourseDetailContainer'

export default CourseDetailContainer
