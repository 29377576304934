import { Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import style from './styles.module.scss'

const NotificationsPanel: React.FC = ({}) => {
  const { t } = useTranslation()

  return (
    <section className={style['notifications-profile']}>
      <div className="app-container">
        <Typography.Title className="panel-title" level={4}>
          {t('userProfile.employeeNotifications')}
        </Typography.Title>
        <Typography.Title className="noNotifications" level={3}>
          {t('userProfile.noNotifications')}
        </Typography.Title>
      </div>
    </section>
  )
}

NotificationsPanel.displayName = 'NotificationsPanel'

export default NotificationsPanel
