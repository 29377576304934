import { Col, Row } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'

import CourseLessonDetailContainer from 'containers/CourseLessonDetail'
import CourseModuleListContainer from 'containers/CourseModuleList'
import CourseVideoContainer from 'containers/CourseVideo'
import style from './styles.module.scss'
import classNames from 'classnames'
import {useLoadCourseByCode} from "../../services/query";

const CourseVideo: React.FC = () => {
  const { code } = useParams()

  const { isFetched, isLoading, isError, data: course } = useLoadCourseByCode(code)


  if (isError) {
    return null
  }

  if (!isFetched || isLoading) {
    return null
  }



  return (
    <section className={classNames('app-container', style['course-page'])}>
      <Row className="m-30 py-30 pb-50">
        <Col md={16} xs={24}>
          <Row>
            <CourseVideoContainer course={course} />
          </Row>
          <Row className="m-10">
            <CourseLessonDetailContainer code={code} />
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <CourseModuleListContainer code={code} hasQuiz={course.hasQuiz} isQuizExpired={course.isQuizExpired} />
        </Col>
      </Row>
    </section>
  )
}

export default CourseVideo
