import React, { lazy, useEffect } from 'react'
import Debug from 'debug'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Seo from 'components/Seo'
import 'core/i18n'
import ErrorBoundary from 'components/errors/ErrorBoundary'
import { getCurrentRoute } from 'core/helpers'
import { ThemeProvider } from 'styled-components'
import routes from 'config/routes'
import Footer from 'components/Footer'
import { Layout } from 'antd'
import BootStrap from './BootStrap'
import HeaderContainer from 'containers/Header'
import {
  applicationErrorSelector,
  bootstrapLoadingSelector,
  errorSelector,
  headerSelector,
} from 'store/App/selectors'
import {
  darkTheme,
  GlobalStyles,
  lightTheme,
  NotificationProvider,
} from 'amazon-chime-sdk-component-library-react'
import { useSelector } from 'react-redux'
import WaitingPage from 'components/WaitingPage'

/*** Most used pages; those are not lazy loaded ***/
import Home from 'pages/HomePage'
import CourseDetail from 'pages/CourseDetail'
import CoursesOverall from 'pages/CoursesOverall'
import CourseVideo from 'pages/CourseVideo'
import DashboardPage from 'pages/Dashboard/Dashboard'
import User from 'pages/UserPage'
import WebinarDetail from 'pages/WebinarDetail'
import AuthProvider from 'containers/AuthProvider'
import { MeetingStateProvider, useMeetingState } from '../../providers/MeetingStateProvider'
import ErrorProvider from '../../providers/ErrorProvider'
import AgreementProvider from '../AgreementProvider/AgreementProvider'
import AgreementPage from "../../pages/AgreementPage/AgreementPage";
import CourseQuiz from 'pages/CourseQuiz/CourseQuiz'
import LearningPathQuiz from "../../pages/LearningPathQuiz";

/*** Lazy Pages ***/
const Notifications = lazy(() => import('pages/Notifications'))
const MyCertifications = lazy(() => import('pages/MyCertifications'))
const CertificatePage = lazy(() => import('pages/CertificatePage'))
const LearningPathCertificatePage = lazy(() => import('pages/LearningPathCertificatePage'))
const BlockchainCertificatePage = lazy(() => import('pages/BlockchainCertificatePage'))

// Error pages
const NotFoundPage = lazy(() => import('pages/NotFoundPage'))
const ErrorPage = lazy(() => import('pages/ErrorPage'))

// Login page
const Login = lazy(() => import('pages/LoginPage'))

// Forgot Password page
const ForgotPasswordPage = lazy(() => import('pages/ForgotPasswordPage'))

// Registration page
const SelfRegistrationPage = lazy(() => import('pages/SelfRegistrationPage'))

const debug = Debug('Frontend')

const App: React.FC = () => {
  const isLoading = useSelector(bootstrapLoadingSelector)
  const isApplicationError = useSelector(applicationErrorSelector)
  const header = useSelector(headerSelector)

  const error = useSelector(errorSelector)
  const location = useLocation()

  useEffect(() => {
    document.getElementById('root').scrollTo(0, 0)
  }, [])

  const currentRoute = getCurrentRoute(location.pathname)
  const isPrivatePage = currentRoute?.private || false
  const isPublicPage = !isPrivatePage

  debug('App started')

  if (isApplicationError) {
    return (
      <ErrorBoundary>
        <BootStrap />
        <ErrorPage />
      </ErrorBoundary>
    )
  }

  if (isLoading) {
    return (
      <ErrorBoundary>
        <BootStrap />
        <WaitingPage />
      </ErrorBoundary>
    )
  }
  if (error) {
    return (
      <ErrorBoundary>
        <ErrorPage />
      </ErrorBoundary>
    )
  }

  //All public pages, not logged users
  if (isPublicPage) {
    return (
      <ErrorBoundary>
        <Seo />

        <Layout className="app-layout">
          <Layout className="app-layout-container">
            <Layout.Content className="app-content">
              <Routes>
                {/* Login Page */}
                <Route path={routes.login.url} element={<Login />} />
                <Route path={routes.blockChainCertificate.url} element={<BlockchainCertificatePage />} />
                {/* Forgot Password Page */}
                <Route path={routes.forgotPassword.url} element={<ForgotPasswordPage/>} />
                {/* Registration Page */}
                <Route path={routes.selfRegistrationPage.url} element={<SelfRegistrationPage />} />
                {/* Errors and Test Pages */}
                {/*<Route path={routes.errorPage.url} element={<ErrorPage />} />*/}
                {/*<Route path={routes.notFoundPage.url} element={<NotFoundPage />} />*/}
                {/* Fallback is not found page */}
                {/*<Route path="*" element={<Navigate to={routes.notFoundPage.url} />} />*/}
              </Routes>
            </Layout.Content>
          </Layout>
        </Layout>
      </ErrorBoundary>
    )
  }

  //All private + public pages, logged users should also see public pages
  return (
    <ErrorBoundary>
      <Seo />

      <BootStrap />
      <Layout className="app-layout-container">
        {header && <HeaderContainer />}
        <Layout.Content className="app-content">
          <AuthProvider>
            <AgreementProvider>
              <MeetingStateProvider>
                <Theme>
                  <NotificationProvider>
                    <ErrorProvider>
                      <Routes>
                        <Route path={routes.agreement.url} element={<AgreementPage />} />
                        <Route path={routes.courseDetail.url} element={<CourseDetail />} />
                        <Route path={routes.coursesOverall.url} element={<CoursesOverall />} />
                        <Route path={routes.courseVideo.url} element={<CourseVideo />} />
                        <Route path={routes.courseQuiz.url} element={<CourseQuiz />} />
                        <Route path={routes.learningPathQuiz.url} element={<LearningPathQuiz />} />
                        <Route path={routes.dashboard.url} element={<DashboardPage />} />
                        <Route path={routes.notifications.url} element={<Notifications />} />
                        <Route path={routes.home.url} element={<Home />} />
                        <Route path={routes.userPage.url} element={<User />} />
                        <Route path={routes.certifications.url} element={<MyCertifications />} />
                        <Route path={routes.webinarDetail.url} element={<WebinarDetail />} />
                        <Route path={routes.certificate.url} element={<CertificatePage />} />
                        <Route path={routes.learningPathCertificate.url} element={<LearningPathCertificatePage />} />
                        {/* Errors and Test Pages */}
                        {/*<Route path={routes.errorPage.url} element={<ErrorPage />} />*/}
                        {/*<Route path={routes.notFoundPage.url} element={<NotFoundPage />} />*/}
                        {/* Fallback is not found page */}
                        {/*<Route path="*" element={<Navigate to={routes.notFoundPage.url} />} />*/}
                      </Routes>
                    </ErrorProvider>
                  </NotificationProvider>
                </Theme>
              </MeetingStateProvider>
            </AgreementProvider>
          </AuthProvider>
        </Layout.Content>
        {header && <Footer />}
      </Layout>
    </ErrorBoundary>
  )
}

const Theme: React.FC = ({ children }) => {
  const { theme } = useMeetingState()

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}

export default App
