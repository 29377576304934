import { Col, Row, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Description from 'components/Description'
import { useLoadCourseModuleList } from 'services/query'
import { currentLessonSelector } from 'store/Course/selectors'
import { CourseDetail } from 'types/store'

interface Props {
  code: CourseDetail['code']
}

const CourseLessonDetailContainer: React.FC<Props> = ({ code }) => {
  const { t } = useTranslation()
  const { isFetched, isError } = useLoadCourseModuleList(code)
  const currentLesson = useSelector(currentLessonSelector)

  if (isError || !isFetched) {
    return null
  }

  if (!currentLesson) {
    return (
      <Col md={24}>
        <Row justify="center">
          <Typography.Title level={4}>{t('courses.noLectureSelected')}</Typography.Title>
        </Row>
      </Col>
    )
  }

  return <Description title={null} body={currentLesson.description} />
}

CourseLessonDetailContainer.displayName = 'CourseLessonDetailContainer'

export default CourseLessonDetailContainer
