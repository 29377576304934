import { Col, Row, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import style from './styles.module.scss'

interface Props {
  title?: string
  body: string
}

const Description: React.FC<Props> = ({ title, body }) => {
  const { t } = useTranslation()

  return (
    <section className={style['description']}>
      <Row>
        <Col>
          <Typography.Title level={4}>{t('general.description')}:</Typography.Title>
        </Col>
      </Row>
      {title !== null && (
        <Row className="mt-20">
          <Col>
            <Typography.Title level={3}>{title}</Typography.Title>
          </Col>
        </Row>
      )}
      <Row className="mt-20">
        <Col>
          <Typography.Paragraph className="text">
            <ReactMarkdown>{body}</ReactMarkdown>
          </Typography.Paragraph>
        </Col>
      </Row>
    </section>
  )
}

Description.displayName = 'Description'

export default Description
