import React from 'react'
import { Button, PageHeader, Skeleton, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CourseProgressionGrid, { CourseProgressionSkeleton } from 'components/CourseProgressionGrid'
import NoContent from 'components/NoContent'
import routes from 'config/routes'
import { useLoadEnrolledCourses } from 'services/query'

const KeepClimbingContainer: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isFetched, isLoading, isError, data } = useLoadEnrolledCourses()

  const onAllCoursesClick = () => {
    navigate(routes.dashboard.url)
  }

  if (isError) {
    return null
  }

  if (!isFetched || isLoading) {
    return (
      <div>
        <Skeleton className="pageHeader-skeleton" active paragraph={false} />
        <CourseProgressionSkeleton />
      </div>
    )
  }

  const courseList = data.slice(0, 4)
  if (courseList.length <= 0) {
    return <NoContent text={t('dashboard.noCourses')} />
  }
  return (
    <div>
      <PageHeader
        className="direction-column pt-50"
        title={t('courses.keepClimbingTitle')}
        subTitle={t('courses.keepClimbingSubitle')}
      />
      <CourseProgressionGrid courses={courseList} />
      <Button type="ghost" onClick={onAllCoursesClick}>
        <Space style={{ marginLeft: '35px' }}>
          {t('courses.keepClimbingAllCoursesButton')} <img src="/images/icons/thin_big_right.svg" />
        </Space>
      </Button>
    </div>
  )
}

KeepClimbingContainer.displayName = 'KeepClimbingContainer'

export default KeepClimbingContainer
