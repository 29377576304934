import {Button, Tabs, Typography} from 'antd'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import DashboardCoursesContainer from 'containers/DashboardCourses'
import DashboardHeaderContainer, {DashboardMode} from 'containers/DashboardHeader'
import style from './styles.module.scss'
import DashboardLearningPathsContainer from 'containers/DashboardLearningPaths'
import DashboardWebinarContainer from "../../containers/DashboardWebinarContainer";
import DashboardQuizzesContainer from "../../containers/DashboardQuizzes/DashboardQuizzes";
import {useParams} from "react-router-dom";

const {Title} = Typography

const DashboardPage: React.FC = () => {
  const {t} = useTranslation()
  const [activeTab, setActiveTab] = useState(DashboardMode.Courses)
  const [flatMode, setFlatMode] = useState(false)
  const {tab} = useParams();

  useEffect(() => {
    const url = new URL(window.location.href);
    const hashFragment = url.hash.slice(1);
    if (hashFragment && hashFragment === 'quiz')
      setActiveTab(DashboardMode.Quizzes)
  }, []);

  const onTabClick = (tab: DashboardMode) => setActiveTab(tab)
  const onFlatModeToggle = () => setFlatMode((mode) => !mode)
  return (
    <section className={style['dashboard-page']}>
      <section className="app-container my-30">
        <DashboardHeaderContainer mode={activeTab}/>
        <div className="tab-container">

          <Tabs activeKey={activeTab} onTabClick={onTabClick}>
            <Tabs.TabPane tab={t('dashboard.quiz')} key={DashboardMode.Quizzes}>
              <DashboardQuizzesContainer flatMode={flatMode}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('dashboard.learningPath')} key={DashboardMode.LearningPaths}>
              <DashboardLearningPathsContainer flatMode={flatMode}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('dashboard.courses')} key={DashboardMode.Courses}>
              <DashboardCoursesContainer flatMode={flatMode}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('dashboard.webinar')} key={DashboardMode.Webinar}>
              <DashboardWebinarContainer flatMode={flatMode}/>
            </Tabs.TabPane>
          </Tabs>

          <Button className="toggle-button" type="ghost" onClick={onFlatModeToggle}>
            {flatMode ? (
              <img src="images/icons/coolicon.svg" alt="Card Toggle"/>
            ) : (
              <img src="images/icons/list_ul.svg" alt="List Toggle"/>
            )}
          </Button>
        </div>
      </section>
    </section>
  )
}

export default DashboardPage
