import { Typography, Tabs, Avatar } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import UserPanel from 'components/User/UserPanel'

import NotificationsPanel from 'components/User/NotificationsPanel'
import ChangePasswordContainer from 'containers/ChangePassword'
import style from './styles.module.scss'

interface Props {
  name: string
  surname: string
  email: string
}

const UserProfile: React.FC<Props> = ({ name, surname, email }) => {
  const { t } = useTranslation()
  const { TabPane } = Tabs
  const firstLetter = name.charAt(0)
  return (
    <section className={style['user-profile']}>
      <Tabs tabPosition="left" defaultActiveKey="1">
        <TabPane
          disabled
          tab={
            <div className="user-image">
              <Avatar size={126}>{firstLetter}</Avatar>

              <Typography.Title level={5}>
                {name} {surname}
              </Typography.Title>
            </div>
          }
          key="0"
        ></TabPane>
        <TabPane className="no-upp" tab={t('menu.profile')} key="1">
          <UserPanel name={name} surname={surname} email={email} />
        </TabPane>
        {/* <TabPane className="photo-panel no-upp " tab={t('menu.userPhoto')} key="2">
          <PhotoPanel />
        </TabPane> */}
        <TabPane className="no-upp" tab={t('menu.userPassword')} key="3">
          <ChangePasswordContainer />
        </TabPane>
        <TabPane className="no-upp" tab={t('menu.notifications')} key="5">
          <NotificationsPanel />
        </TabPane>
      </Tabs>
    </section>
  )
}

UserProfile.displayName = 'UserProfile'

export default UserProfile
