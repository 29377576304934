import { Card, Col, Divider, Row, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import style from './styles.module.scss'

const { Title } = Typography

interface Props {
  courseCount: number
  lessonCount: number
  webinarWatched: number
  webinarCount: number
  hourCount: number
  hourTotal: number
}

const CourseProgressionInfo: React.FC<Props> = ({
  lessonCount,
  courseCount,
  webinarCount,
  webinarWatched,
  hourCount,
  hourTotal,
}) => {
  const { t } = useTranslation()

  return (
    <Card className={style['course-progression-info']}>
      <Card.Meta
        className="pl-10 pr-10"
        title={
          <Col>
            <Title className="title" level={5}>
              {t('dashboard.courseProgressiontTitle')}
            </Title>
            <Divider />
          </Col>
        }
      />
      <Row className="info-row pl-10 pr-10">
        <Col md={12}>
          <Row>
            <Col md={2}>{lessonCount}</Col>
            <Col md={22}>{t('dashboard.completedLesson', { count: lessonCount })}</Col>
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <Col md={2}>{courseCount}</Col>
            <Col md={22}>{t('dashboard.completedCourse', { count: courseCount })}</Col>
          </Row>
        </Col>
      </Row>
      <Row className="info-row pl-10 pr-10">
        <Col md={12}>
          <Row>
            <Col md={2}>{webinarWatched}</Col>
            <Col md={22}>{t('dashboard.watchedWebinars', { count: webinarWatched })}</Col>
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <Col md={2}>{webinarCount}</Col>
            <Col md={22}>{t('dashboard.completedWebinars', { count: webinarCount })}</Col>
          </Row>
        </Col>
      </Row>
      <Row className="pl-10 pr-10">
        <ReactMarkdown className="completed-hours">
          {t('dashboard.completedHours', { p0: hourCount, p1: hourTotal })}
        </ReactMarkdown>
      </Row>
    </Card>
  )
}

CourseProgressionInfo.displayName = 'CourseProgressionInfo'

export default CourseProgressionInfo
