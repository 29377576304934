import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  MeetingStatus,
  useNotificationDispatch,
  Severity,
  ActionType,
  useMeetingStatus,
} from 'amazon-chime-sdk-component-library-react'

const useMeetingEndRedirect = () => {
  const history = useNavigate()
  const dispatch = useNotificationDispatch()
  const meetingStatus = useMeetingStatus()

  useEffect(() => {
    if (meetingStatus === MeetingStatus.Ended) {
      console.log('[useMeetingEndRedirect] Meeting ended')
      dispatch({
        type: ActionType.ADD,
        payload: {
          severity: Severity.INFO,
          message: 'The meeting was ended by another attendee',
          autoClose: true,
          replaceAll: true,
        },
      })
      history('../../')
    }
  }, [meetingStatus])
}

export default useMeetingEndRedirect
