import { Button, Row, Space, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import style from './styles.module.scss'

interface Props {
  flatMode: boolean
  onFlatModeToggle: () => void
}

const LearningPathListTitle: React.FC<Props> = ({ flatMode, onFlatModeToggle }) => {
  const { t } = useTranslation()

  return (
    <Row className={style['learning-path-list-title']}>
      <Space>
        <img src="/images/icons/error_outline.svg" alt="Attention" />
        <Typography.Title level={4}>{t('learningPaths.listTitle')}</Typography.Title>
      </Space>
      <Button className="toggle-button" type="ghost" onClick={onFlatModeToggle}>
        {flatMode ? (
          <img src="images/icons/coolicon.svg" alt="Card Toggle" />
        ) : (
          <img src="images/icons/list_ul.svg" alt="List Toggle" />
        )}
      </Button>
    </Row>
  )
}

LearningPathListTitle.displayName = 'LearningPathListTitle'

export default LearningPathListTitle
