import React from 'react'
import {useTranslation} from 'react-i18next'

import CourseProgressionGrid from 'components/CourseProgressionGrid'
import CourseList from 'components/CourseList'
import NoContent from 'components/NoContent'
import {useLoadEnrolledCourses} from 'services/query'

interface Props {
  flatMode: boolean
}

const DashboardCoursesContainer: React.FC<Props> = ({flatMode}) => {
  const {t} = useTranslation()
  const {isFetched, isLoading, isError, data: courses} = useLoadEnrolledCourses()

  if (isError) {
    return null
  }

  if (!isFetched || isLoading) {
    return null
  }

  if (courses.length <= 0) {
    return <NoContent text={t('dashboard.noCourses')}/>
  }

  const coursesFiltered = courses.filter((c) => c.courseType === 'OnDemand')

  return flatMode ? (
    <>
      {coursesFiltered.map((course, index) => (
        <CourseList key={`${course.code}_${index}`} course={course}/>
      ))}
    </>
  ) : (
    <CourseProgressionGrid courses={coursesFiltered}/>
  )
}

DashboardCoursesContainer.displayName = 'DashboardCoursesContainer'

export default DashboardCoursesContainer
