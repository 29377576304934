import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import style from './styles.module.scss'

interface Props {
  name: string
  onSearch: (text: string) => void
}

const WelcomeHeader: React.FC<Props> = ({ name, onSearch }) => {
  const { t } = useTranslation()
  const searchRef = useRef<Input>(null)

  const search = useCallback(() => {
    onSearch(searchRef.current.state.value?.trim())
  }, [onSearch])
  const onSearchKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const keyCode = event.code || event.key
      if (keyCode == 'Enter') {
        search()
        return false
      }
    },
    [search]
  )

  return (
    <section className={style['welcome-header']}>
      <div className="content-header">
        <div className="app-container ">
          <div>
            <Typography.Title className="welcome">
              {t('home.welcome', { p0: name })}
            </Typography.Title>
            <Typography.Title level={4}>{t('home.citation')}</Typography.Title>
            <Typography.Title level={4} className="m-0">
              {t('home.nameCitation')}
            </Typography.Title>
            <Input
              ref={searchRef}
              placeholder="Cerca corsi"
              size="large"
              className="light mt-60"
              prefix={<SearchOutlined onClick={search} />}
              onKeyPress={onSearchKeyPress}
            />
          </div>
          <div>
            <Typography.Title className="center m-10" level={2}>
              {t('home.endWelcome')}
            </Typography.Title>
          </div>
        </div>
      </div>
    </section>
  )
}

WelcomeHeader.displayName = 'WelcomeHeader'

export default WelcomeHeader
