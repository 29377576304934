import { LOGOUT } from 'store/App/constants'
import { LogoutAction } from 'store/App/types'
import * as K from './constants'
import { CourseActionTypes, CourseState } from './types'

// The initial state of the Reducer
export const initialState: CourseState = {
  currentWebinar: null,
  currentEdition: null,
  currentLesson: null,
  categoryList: [],
}

const reducer = (state = initialState, action: CourseActionTypes | LogoutAction): CourseState => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        currentLesson: initialState.currentLesson,
      }
    case K.SET_CURRENT_LESSON:
      return {
        ...state,
        currentLesson: action.payload,
      }
    case K.SET_CURRENT_EDITION:
      return {
        ...state,
        currentEdition: action.payload,
      }
    case K.SET_CURRENT_WEBINAR:
      return {
        ...state,
        currentWebinar: action.payload,
      }
    default:
      return state
  }
}

export default reducer
