import { CourseStatus, EventOrder, EventType } from 'types/enums'
import { Category, CourseFilter } from 'types/store'

export const filterToQuery = (filter: Partial<CourseFilter>): string => {
  const cleanEntries = Object.entries(filter)
    .filter(([key, value]) => value !== null)
    .map(([key, value]) => [key, key === 'search' ? value : value.toLowerCase()])
  const query = new URLSearchParams(Object.fromEntries(cleanEntries))
  return query.toString()
}

export const filterFromQuery = (query: string): CourseFilter => {
  const queryParams = new URLSearchParams(query)
  return {
    type: (queryParams.get('type')?.toUpperCase() as EventType) ?? EventType.Course,
    order: (queryParams.get('order')?.toUpperCase() as EventOrder) ?? null,
    category: (queryParams.get('category')?.toUpperCase() as Category['code']) ?? null,
    status: (queryParams.get('status')?.toUpperCase() as CourseStatus) ?? null,
    search: queryParams.get('search') ?? null,
  }
}
