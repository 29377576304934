import { Address, Company } from 'types/store/user'
import { AddressData, CompanyListData, CompanyCreateData } from 'types/api'
import { addressDefault, contactDefault } from './common'
import Debug from 'debug'

const debug = Debug('services:map:company')

export const mapAddress = (data: AddressData): Address => {
  try {
    return {
      street: `${data?.street || ''}`,
      number: `${data?.number || ''}`,
      zip: `${data?.zip || ''}`,
      city: `${data?.city || ''}`,
      county: `${data?.county || ''}`,
      country: `${data?.country || ''}`,
    }
  } catch (error) {
    debug(error)
    throw Error('Mapping error')
  }
}

export const mapCompany = (data: CompanyListData): Company => {
  try {
    const mapped: Company = {
      id: Number(data.id),
      name: `${data.name || ''}`,
      vat: `${data.vat || ''}`,
      taxCode: `${data.taxCode || ''}`,
      legalAddress: data.legalAddress
        ? {
            street: data.legalAddress.legAddrstreet,
            number: data.legalAddress.legAddrnumber,
            zip: data.legalAddress.legAddrzip,
            city: data.legalAddress.legAddrcity,
            county: data.legalAddress.legAddrcounty,
            country: data.legalAddress.legAddrcountry,
          }
        : addressDefault,
      billingAddress: data.billingAddress
        ? {
            street: data.billingAddress.billAddrstreet,
            number: data.billingAddress.billAddrnumber,
            zip: data.billingAddress.billAddrzip,
            city: data.billingAddress.billAddrcity,
            county: data.billingAddress.billAddrcounty,
            country: data.billingAddress.billAddrcountry,
          }
        : addressDefault,
      primaryContact: data.primaryContact
        ? {
            name: data.primaryContact.contactName,
            surname: data.primaryContact.contactSurname,
            email: data.primaryContact.contactEmail,
            telephone: data.primaryContact.contactTelephone,
          }
        : contactDefault,
      notes: data.notes,
      thumbnailUrl: data.thumbnailUrl ?? null,
    }
    return mapped
  } catch (error) {
    debug(error)
    throw Error('Mapping error')
  }
}

export const mapCompanyCreateFromCompany = (entity: Omit<Company, 'id'>): CompanyCreateData => {
  try {
    const mapped: CompanyCreateData = {
      name: entity.name,
      vat: entity.vat,
      taxCode: entity.taxCode,
      legalAddress: {
        legAddrstreet: entity.legalAddress.street,
        legAddrnumber: entity.legalAddress.number,
        legAddrzip: entity.legalAddress.zip,
        legAddrcity: entity.legalAddress.city,
        legAddrcounty: entity.legalAddress.county,
        legAddrcountry: entity.legalAddress.country,
      },
      billingAddress: {
        billAddrstreet: entity.billingAddress.street,
        billAddrnumber: entity.billingAddress.number,
        billAddrzip: entity.billingAddress.zip,
        billAddrcity: entity.billingAddress.city,
        billAddrcounty: entity.billingAddress.county,
        billAddrcountry: entity.billingAddress.country,
      },
      primaryContact: {
        contactName: entity.primaryContact.name,
        contactSurname: entity.primaryContact.surname,
        contactEmail: entity.primaryContact.email,
        contactTelephone: entity.primaryContact.telephone,
      },
      notes: entity.notes,
    }
    return mapped
  } catch (error) {
    debug(error)
    throw Error('Mapping error')
  }
}

export const composeCompanyMap = (companyList: Company[]): Record<Company['id'], Company> => {
  try {
    const companyMap = companyList.reduce((acc, company) => {
      acc[company.id] = company
      return acc
    }, {} as Record<Company['id'], Company>)
    return companyMap
  } catch (error) {
    debug(error)
    throw Error('Mapping error')
  }
}

export function hidrateCompany<T extends { company: Company }>(
  entity: T,
  companyMap: Record<Company['id'], Company>
) {
  try {
    const companyId = entity.company.id
    const hidrated = { ...entity }
    if (companyId && companyId in companyMap) {
      hidrated.company = companyMap[companyId]
    }
    return hidrated
  } catch (error) {
    debug(error)
    throw Error('Mapping error')
  }
}

// export function hidrateCompanyOnList<T extends { company: Company }>(
//   entityList: T[],
//   companyMap: Record<Company['id'], Company>
// ) {
//   try {
//     return entityList.map((entity) => hidrateCompany(entity, companyMap));
//   } catch (error) {
//     debug(error);
//     throw Error('Mapping error');
//   }
// };
