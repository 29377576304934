import Debug from 'debug'

import {getApplicationError} from 'core'
import {
  CourseDetailData,
  CourseListData,
  CourseModuleListData,
  CourseProgressionData,
  EnrolledCourseListData,
  LearningPathProgressionData,
  LectureListData,
} from 'types/api'
import {ErrorCode} from 'types/enums'
import {Category, Course, CourseDetail, CourseEnrolled, LearningPath, Lesson, Module,} from 'types/store'
import {resolveUrl} from 'core/helpers'
import {categoryPlaceholder, mapBoolean, mapDate, mapNumber, mapString} from './common'
import {mapCategoryCode} from './user'

const debug = Debug('services:map:course')

export const mapLessonCode = (data: number): Lesson['code'] => `${data}`
export const mapFromLessonCode = (code: Lesson['code']): number => Number.parseInt(code)

export const mapCourseCode = (data: number): Course['code'] => `${data}`
export const mapFromCourseCode = (code: Course['code']): number => Number.parseInt(code)

export const mapLearningPathCode = (data: number): LearningPath['code'] => `${data}`
export const mapFromLearningPath = (code: LearningPath['code']): number => Number.parseInt(code)

export const mapLesson = (
  data: LectureListData,
  lookup: {
    enrolledCourse: CourseEnrolled | null
  }
): Lesson => {
  const {enrolledCourse} = lookup
  try {
    const code = mapLessonCode(data.id)
    const progress = enrolledCourse?.lessonProgress[code]
    return {
      code,
      title: mapString(data.title),
      description: mapString(data.description),
      completed: progress >= 1,
      duration: mapNumber(data.duration),
      videoUrl: resolveUrl(data.videoLink),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapFromLesson = (data: Lesson): LectureListData => {
  try {
    return {
      id: Number.parseInt(data.code),
      title: data.title,
      // completed: false, @TODO missing field
      duration: data.duration,
      videoLink: data.videoUrl,
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapLessonList = (
  data: LectureListData[],
  lookup: {
    enrolledCourse: CourseEnrolled | null
  }
): Lesson[] => {
  try {
    return data
      .sort((itemA, itemB) => itemA.order - itemB.order)
      .map((item) => mapLesson(item, lookup))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapModule = (
  data: CourseModuleListData,
  lookup: {
    enrolledCourse: CourseEnrolled | null
  }
): Module => {
  try {
    return {
      code: `${data.id}`,
      title: `${data.title}`,
      lessons: mapLessonList(data.lectures || [], lookup),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapModuleList = (
  data: CourseModuleListData[],
  lookup: {
    enrolledCourse: CourseEnrolled | null
  }
) => {
  try {
    return data
      .sort((itemA, itemB) => itemA.order - itemB.order)
      .map((item) => mapModule(item, lookup))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCourse = (
  data: CourseListData,
  lookup: {
    categoryMap: Record<Category['code'], Category>
    enrolledCourseMap: Record<Course['code'], Course>
  }
): Course => {
  try {
    const {categoryMap, enrolledCourseMap} = lookup
    const enrolled = enrolledCourseMap[mapCourseCode(data.id)] ?? null
    return {
      code: mapCourseCode(data.id),
      title: mapString(data.title),
      thumbnailImg: resolveUrl(data.thumbnailUrl),
      mandatory: enrolled ? enrolled.mandatory : false,
      started: enrolled ? enrolled.started : false,
      rolledOut: data.rolledOut,
      checkUserAttention: data.checkUserAttention ?? false,
      category: categoryMap[mapCategoryCode(data.categoryId)] ?? categoryPlaceholder,
      descriptionTitle: mapString(data.description1),
      description: mapString(data.description2),
      courseType: data.courseType,
      duration: mapNumber(data.duration),
      dueDate: enrolled ? enrolled.dueDate : null,
      creationDate: data.createdAt ? new Date(data.createdAt) : null,
      enrollmentDate: enrolled ? enrolled.enrollmentDate : null,
      progressPercentage: enrolled ? enrolled.progressPercentage : null,
      hasQuiz: enrolled ? enrolled.hasQuiz : null,
      isQuizExpired: enrolled ? enrolled.isQuizExpired : null,
      quizExpirationDate: enrolled ? enrolled.quizExpirationDate : null,
      successfulAttempt: enrolled ? enrolled.successfulAttempt : null,
      transactionHash: enrolled ? enrolled.transactionHash : null,
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCourseList = (
  data: CourseListData[],
  lookup: {
    categoryMap: Record<Category['code'], Category>
    enrolledCourseMap: Record<Course['code'], Course>
  }
): Course[] => {
  try {
    return data.map((item) => mapCourse(item, lookup))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCourseProgression = (
  data: CourseProgressionData,
  lookup: {
    categoryMap: Record<Category['code'], Category>
    enrolledCourseMap: Record<Course['code'], Course>
  }
): Course => {
  try {
    const {categoryMap, enrolledCourseMap} = lookup
    const enrolled = enrolledCourseMap[mapCourseCode(data.id)] ?? null
    return {
      code: mapCourseCode(data.id),
      title: mapString(data.title),
      thumbnailImg: resolveUrl(data.thumbnailUrl),
      mandatory: enrolled ? enrolled.mandatory : false,
      courseType: data.courseType,
      started: enrolled ? enrolled.started : false,
      rolledOut: mapBoolean(data.rolledOut),
      checkUserAttention: mapBoolean(data.checkUserAttention),
      category: categoryMap[mapCategoryCode(data.categoryId)] ?? categoryPlaceholder,
      descriptionTitle: mapString(data.description1),
      description: mapString(data.description2),
      duration: mapNumber(data.duration),
      dueDate: enrolled ? enrolled.dueDate : null,
      creationDate: null,
      enrollmentDate: enrolled ? enrolled.enrollmentDate : null,
      progressPercentage: enrolled ? enrolled.progressPercentage : null,
      hasQuiz: enrolled ? enrolled.hasQuiz : null,
      isQuizExpired: enrolled ? enrolled.isQuizExpired : null,
      quizExpirationDate: enrolled ? enrolled.quizExpirationDate : null,
      successfulAttempt: enrolled ? enrolled.successfulAttempt : null,
      transactionHash: enrolled ? enrolled.transactionHash : null
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCourseProgressionList = (
  data: CourseProgressionData[],
  lookup: {
    categoryMap: Record<Category['code'], Category>
    enrolledCourseMap: Record<Course['code'], Course>
  }
): Course[] => {
  try {
    return data.map((item) => mapCourseProgression(item, lookup))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapEnrolledCourse = (
  data: EnrolledCourseListData,
  lookup: {
    categoryMap: Record<Category['code'], Category>
  }
): CourseEnrolled => {
  try {
    const {categoryMap} = lookup
    const lessonProgress: Record<Lesson['code'], number> = {}
    if (data.modulesProgressData) {
      for (const item of data?.modulesProgressData) {
        for (const {id, progress} of item.lectures) {
          lessonProgress[id] = progress / 100
        }
      }
    }
    return {
      code: mapCourseCode(data.id),
      title: mapString(data.title),
      thumbnailImg: resolveUrl(data.thumbnailUrl),
      mandatory: false,
      started:
        data?.modulesProgressData?.findIndex((progressData) => {
          const lectureProgressGTzero = (progressLectures) => progressLectures.progress > 0
          return progressData.lectures?.findIndex(lectureProgressGTzero) >= 0
        }) >= 0,
      rolledOut: mapBoolean(data.rolledOut),
      checkUserAttention: mapBoolean(data.checkUserAttention),
      category: categoryMap[mapCategoryCode(data.categoryId)] ?? categoryPlaceholder,
      descriptionTitle: mapString(data.description1),
      description: mapString(data.description2),
      duration: mapNumber(data.duration),
      dueDate: null,
      creationDate: mapDate(data.createdAt),
      courseType: data.courseType,
      enrollmentDate: null,
      progressPercentage: data.rolledOut ? data.progress / 100 : null,
      lessonProgress,
      hasQuiz: data.hasQuiz,
      isQuizExpired: data.isQuizExpired,
      quizExpirationDate: data.quizExpirationDate,
      successfulAttempt: data.successfulAttempt,
      transactionHash: data.transactionHash
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapEnrolledCourseList = (
  data: EnrolledCourseListData[],
  lookup: {
    categoryMap: Record<Category['code'], Category>
  }
): CourseEnrolled[] => {
  try {
    return data.map((item) => mapEnrolledCourse(item, lookup))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCourseDetail = (
  data: CourseDetailData,
  lookup: {
    categoryMap: Record<Category['code'], Category>
    enrolledCourseMap: Record<CourseEnrolled['code'], CourseEnrolled>
  }
): CourseDetail => {
  try {
    const {categoryMap, enrolledCourseMap} = lookup
    const enrolled = enrolledCourseMap[mapCourseCode(data.id)] ?? null
    return {
      code: mapCourseCode(data.id),
      title: mapString(data.title),
      thumbnailImg: resolveUrl(data.thumbnailUrl),
      mandatory: enrolled ? enrolled.mandatory : false,
      started: enrolled ? enrolled.started : false,
      rolledOut: mapBoolean(data.rolledOut),
      checkUserAttention: mapBoolean(data.checkUserAttention),
      courseType: data.courseType,
      category: categoryMap[mapCategoryCode(data.categoryId)] ?? categoryPlaceholder,
      descriptionTitle: mapString(data.description1),
      description: mapString(data.description2),
      duration: mapNumber(data.duration),
      dueDate: enrolled ? enrolled.dueDate : null,
      creationDate: mapDate(data.createdAt),
      enrollmentDate: enrolled ? enrolled.enrollmentDate : null,
      progressPercentage: enrolled ? enrolled.progressPercentage : null,
      teacher: data.teacher,
      nameOfTheTeacher: data.nameOfTheTeacher,
      hasQuiz: data.hasQuiz,
      isQuizExpired: data.isQuizExpired,
      quizExpirationDate: data.quizExpirationDate,
      lessonCount: data.modules.reduce((acc, module) => acc + (module.lectures?.length ?? 0), 0),
      modules: mapModuleList(data.modules, {enrolledCourse: enrolled}),
      successfulAttempt: enrolled ? enrolled.successfulAttempt : null,
      transactionHash: enrolled ? enrolled.transactionHash : null,
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCourseDetailList = (
  data: CourseDetailData[],
  lookup: {
    categoryMap: Record<Category['code'], Category>
    enrolledCourseMap: Record<CourseEnrolled['code'], CourseEnrolled>
  }
) => {
  try {
    return data.map((item) => mapCourseDetail(item, lookup))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapLearningPath = (
  data: LearningPathProgressionData,
  lookup: {
    categoryMap: Record<Category['code'], Category>
    enrolledCourseMap: Record<Course['code'], Course>
  }
): LearningPath => {
  try {
    return {
      code: mapLearningPathCode(data.id),
      title: mapString(data.title),
      description: mapString(data.description),
      dueDate: mapDate(data.dueDate),
      courses: mapCourseProgressionList(data.courses, lookup),
      successfulAttempt: mapBoolean(data.successfulAttempt),
      allCoursesCompleted: mapBoolean(data.allCoursesCompleted),
      quizExpirationDate: data.quizExpirationDate,
      hasQuiz: mapBoolean(data.hasQuiz),
      isQuizExpired: mapBoolean(data.isQuizExpired),
      transactionHash: mapString(data.transactionHash)
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapLearningPathList = (
  data: LearningPathProgressionData[],
  lookup: {
    categoryMap: Record<Category['code'], Category>
    enrolledCourseMap: Record<Course['code'], Course>
  }
): LearningPath[] => {
  try {
    return data.map((item) => mapLearningPath(item, lookup))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const setLessonProgressOnEnrolledCourseData = (
  data: EnrolledCourseListData,
  lessonId: number,
  progress: number
) => {
  return {
    ...data,
    modulesProgressData: data?.modulesProgressData?.map((data) => ({
      ...data,
      lectures: data.lectures.map((lecture) => {
        if (lecture.id === lessonId) {
          return {
            ...lecture,
            progress,
          }
        }
        return lecture
      }),
    })),
  }
}
