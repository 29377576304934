import {config} from 'core'
import {getAxios} from './apiService'
import {getApiError, getAuthenticationHeader} from './common'
import {AttemptCreateData, AttemptListData, QuizDone, QuizFullData} from "../types/store";
import {mapGivenAnswers} from "../services/map/quiz";

const axios = getAxios()


export async function getFullQuizByCourseId(
  courseId: number
): Promise<QuizFullData> {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.get(config.api.geFullQuizByCourseId(courseId), headers)
    return response.data as QuizFullData
  } catch (error) {
    throw getApiError(error)
  }
}


export async function createQuizAttempt(
  courseId: number,
  quizId: number,
  userId: number,
  quizOriginal: QuizFullData,
  quizDone: QuizDone
) {
  try {

    const attempt: AttemptCreateData = {
      userId: userId,
      quizId: quizId,
      courseId: courseId,
      givenAnswers: mapGivenAnswers(quizOriginal.questions, quizDone.questions, quizDone.userInput)
    }

    const headers = await getAuthenticationHeader()
    const response = await axios.post(config.api.postQuizAttempt(courseId, quizId, userId), attempt, headers);
    return response.data as AttemptListData;
  } catch (error) {
    throw getApiError(error)
  }
}

export async function getFullQuizByLearningPathId(
  learningPathId: number
): Promise<QuizFullData> {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.get(config.api.geFullQuizByLearningPathId(learningPathId), headers)
    return response.data as QuizFullData
  } catch (error) {
    throw getApiError(error)
  }
}


export async function createLearningPathQuizAttempt(
  learningPathId: number,
  quizId: number,
  userId: number,
  quizOriginal: QuizFullData,
  quizDone: QuizDone
) {
  try {

    const attempt: AttemptCreateData = {
      userId: userId,
      quizId: quizId,
      courseId: learningPathId,
      givenAnswers: mapGivenAnswers(quizOriginal.questions, quizDone.questions, quizDone.userInput)
    }

    const headers = await getAuthenticationHeader()
    const response = await axios.post(config.api.postLearningPathQuizAttempt(learningPathId, quizId, userId), attempt, headers);
    return response.data as AttemptListData;
  } catch (error) {
    throw getApiError(error)
  }
}
