import { config } from 'core'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import {
  CategoryListData,
  UserRegistrationData,
  UserListData,
  UserDetailData,
  RegisterData, UserAgreementData,
} from 'types/api'
import { AxiosError } from 'axios'
import { Webinar } from '../types/store'
import { RosterAttendeeType } from 'amazon-chime-sdk-component-library-react/lib/types'
import {GetAttendeeResponse} from "../providers/VideoTileGridProvider/state";

const axios = getAxios()

export async function getUsers(): Promise<UserListData[]> {
  try {
    const { headers } = await getAuthenticationHeader()
    const response = await axios.get(config.api.getUsers(), {
      headers,
    })
    return response.data as UserListData[]
  } catch (error) {
    throw getApiError(error)
  }
}

export async function editUserAgreementData(model: UserAgreementData): Promise<UserAgreementData> {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.post(config.api.editUserAgreement(), model, headers)
    return response.data as UserAgreementData
  } catch (error) {
    throw getApiError(error as AxiosError);
  }
}

export async function getAttendeeNameByWebinarCodeAndEditionCodeAndAttendeeId(
  webinarCode: Webinar['code'],
  editionCode: Webinar['code'],
  attendeeId: RosterAttendeeType['name']
): Promise<GetAttendeeResponse> {
  try {
    const headers = await getAuthenticationHeader()
    console.log('***gettingNames')
    return await axios.get(
      config.api.getAttendeeNameByWebinarCodeAndEditionCodeAndAttendeeId(
        +webinarCode,
        +editionCode,
        attendeeId
      ),
      headers
    )
  } catch (error) {
    throw getApiError(error)
  }
}

export async function getMyUser(): Promise<UserListData> {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.get(config.api.getMyUser(), headers)

    return response.data as UserListData
  } catch (error) {
    throw getApiError(error)
  }
}

export async function getMyUserAgreement(): Promise<UserAgreementData> {

  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.get(config.api.getUserAgreement(), headers)

    return response.data as UserAgreementData
  } catch (error) {
    throw getApiError(error)
  }
}

export async function getUserById(userId: number): Promise<UserDetailData> {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.get(config.api.getUserById(userId), headers)
    return response.data as UserDetailData
  } catch (error) {
    throw getApiError(error)
  }
}

export async function getCategories(): Promise<CategoryListData[]> {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.get(config.api.getCategories(), headers)
    return response.data as CategoryListData[]
  } catch (error) {
    throw getApiError(error)
  }
}

export async function registerUser(model: UserRegistrationData): Promise<UserListData> {
  try {
    const response = await axios.post(config.api.registration(), model)
    return response.data as UserListData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getRegistration(token: string): Promise<RegisterData> {
  try {
    const response = await axios.get(config.api.registration(), {
      params: { token },
    })
    return response.data as RegisterData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
