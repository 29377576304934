import {Button, Col, Row, Typography} from 'antd'
import routes from 'config/routes'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, Link, useNavigate} from 'react-router-dom'
import {CourseEnrolled, LearningPath} from 'types/store'
import style from './styles.module.scss'

const {Title} = Typography

interface Props {
  element: LearningPath | CourseEnrolled
  type: "LEARNING_PATH" | "COURSE"
}

const QuizItem: React.FC<Props> = ({element, type}) => {
  const navigate = useNavigate()
  const {t} = useTranslation()

  return (
    <Col className={style['learning-path-list-item']}>
      <Row justify='space-between' align='middle'>
        <Title level={5}>{element.title}</Title>

        <Button type="primary">
          {type === 'LEARNING_PATH' && <Link to={generatePath(routes.learningPathQuiz.url, {code: element.code})}>
            {t('courses.quiz')}
          </Link>}

          {type === 'COURSE' && <Link to={generatePath(routes.courseQuiz.url, {code: element.code})}>
            {t('courses.quiz')}
          </Link>}
        </Button>
      </Row>
    </Col>
  )
}

QuizItem.displayName = 'QuizItem'

export default QuizItem
