import { Button } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'
import routes from 'config/routes'
import { Course } from 'types/store'
import { useLoadCurrentUser, useLoadWebinarEditionList } from '../../services/query'
import { Constants } from '../../utils/constants'

export enum CourseLinkType {
  discover = 'DISCOVER',
  rollOut = 'ROLL_OUT',
}

interface PropsDiscover {
  type: CourseLinkType.discover
  className?: string
  course: Course
  loading?: boolean
}

interface PropsRollOut {
  type: CourseLinkType.rollOut
  className?: string
  course: Course
  loading?: boolean
  onRollOut: (course: Course) => void
}

type Props = PropsDiscover | PropsRollOut

const WebinarLink: React.FC<Props> = (props) => {
  const { className, course, loading = false } = props
  const { t } = useTranslation()
  const { progressPercentage } = course
  const { data: user } = useLoadCurrentUser()

  if (progressPercentage >= Constants.editionProgressThreshold) {
    return (
      <Button className={className} type="primary" block loading={loading}>
        <Link to={generatePath(routes.certificate.url, { code: course.code })}>
          {t('courses.completed')}
        </Link>
      </Button>
    )
  }

  const {
    isFetched,
    isLoading,
    isError,
    data: editions,
    dataUpdatedAt,
  } = useLoadWebinarEditionList(course.code)

  if (isError) {
    return null
  }

  if (!isFetched || isLoading) {
    return null
  }

  const futureEditions = editions.filter((e) => e.date > new Date())

  if (futureEditions.length >= 1) {
    const userEditionsCode = user.editions.map((e) => e.code)

    if (futureEditions.some((f) => userEditionsCode.includes(f.code))) {
      return (
        <Button className={className} type="primary" block loading={loading}>
          <Link to={generatePath(routes.webinarDetail.url, { code: course.code })}>
            {t('general.attend')}
          </Link>
        </Button>
      )
    } else {
      return (
        <Button className={className} type="primary" block loading={loading}>
          <Link to={generatePath(routes.webinarDetail.url, { code: course.code })}>
            {t('general.subscribe')}
          </Link>
        </Button>
      )
    }
  }
  return (
    <Button className={className} type="primary" block loading={loading}>
      <Link to={generatePath(routes.webinarDetail.url, { code: course.code })}>
        {t('courses.discover')}
      </Link>
    </Button>
  )
}

WebinarLink.displayName = 'WebinarLink'

export default WebinarLink
