import { useQuery } from 'react-query'

import { getSettings } from 'api/setting'
import * as queryKeys from 'config/queryKeys'
import { useDebugValue, useMemo } from 'react'

export function useLoadSettings() {
  const queryFunction = async () => {
    const data = await getSettings()
    return data
  }
  const resultQuery = useQuery(queryKeys.settings(), queryFunction, {
    placeholderData: {},
    cacheTime: Infinity,
  })
  useDebugValue(
    resultQuery.isError ? 'Reported error from query' : `Environment ${resultQuery.data.environment}`
  )
  return resultQuery
}

export function useSettingValue(key: string) {
  const { isError, data: settings } = useLoadSettings()
  const settingValue = useMemo(() => {
    if (isError) {
      return ''
    }

    if (!settings?.settings) {
      return ''
    }

    for (const entry of settings.settings) {
      if (entry.key === key) {
        return entry.value
      }
    }

    return ''
  }, [key, isError])
  useDebugValue(`${key}: ${settingValue}`)
  return settingValue
}
