import React, {useEffect, useState} from 'react'
import Quiz from 'react-quiz-component';
import {QuizDone} from "../../types/store";
import {createQuizAttempt} from "../../api/quiz";
import {useCurrentUserId} from "../../services/query";
import {generatePath, Link, useParams} from "react-router-dom";

import {useTranslation} from "react-i18next";
import routes from "../../config/routes";
import {Button, Col, Row, Typography} from "antd";
import Spinner from "../icons/Spinner";

interface Props {
  quizData: any
}

const CourseQuiz: React.FC<Props> = ({quizData}) => {

  const [requesting, setRequesting] = useState(false)
  const [quizDone, setQuizDone] = useState(false)
  const [quizPassed, setQuizPassed] = useState(false)

  const createQuizComponent = (retry = false) => {
    if (retry) {
      setRequesting(false)
      setQuizDone(false)
      setQuizPassed(false)
      setQuizComponent(<></>)
    }

    setQuizComponent(<Quiz
      key={Math.random()}
      quiz={quizData}
      onComplete={createAttempt}
    />)
  }
  const [quizComponent, setQuizComponent] = useState(<></>)

  useEffect(() => {
    createQuizComponent(false)
  }, [])

  if (!quizData) {
    return null
  }

  const {originalQuiz} = quizData


  const userId = useCurrentUserId()
  const code = +(useParams() as any).code
  const {t} = useTranslation()

  const createAttempt = async (quizDone: QuizDone) => {
    setRequesting(true)
    try {
      const attempt = await createQuizAttempt(code, originalQuiz.id, userId, originalQuiz, quizDone)
      setQuizPassed(attempt.passed)
      setQuizDone(true)
      setRequesting(false)
    } catch (e) {
      console.log(e)
    }

  }


  return (
    <>
      <Row>
        <Col md={18}>
          <Quiz
            quiz={quizData}
            onComplete={createAttempt}
          />
        </Col>
      </Row>

      {requesting &&
        <Col md={6}>

          <Spinner></Spinner>
          <Typography.Text className="m-10 caption">Correzione quiz in corso...</Typography.Text>

        </Col>
      }
      {
        quizDone && quizPassed &&
        <Col md={6}>
          <Button className={"mt-50"} type="primary" block loading={requesting}>
            <Link to={generatePath(routes.certificate.url, {code: "" + code})}>
              {t('courses.completed')}
            </Link>
          </Button>
        </Col>
      }
      {
        quizDone && !quizPassed &&
        <Col md={6}>
          <Button className={"mt-50"} type="primary" block loading={requesting}
                  onClick={() => createQuizComponent(true)}
          >
            {t('courses.retry')}

          </Button>
        </Col>
      }

    </>
  )
}

CourseQuiz.displayName = 'CourseQuiz'

export default CourseQuiz
