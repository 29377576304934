import React from 'react'
import { useTranslation } from 'react-i18next'
import style from './styles.module.scss'
import { Button, Form, Input, Space, Typography } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
interface Props {
  onChangeRequested: (oldPassword: string, newPassword: string) => void
}

const PasswordPanel: React.FC<Props> = ({ onChangeRequested }) => {
  const { t } = useTranslation()

  const onFinish = (values: { oldPassword: string; newPassword: string }) => {
    onChangeRequested(values.oldPassword, values.newPassword)
  }

  return (
    <section className={style['password-profile']}>
      <div className="app-container">
        <Typography.Title className="panel-title" level={4}>
          {t('userProfile.employeePassword')}
        </Typography.Title>

        <Space direction="vertical">
          <Form className="password-panel-form" onFinish={onFinish}>
            <Form.Item
              name="oldPassword"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('login.errors.password'),
                },
              ]}
            >
              <Input.Password placeholder={t('userProfile.oldPassword')} />
            </Form.Item>
            <Form.Item
              name="newPassword"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('login.errors.password'),
                },
              ]}
            >
              <Input.Password placeholder={t('userProfile.newPassword')} />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('login.errors.confirmPassword'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error(t('login.errors.samePassword')))
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={t('userProfile.confirmPassword')}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" className=" light user-panel-button mt-30">
                {t('userProfile.doSave')}
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </div>
    </section>
  )
}

PasswordPanel.displayName = 'PasswordPanel'

export default PasswordPanel
