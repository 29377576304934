import React, {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import WebinarDetailContainer from 'containers/WebinarDetail'
import WebinarEditionList from 'containers/WebinarEditionList'
import {useLoadCurrentUser, useLoadWebinarByCode, useLoadWebinarFutureEditionList,} from '../../services/query'
import {useDispatch, useSelector} from 'react-redux'
import MeetingProviderWrapper from '../../containers/MeetingProviderWrapper'
import {Flex, Spinner} from 'amazon-chime-sdk-component-library-react'
import {Col, Row} from 'antd'
import * as courseAction from '../../store/Course/actions'
import {editionStatuses} from '../../constants'
import {currentEditionSelector} from '../../store/Course/selectors'
import {WebinarEdition} from '../../types/store'
import {headerSelector} from '../../store/App/selectors'
import {joinWebinar, launchWebinar} from "../../api/webinar";

const WebinarDetail: React.FC = () => {
  const dispatch = useDispatch()
  const header = useSelector(headerSelector)
  const [currentEdition, setCurrentEdition] = useState(useSelector(currentEditionSelector))
  const {code} = useParams()
  const {data: user} = useLoadCurrentUser()

  const [webinarConsoleShown, setWebinarConsoleShown] = useState(false)

  const {
    isFetched: isEditionsFetched,
    isLoading: isEditionsLoading,
    isError: isEditionsError,
    data: editions,
    dataUpdatedAt: editionsDataUpdatedAt,
  } = useLoadWebinarFutureEditionList(code)

  const {
    isFetched: isWebinarFetched,
    isLoading: isWebinarLoading,
    isError: isWebinarError,
    data: webinar,
  } = useLoadWebinarByCode(code)

  useEffect(() => {
    if (!isEditionsFetched || isEditionsLoading) {
      return
    }
    setCurrentEdition(editions[0])
    dispatch(courseAction.setCurrentEdition(editions[0]))
  }, [editionsDataUpdatedAt])

  const handleJoinMeeting = async () => {

    let meetingUrl

    if (currentEdition.status === editionStatuses.started)
      meetingUrl = await joinWebinar(code, currentEdition.code)
    else if (isWebinarTeacher && currentEdition.status === editionStatuses.notStarted)
      meetingUrl = await launchWebinar(code, currentEdition.code)
    else return


    // console.log('+++openingUrl', meetingUrl)
    window.open(meetingUrl, '_blank');
  }

  const onEditionSelect = useCallback((edition: WebinarEdition) => {
    setCurrentEdition(edition)
    dispatch(courseAction.setCurrentEdition(edition))
  }, [])

  if (isWebinarError || isEditionsError) return <h1>ERROR</h1>

  if (isWebinarLoading || isEditionsLoading || !isWebinarFetched || !isEditionsFetched)
    return (
      <Flex container layout="fill-space">
        <Spinner/>
      </Flex>
    )

  const isWebinarTeacher = user.id === webinar.teacher.id
  let isEnrolled = false
  let canStart = false

  if (currentEdition) {
    isEnrolled = !!user.editions.find((edition) => edition.code === currentEdition.code)
    canStart = currentEdition.date.valueOf() < Date.now()
  }

  return (
    <>
      {currentEdition &&
        ((isWebinarTeacher && canStart && currentEdition.status !== editionStatuses.ended) ||
          (isEnrolled && currentEdition.status === editionStatuses.started)) &&
        webinarConsoleShown && <MeetingProviderWrapper/>}
      {header && (
        <>
          <section className="app-container">
            <Row>
              <Col span={16}>
                <WebinarDetailContainer code={code}/>
              </Col>
              <Col span={8}>
                <WebinarEditionList
                  code={code}
                  onEditionSelect={onEditionSelect}
                  onWebinarShow={() =>  handleJoinMeeting()}
                />
              </Col>
            </Row>
          </section>
        </>
      )}
    </>
  )
}

export default WebinarDetail
