import React from 'react'
import { useLoadWebinarByCode } from 'services/query'
import { Course } from 'types/store'
import WebinarDetail from 'components/WebinarDetail'

interface Props {
  code: Course['code']
}

const WebinarDetailContainer: React.FC<Props> = ({ code }) => {
  const { isFetched, isLoading, isError, data: webinar } = useLoadWebinarByCode(code)
  if (isError) {
    return null
  }

  if (!isFetched || isLoading) {
    return null
  }

  return <WebinarDetail webinar={webinar} />
}

WebinarDetailContainer.displayName = 'WebinarDetailContainer'

export default WebinarDetailContainer
