import Debug from 'debug'

import { getApplicationError } from 'core'
import { CourseDetailData, CourseListData, EditionListData } from 'types/api'
import { CourseStatus, ErrorCode } from 'types/enums'
import { Webinar, WebinarDetail, WebinarEdition } from 'types/store'
import { resolveUrl } from 'core/helpers'
import { mapDate, mapNumber, mapString } from './common'

const debug = Debug('services:map:course')

export const mapWebinarCode = (data: number): Webinar['code'] => `${data}`
export const mapFromWebinarCode = (code: Webinar['code']): number => Number.parseInt(code)

export const mapWebinarEditionCode = (data: number): WebinarEdition['code'] => `${data}`
export const mapFromEditionCode = (code: WebinarEdition['code']): number => Number.parseInt(code)


export const mapWebinarEdition = (data: EditionListData): WebinarEdition => {
  try {
    return {
      code: mapWebinarEditionCode(data.id),
      courseCode: mapWebinarEditionCode(data.courseId),
      date: mapDate(data.timestamp),
      videoUrl: resolveUrl(data.videoLink),
      seats: mapNumber(data.seats),
      freeSeats: mapNumber(data?.freeSeats),
      status: data.status
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapWebinarEditionList = (data: EditionListData[]): WebinarEdition[] => {
  try {
    return data.map(mapWebinarEdition)
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapWebinarFutureEditionList = (data: EditionListData[]): WebinarEdition[] => {
  try {

    const refMinTs = new Date().setDate(new Date().getDate() - 1)

    return data
      .sort((a, b) => Date.parse(a.timestamp) - Date.parse(b.timestamp))
      .filter((value) => {
        return Date.parse(value.timestamp) > refMinTs
      })
      .map(mapWebinarEdition)
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapWebinar = (data: CourseListData): Webinar => {
  try {
    return {
      code: mapWebinarCode(data.id),
      title: mapString(data.title),
      teacher: data.teacher,
      nameOfTheTeacher: data.nameOfTheTeacher,
      thumbnailImg: resolveUrl(data.thumbnailUrl),
      status: CourseStatus.NotStarted,
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapWebinarList = (data: CourseListData[]): Webinar[] => {
  try {
    return data.map(mapWebinar)
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapWebinarDetail = (data: CourseDetailData): WebinarDetail => {
  try {
    return {
      code: mapWebinarCode(data.id),
      title: mapString(data.title),
      teacher: data.teacher,
      nameOfTheTeacher: data.nameOfTheTeacher,
      thumbnailImg: resolveUrl(data.thumbnailUrl),
      status: CourseStatus.NotStarted,
      descriptionTitle: mapString(data.description1),
      description: mapString(data.description2),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}
