import React, { useCallback } from 'react'
import CategoryMosaic from 'components/CategoryMosaic'
import { useLoadCategoryList } from 'services/query/user'
import { useNavigate } from 'react-router'
import routes from 'config/routes'
import { filterToQuery } from 'services/navigation/course'
import { Category } from 'types/store'

const CategoryMosaicContainer: React.FC = () => {
  const navigate = useNavigate()
  const { data } = useLoadCategoryList()
  const onCategoryClick = useCallback((category: Category) => {
    navigate({
      pathname: routes.coursesOverall.url,
      search: filterToQuery({ category: category.code }),
    })
  }, [])

  return <CategoryMosaic categories={data} onCategoryClick={onCategoryClick} />
}

CategoryMosaicContainer.displayName = 'CategoryMosaicContainer'

export default CategoryMosaicContainer
