import { Row, Typography } from 'antd'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { filterFromQuery } from 'services/navigation/course'
import { useLoadCategoryList } from 'services/query/user'
import { EventType } from 'types/enums'

const CourseHeaderContainer: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const filter = useMemo(() => filterFromQuery(search), [search])
  const { data: categoryList } = useLoadCategoryList()

  let title
  switch (filter.type) {
    case EventType.Webinar:
      title = t('courses.titleWebinar')
      break
    case EventType.Course:
    default:
      if (filter.category) {
        const category = categoryList.find(({ code }) => code === filter.category)
        title = t('courses.titleByCategory', { p0: category?.name })
      } else {
        title = t('courses.titleOverall')
      }
      break
  }
  return (
    <Row className="pt-30 pb-80" justify="center">
      <Typography.Title level={2}>{title}</Typography.Title>
    </Row>
  )
}

CourseHeaderContainer.displayName = 'CourseHeaderContainer'

export default CourseHeaderContainer
