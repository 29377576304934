import { Typography } from 'antd'
import React from 'react'
import style from './styles.module.scss'

interface Props {
  text: string
}

const NoContent: React.FC<Props> = ({ text }) => {
  return (
    <section className={style['no-content']}>
      <Typography.Title level={2}>{text}</Typography.Title>
    </section>
  )
}

NoContent.displayName = 'NoContent'

export default NoContent
