import { config } from 'core'
import {
  CourseDetailData,
  CourseListData,
  CourseSearchData,
  EditionListData,
  GetAllCoursesParams,
  UserListData,
} from 'types/api'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import { WebinarEdition } from '../types/store'
import {MeetingManager} from "amazon-chime-sdk-component-library-react";
import {NavigateFunction} from "react-router-dom";
import * as appActions from "../store/App/actions";
import {useDispatch} from "react-redux";

const axios = getAxios()

export async function getWebinarList(
  params: Omit<GetAllCoursesParams, 'courseType'> = {}
): Promise<CourseSearchData> {
  try {
    const { headers } = await getAuthenticationHeader()
    const response = await axios.get(config.api.getWebinars(), {
      headers,
      params: { ...params, courseType: 'Webinar' } as GetAllCoursesParams,
    })
    return response.data as CourseSearchData
  } catch (error) {
    throw getApiError(error)
  }
}

export async function getWebinarDetailById(id: CourseDetailData['id']): Promise<CourseDetailData> {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.get(config.api.getWebinarById(id), headers)
    return response.data as CourseDetailData
  } catch (error) {
    throw getApiError(error)
  }
}

export async function getWebinarEditionList(webinarId: CourseListData['id']) {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.get(config.api.getEditions(webinarId), headers)
    return response.data as EditionListData[]
  } catch (error) {
    throw getApiError(error)
  }
}

export async function getReservedUsersByCourseIdAndEditionId(
  webinarId: CourseListData['id'],
  editionId: EditionListData['id']
) {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.get(
      config.api.getReservedUsersByCourseIdAndEditionId(webinarId, editionId),
      headers
    )
    return response.data as UserListData[]
  } catch (error) {
    throw getApiError(error)
  }
}

export async function launchWebinar(
  webinarId: WebinarEdition['code'],
  editionId: WebinarEdition['code']
) {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.get(config.api.launchEditionChime(+webinarId, +editionId), headers)
    return response.data as any
  } catch (error) {
    throw getApiError(error)
  }
}

export async function joinWebinar(
  webinarId: WebinarEdition['code'],
  editionId: WebinarEdition['code']
) {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.get(config.api.joinEditionChime(+webinarId, +editionId), headers)
    return response.data as any
  } catch (error) {
    throw getApiError(error)
  }
}

export async function subscribeWebinar(
  webinarId: WebinarEdition['code'],
  editionId: WebinarEdition['code'],
  userId: UserListData['id']
) {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.put(
      config.api.putSubscribeWebinarByWebinarIdAndEditionIdAndUserId(
        +webinarId,
        +editionId,
        +userId
      ),
      null,
      headers
    )
    return response.data as any
  } catch (error) {
    throw getApiError(error)
  }
}

export async function endWebinar(
  webinarId: WebinarEdition['code'],
  editionId: WebinarEdition['code']
) {
  try {
    const headers = await getAuthenticationHeader()
    await axios.get(config.api.endEditionChime(+webinarId, +editionId), headers)
  } catch (error) {
    throw getApiError(error)
  }
}

export async function leaveMeeting(meetingManager: MeetingManager, history: NavigateFunction) {
  await meetingManager.meetingSession.audioVideo.chooseVideoInputDevice(null)
  await meetingManager.meetingSession.audioVideo.chooseAudioOutputDevice(null)
  meetingManager.meetingSession.audioVideo.stopLocalVideoTile()
  meetingManager.meetingSession.audioVideo.stop()

  history('../../')
}

export async function endMeetingForAll(courseCode: string, editionCode: string, meetingManager: MeetingManager, history: NavigateFunction) {
  try {
    await endWebinar(courseCode, editionCode)
    await leaveMeeting(meetingManager, history)
    history('../../')
  } catch (e) {
    console.log('Could not end meeting', e)
  }
}
