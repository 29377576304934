import {Col, Row} from 'antd'
import React from 'react'
import CourseCard from 'components/CourseCard'
import {Course} from 'types/store'
import style from './styles.module.scss'

interface Props {
  courses: Array<Course>
}

const CourseProgressionGrid: React.FC<Props> = ({courses}) => {

  return (
    <Row className={style['course-progression-grid']}>
      {courses?.map((course) => (
        <Col className="container-card" key={course.code} xs={24} md={12} lg={6}>
          <CourseCard course={course}/>
        </Col>
      ))}
    </Row>
  )
}

CourseProgressionGrid.displayName = 'CourseProgressionGrid'

export default CourseProgressionGrid
