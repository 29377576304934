import React, { useEffect, useState } from 'react'
import { getAuthToken } from 'core/localStore'
import { Navigate } from 'react-router'
import routes from 'config/routes'

const AuthProvider: React.FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(null)

  useEffect(() => {
    const getData = async () => {
      const token = await getAuthToken()
      const authenticated = token != null

      setIsAuthenticated(authenticated)
    }
    getData()
  }, [])

  if (isAuthenticated === null) {
    return null
  }

  if (isAuthenticated === false) {
    return <Navigate to={routes.login.url} />
  }

  return <>{children}</>
}

export default AuthProvider
