import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as queryKeys from 'config/queryKeys'

import { getCompanies, getCompany, addCompany, editCompany, deleteCompany } from 'api/company'
import { CompanyCreateData } from 'types/api'

import { mapCompany, mapCompanyCreateFromCompany } from 'services/map/company'
import { companyDefault } from 'services/map/common'
import { Company } from 'types/store'

export function useLoadCompanies(query?: { search: string | null }) {
  const search = query?.search
  const queryFunction = async () => {
    const companies = await getCompanies({ search })
    return companies.map((user) => mapCompany(user))
  }
  return useQuery([...queryKeys.companyList(), search], queryFunction, {
    placeholderData: [],
    cacheTime: search ? 0 : Infinity,
    staleTime: Infinity,
  })
}

export function useLoadCompanyById(companyId: number) {
  const queryFunction = async () => {
    const data = await getCompany(companyId)
    const entity = mapCompany(data)
    return entity
  }


  return useQuery(queryKeys.companyById(companyId), queryFunction, {
    placeholderData: companyDefault,
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled: companyId != null,
  })
}

export function useAddCompany() {
  const mutateFunction = async (company: Omit<Company, 'id'>) => {
    const data: CompanyCreateData = mapCompanyCreateFromCompany(company)
    const result = await addCompany(data)
    return result.id as Company['id']
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: () => {
      return queryClient.invalidateQueries(queryKeys.companyList())
    },
  })
  return mutateAsync
}

export function useEditCompany(id: number) {
  const mutateFunction = async (company: Omit<Company, 'id'>) => {
    const data: CompanyCreateData = mapCompanyCreateFromCompany(company)
    const result = await editCompany(id, data)
    return result
  }
  const queryClient = useQueryClient()
  const { mutate } = useMutation(mutateFunction, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.companyById(id)),
        queryClient.invalidateQueries(queryKeys.companyList()),
      ])
    },
  })
  return mutate
}

export function useDeleteCompany() {
  const deleteFunction = async (id: number) => {
    const result = await deleteCompany(id)
    return result
  }
  const queryClient = useQueryClient()
  const { mutate } = useMutation(deleteFunction, {
    onSuccess: () => {
      return queryClient.invalidateQueries(queryKeys.companyList())
    },
  })
  return mutate
}
