import React from 'react'
import { useTranslation } from 'react-i18next'
import { CourseStatus } from 'types/enums'
import { Webinar } from 'types/store'
import { Typography, Card } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import style from './styles.module.scss'
import { generatePath, Link } from 'react-router-dom'
import routes from 'config/routes'

interface Props {
  item: Webinar
}

const WebinarCard: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation()

  return (
    <section className={style['webinar-card']}>
      <Link to={generatePath(routes.webinarDetail.url, { code: item.code })}>
        <Card
          bordered={false}
          key={item.code}
          cover={<img alt={item.title} src={item.thumbnailImg} />}
        >
          <div className="container-title">
            <Typography.Paragraph className=" carousel-title" ellipsis={{ rows: 2 }}>
              {item.title}
            </Typography.Paragraph>
          </div>

          <Typography.Paragraph className="not-bold">{item.teacher.name} {item.teacher.surname}</Typography.Paragraph>
          {item.status === CourseStatus.Closed && (
            <Typography.Title className="closed" level={3}>
              {t('courses.subClosed')}
            </Typography.Title>
          )}
          {item.status === CourseStatus.NotStarted && <PlusCircleOutlined className="plus" />}
        </Card>
      </Link>
    </section>
  )
}

WebinarCard.displayName = 'WebinarCard'

export default WebinarCard
