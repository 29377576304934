import React, { useEffect, useState } from 'react'

import AgreementPage from '../../pages/AgreementPage/AgreementPage'
import { useLoadCurrentUser } from '../../services/query'
import { useDispatch } from 'react-redux'
import { setUserAgreement } from '../../store/App/actions'

const AgreementProvider: React.FC = ({ children }) => {
  const [isAgreed, setIsAgreed] = useState<boolean>(null)
  const dispatch = useDispatch()
  const { data: user } = useLoadCurrentUser()
  const { userAgreement } = user

  useEffect(() => {
    dispatch(setUserAgreement(userAgreement))
    setIsAgreed(!!(userAgreement && userAgreement.userPolicy))
  }, [userAgreement])

  if (isAgreed === null || user.id == null) {
    return null
  }

  if (isAgreed === false) {
    return (
      <>
        <AgreementPage
          showOnlyWebinarRecordingPolicy={false}
          isVisible={true}
          setIsAgreed={setIsAgreed}
        />
      </>
    )
  }

  return <>{children}</>
}

export default AgreementProvider
