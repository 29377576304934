import routes from 'config/routes'
import {matchPath} from 'react-router-dom'
import {config} from './config'

declare let document

function getQueryString(pars: Array<string>): string {
  let q = ''
  let value: string

  Object.keys(pars).map((key) => {
    value = pars[key]
    if (!(value === null)) {
      q += `&${key}=${encodeURIComponent(value)}`
    }
    return null
  })

  if (q.length > 0) {
    //remove first
    q = q.substr(1)
  }

  return q
}

function splitQueryString(q: string) {
  const pars = q.split('&')
  const qq = {}

  for (let i = 0; i < pars.length; i += 1) {
    const ret = pars[i].toString().split('=')
    qq[ret[0]] = decodeURIComponent(ret[1])
  }
  return qq
}

export function getQueryParameters(): QueryParameters {
  const { search } = document.location
  if (!search) {
    //no search pars
    return {}
  }

  //normalize to lowercase
  const q = search.toLowerCase().substr(1) as string
  return splitQueryString(q) as QueryParameters
}

export function getHashParameters() {
  const { hash } = document.location
  if (!hash) {
    //no hash pars
    return {}
  }

  //normalize to lowercase
  const q = hash.toLowerCase().substr(1)
  return splitQueryString(q)
}

export function setHashParameters(pars) {
  document.location.hash = `#${getQueryString(pars)}`
}

export function removeNull(obj) {
  Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {})
  return obj
}

export const getCurrentRoute = (locationPath: string): Route => {
  const currentRouteKey = Object.keys(routes).find((route) => {
    const match = matchPath(routes[route].url, locationPath)
    return match != null
  })
  return routes[currentRouteKey]
}

export const resolveUrl = (location: string) => {
  if (!location) {
    return null
  }
  return `${new URL(location, config.appBaseUrl)}`
}
