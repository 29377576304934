import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import classNames from 'classnames'
import { WebinarEdition } from 'types/store'
import { Calendar, Col, List, Row, Typography } from 'antd'
import style from './styles.module.scss'
import IconFont from 'core/IconFont'
import { useSelector } from 'react-redux'
import { currentEditionSelector } from '../../store/Course/selectors'
import { editionStatuses } from '../../constants'

interface Props {
  editions: WebinarEdition[]
  onEditionSelect: (edition: WebinarEdition) => void
  freeSeats: number
}

const WebinarEditions: React.FC<Props> = ({ editions, onEditionSelect, freeSeats }) => {
  const currentEdition = useSelector(currentEditionSelector)

  const { t } = useTranslation()
  let date

  if (currentEdition && currentEdition.date) {
    date = moment(currentEdition.date)
  }

  return (
    <section className={style['webinar-edition']}>
      {currentEdition && (
        <Col>
          <List
            header={
              <Typography.Paragraph className="free-seats">
                {t('courses.availableDates')}
              </Typography.Paragraph>
            }
          >
            {editions
              .sort((a, b) => a.date.getTime() - b.date.getTime())
              .map((edition) => (
                <List.Item key={edition.code} className="free-seats-container">
                  <IconFont type="icon-calendar-alt" />
                  <Typography.Paragraph
                    className={classNames('free-seats', 'cal', {
                      select: edition.code === currentEdition.code,
                    })}
                    onClick={() => onEditionSelect(edition)}
                  >
                    {moment(edition.date).format('dddd D MMMM yyyy HH:mm')}
                    {edition.code !== currentEdition.code ? (
                      <IconFont type="icon-arrow-right-circle" />
                    ) : (
                      ''
                    )}
                  </Typography.Paragraph>
                </List.Item>
              ))}
            <List.Item className="free-seats-container">
              <img src="/images/icons/free-seats.svg" alt="icon" />
              <Typography.Paragraph className="free-seats">
                {currentEdition.status === editionStatuses.notStarted &&
                  `${t('webinar.seats').toUpperCase()}: ${freeSeats} / ${currentEdition.seats} - ${t(
                    'webinar.openSub'
                  ).toUpperCase()}`}
                {currentEdition.status === editionStatuses.started &&
                  `${t('webinar.started').toUpperCase()}`}
                {currentEdition.status === editionStatuses.ended &&
                  `${t('webinar.ended').toUpperCase()}`}
              </Typography.Paragraph>
            </List.Item>
          </List>

          <Calendar
            value={date}
            fullscreen={false}
            headerRender={() => {
              return (
                <Row className="row-date">
                  <Col className="col-date">
                    <Typography.Paragraph className="date">
                      {moment(currentEdition.date).format('MMMM yyyy')}
                    </Typography.Paragraph>
                  </Col>
                </Row>
              )
            }}
          />
        </Col>
      )}
      {editions.length == 0 && (




        <Col>
          <List
            header={
              <Typography.Paragraph>
                {t('courses.availableDates')}
              </Typography.Paragraph>
            }
          >
            <List.Item key={55555555} className="free-seats-container-no-capitalize">
              <IconFont type="icon-calendar-alt" />
              <Typography.Paragraph
                className={classNames('free-seats', 'cal')}>

                {t('courses.noAvailableDates')}


              </Typography.Paragraph>
            </List.Item>
          </List>

        </Col>






      )}
    </section>
  )
}
WebinarEditions.displayName = 'WebinarEditions'

export default WebinarEditions
