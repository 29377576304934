import { createSelector } from 'reselect'
import { STATE_KEY } from './constants'
import { initialState } from './reducer'
import { CourseState } from './types'

export function baseSelector(state): CourseState {
  return state.root[STATE_KEY] || initialState
}

export const currentLessonSelector = createSelector(baseSelector, (state) => state.currentLesson)
export const  currentEditionSelector = createSelector(baseSelector, (state) => state.currentEdition)
export const currentWebinarSelector = createSelector(baseSelector, (state) => state.currentWebinar)
