import { Col, Row } from 'antd'
import React from 'react'
import { CourseCardSkeleton } from 'components/CourseCard'
import style from './styles.module.scss'

const CourseProgressionSkeleton: React.FC = () => {
  return (
    <Row gutter={[30, 30]} className={style['course-progression-grid']}>
      {Array(4)
        .fill(null)
        .map((value, index) => (
          <Col key={index} xs={24} md={12} lg={6} className="course-card-container">
            <CourseCardSkeleton />
          </Col>
        ))}
    </Row>
  )
}

CourseProgressionSkeleton.displayName = 'CourseProgressionSkeleton'

export default CourseProgressionSkeleton
