import { config } from 'core'
import { ConfigurationData } from 'types/api'
import { getAxios } from './apiService'
import { getApiError } from './common'

const axios = getAxios()

export async function getSettings(): Promise<ConfigurationData> {
  try {
    const response = await axios.get(config.api.getSettings())
    return response.data as ConfigurationData
  } catch (error) {
    throw getApiError(error)
  }
}
