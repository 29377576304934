import { createSelector } from 'reselect'
import { STATE_KEY } from './constants'
import { initialState } from './reducer'
import { AppState } from './types'

export function baseSelector(state): AppState {
  return state.root[STATE_KEY] || initialState
}

export const applicationErrorSelector = createSelector(baseSelector, (state) => state.error)
export const bootstrapLoadingSelector = createSelector(
  baseSelector,
  (state) => state.bootstrapLoading
)
export const userSelector = createSelector(baseSelector, (state) => state.user)
export const errorSelector = createSelector(baseSelector, (state) => state.error)
export const headerSelector = createSelector(baseSelector, (state) => state.header)
export const agreementSelector = createSelector(baseSelector, (state) => state.userAgreement)
