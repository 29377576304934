import {Col, Row} from 'antd'
import React from 'react'
import {useParams} from 'react-router-dom'
import style from './styles.module.scss'
import classNames from 'classnames'
import LearningPathQuizContainer from "../../containers/LearningPathQuiz";

const LearningPathQuiz: React.FC = () => {
  const {code} = useParams()

  return (
    <section className={classNames('app-container', style['course-quiz'])}>
      <Row className="m-30 py-30 pb-50">
        <Col md={16} xs={24}>
          <Row>
            <LearningPathQuizContainer code={code}/>
          </Row>
        </Col>
      </Row>
    </section>
  )
}

export default LearningPathQuiz
