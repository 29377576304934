import { config } from 'core'

import {
  CompanyCreateData,
  CompanyListData,
  CompanyPatchData,
  ContractCreateData,
  ContractListData,
} from 'types/api'

import { AxiosError, AxiosResponse } from 'axios'
import { getAxios } from './apiService'
import {getApiError, getAuthenticationHeader} from './common'

const axios = getAxios()

export async function getCompanies(query?: { search: string | null }): Promise<CompanyListData[]> {
  try {
    const search = query?.search
    const response = await axios.get(config.api.getCompanies(), { params: { search } })
    return response.data as CompanyListData[]
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getCompany(id: number): Promise<CompanyListData> {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.get(config.api.getCompanyById(id), headers)
    return response.data as CompanyListData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function addCompany(model: CompanyCreateData): Promise<CompanyListData> {
  try {
    const response = await axios.post(config.api.getCompanies(), model)
    return response.data as CompanyListData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editCompany(id: number, model: CompanyPatchData): Promise<CompanyListData> {
  try {
    const response = await axios.patch(config.api.getCompanyById(id), model)
    return response.data as CompanyListData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteCompany(id: number): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.getCompanyById(id))
    return response.data as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
