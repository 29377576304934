import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Checkbox, Row, Modal, Col, List, Divider } from 'antd'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useSettingValue } from '../../services/query'
import { t } from '../../core'
import { editUserAgreementData } from '../../api/user'
import { UserAgreementData } from '../../types/api'
import { useDispatch } from 'react-redux'
import { setUserAgreement } from '../../store/App/actions'
import style from './styles.module.scss'

interface Props {
  showOnlyWebinarRecordingPolicy?: boolean
  isVisible?: boolean
  setIsAgreed?: Dispatch<SetStateAction<boolean>>
}

const AgreementPage: React.FC<Props> = ({
  showOnlyWebinarRecordingPolicy,
  isVisible,
  setIsAgreed,
  children,
}) => {
  const [visible, setVisible] = useState(isVisible)
  const [checked, setChecked] = useState<CheckboxValueType[]>([])
  const [disabled, setDisabled] = useState(true)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setVisible(isVisible)
  }, [isVisible])

  useEffect(() => {
    checkOkButton(checked)
  }, [checked])

  const handleOk = async () => {
    setConfirmLoading(true)

    const data: UserAgreementData = {
      termsAndConditions: showOnlyWebinarRecordingPolicy ? true : checked.includes(1),
      userPolicy: showOnlyWebinarRecordingPolicy ? true : checked.includes(2),
      visitorPolicyA: showOnlyWebinarRecordingPolicy ? true : checked.includes(3),
      visitorPolicyB: showOnlyWebinarRecordingPolicy ? true : checked.includes(4),
      webinarRecording: checked.includes(5),
    }

    const response = await editUserAgreementData(data)
    dispatch(setUserAgreement(response))
    setConfirmLoading(false)
    setVisible(false)
    if (!showOnlyWebinarRecordingPolicy) {
      setIsAgreed(true)
      return <>{children}</>
    }
  }

  const checkOkButton = (checked: CheckboxValueType[]) => {
    if (showOnlyWebinarRecordingPolicy) {
      manageWebinarCheckAndValidation()
    } else {
      if (checked.includes(1) && checked.includes(2) && checked.includes(3)) {
        setDisabled(false)
      } else setDisabled(true)
    }
  }

  const manageWebinarCheckAndValidation = () => {
    if (checked.includes(5)) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setChecked(checkedValues)
  }

  return (
    <>
      <Modal
        title={t('agreement.agreement')}
        confirmLoading={confirmLoading}
        maskClosable={false}
        visible={visible}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            disabled={disabled}
            loading={confirmLoading}
          >
            Ok
          </Button>,
        ]}
      >
        <Checkbox.Group onChange={onChange}>
          <Col>
            {!showOnlyWebinarRecordingPolicy && (
              <>
                <p> {t('agreement.platformPolicyMessage')}</p>
                <Col>
                  <List>
                    <List.Item>
                      <Col>
                        <Row>
                          <a
                            href={useSettingValue('link.termsAndConditions')}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <strong>- {t('agreement.termsAndConditions')}</strong>
                          </a>
                        </Row>
                        <Row>
                          <Checkbox value={1} className={style['checkbox']}>
                            {t('agreement.readAndAccept')}
                          </Checkbox>
                        </Row>
                      </Col>
                    </List.Item>

                    <List.Item>
                      <Col>
                        <Row>- {t('agreement.approveTermsAndConditionsMessage')}</Row>
                        <Row>
                          <Checkbox value={2} className={style['checkbox']}>
                            {t('agreement.readAndAccept')}
                          </Checkbox>
                        </Row>
                      </Col>
                    </List.Item>

                    <List.Item>
                      <Col>
                        <Row>
                            <a
                              href={useSettingValue('link.userPolicyA')}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <strong>- {t('agreement.userPolicyTitle')}</strong>
                            </a>
                          {t('agreement.approveUserPolicyA')}
                        </Row>
                        <Row>
                          <Checkbox value={3} className={style['checkbox']}>
                            {t('agreement.readAndAccept')}
                          </Checkbox>
                        </Row>
                      </Col>
                    </List.Item>
                  </List>
                </Col>

                <Divider />

                <p> {t('agreement.optionals')}</p>
                <Col>
                  <List>
                    <List.Item>
                      <Col>
                        <Row>
                          <strong>- {t('agreement.userPolicyTitle')}</strong>
                          {t('agreement.approveUserPolicyB')}
                        </Row>
                        <Row>
                          <Checkbox value={4} className={style['checkbox']}>
                            {t('agreement.readAndAccept')}
                            <a
                              href={useSettingValue('link.termsAndConditions')}
                              target="_blank"
                              rel="noreferrer"
                            ></a>
                          </Checkbox>
                        </Row>
                      </Col>
                    </List.Item>
                  </List>
                </Col>
              </>
            )}

            <Col>
              <List>
                <List.Item>
                  <Col>
                    <Row>
                      <strong>- {t('agreement.approveWebinarRecording')}</strong>
                    </Row>
                    <Row>
                      <Checkbox value={5} className={style['checkbox']}>
                        {t('agreement.readAndAccept')}
                        <a href={useSettingValue('link.termsAndConditions')}
                          target="_blank"
                          rel="noreferrer"
                        />
                      </Checkbox>
                    </Row>
                  </Col>
                </List.Item>
              </List>
            </Col>
          </Col>
        </Checkbox.Group>
      </Modal>
    </>
  )
}

export default AgreementPage
