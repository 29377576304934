import React from 'react'
import { useTranslation } from 'react-i18next'

import CourseProgressionGrid from 'components/CourseProgressionGrid'
import CourseList from 'components/CourseList'
import NoContent from 'components/NoContent'
import {
  useLoadCurrentUser,
  useLoadEnrolledCourses,
  useLoadWebinarEditionList,
} from 'services/query'
import { EventType } from '../../types/enums'
import { Constants } from '../../utils/constants'

interface Props {
  flatMode: boolean
}

const DashboardWebinarContainer: React.FC<Props> = ({ flatMode }) => {
  const { t } = useTranslation()
  const { isFetched, isLoading, isError, data: courses } = useLoadEnrolledCourses()
  const { data: user } = useLoadCurrentUser()

  if (isError) {
    return null
  }

  if (!isFetched || isLoading) {
    return null
  }

  if (courses.length <= 0) {
    return <NoContent text={t('dashboard.noCourses')} />
  }

  const existFutureEdition = (courseId) => {
    const { isFetched, isLoading, isError, data: editions } = useLoadWebinarEditionList(courseId)

    if (isError) {
      return null
    }

    if (!isFetched || isLoading) {
      return null
    }

    return editions.some((e) => e.date > new Date())
  }

  const webinars = courses.filter(
    (course) =>
      course.courseType === 'Webinar' &&
      (course.progressPercentage >= Constants.editionProgressThreshold ||
        existFutureEdition(course.code))
  )

  return flatMode ? (
    <>
      {webinars.map((course, index) => (
        <CourseList key={`${course.code}_${index}`} course={course} />
      ))}
    </>
  ) : (
    <CourseProgressionGrid courses={webinars} />
  )
}

DashboardWebinarContainer.displayName = 'DashboardWebinarContainer'

export default DashboardWebinarContainer
