import React, {useCallback, useState} from 'react'

import LearningPathStrip, {LearningPathStripSkeleton} from 'components/LearningPathStrip'
import {useLoadLearningPathList} from 'services/query/course'
import LearningPathListTitle, {LearningPathListTitleSkeleton,} from 'components/LearningPathListTitle'
import {Course} from 'types/store'
import CourseList from 'components/CourseList'

const LearningPathListContainer: React.FC = () => {
  const [flatMode, setFlatMode] = useState(false)
  const learningPathsQuery = useLoadLearningPathList()
  const {isFetched, isLoading, isError, data: learningPathList} = learningPathsQuery

  const onFlatModeToggle = useCallback(() => setFlatMode((mode) => !mode), [])

  if (isError) {
    return null
  }

  if (!isFetched || isLoading) {
    return (
      <>
        <LearningPathListTitleSkeleton/>
        <LearningPathStripSkeleton/>
      </>
    )
  }

  if (learningPathList.length <= 0) {
    return null
  }
  return (
    <>
      <LearningPathListTitle flatMode={flatMode} onFlatModeToggle={onFlatModeToggle}/>
      {flatMode
        ? learningPathList
          .reduce((acc, learningPath) => acc.concat(learningPath.courses), [] as Course[])
          .map((course, index) => <CourseList key={`${course.code}_${index}`} course={course}/>)
        : // ? learningPathList.map(({ courses, code }) => (
          //     <LearningPathListItem key={code} courses={courses} />
          //   ))
        learningPathList.map(({
                                code,
                                title,
                                dueDate,
                                allCoursesCompleted,
                                successfulAttempt,
                                hasQuiz,
                                isQuizExpired,
                                courses
                              }) => (
          <LearningPathStrip key={code} title={title} dueDate={dueDate} courses={courses} code={code}
                             successfulAttempt={successfulAttempt}
                             allCoursesCompleted={allCoursesCompleted} isQuizExpired={isQuizExpired} hasQuiz={hasQuiz}/>
        ))}
    </>
  )
}

LearningPathListContainer.displayName = 'LearningPathListContainer'

export default LearningPathListContainer
