import React from 'react'

import { Layout, Row } from 'antd'

const WaitingPage: React.FC = () => {
  return (
    <Layout>
      <section>
        <Row justify="center"></Row>
        <Row justify="center" className="align-center"></Row>
      </section>
    </Layout>
  )
}

export default WaitingPage
