import Axios, { AxiosInstance } from 'axios'

const axios: AxiosInstance = Axios.create()

// Axios Interceptors
// Request interceptor for API calls
// axios.interceptors.request.use((request) => {
//   debug('Axios Request', request)
//   return request
// })
// // Response interceptor for API calls
// axios.interceptors.response.use(onFullfilled, onRejected)

export const getAxios = () => {
  return axios
}
