import { Col, Row, Skeleton } from 'antd'
import React from 'react'

import { CourseCardSkeleton } from 'components/CourseCard'
import style from './styles.module.scss'

const LearningPathStripSkeleton: React.FC = () => {
  return (
    <section className={style['learning-path-strip']}>
      <Col md={13}>
        <Skeleton className="pageHeader-skeleton" active paragraph={false} />
      </Col>
      <Row gutter={[30, 30]}>
        {Array(4)
          .fill(null)
          .map((value, index) => (
            <Col key={index} xs={24} md={12} lg={6} className="course-card-container">
              <CourseCardSkeleton />
            </Col>
          ))}
      </Row>
    </section>
  )
}

LearningPathStripSkeleton.displayName = 'LearningPathStripSkeleton'

export default LearningPathStripSkeleton
