import { Lesson, Webinar, WebinarEdition } from 'types/store'
import * as K from './constants'
import { CourseActionTypes } from './types'

export function setCurrentLesson(lesson: Lesson): CourseActionTypes {
  return {
    type: K.SET_CURRENT_LESSON,
    payload: lesson,
  }
}

export function setCurrentEdition(edition: WebinarEdition): CourseActionTypes {
  return {
    type: K.SET_CURRENT_EDITION,
    payload: edition,
  }
}

export function setCurrentWebinar(webinar: Webinar): CourseActionTypes {
  return {
    type: K.SET_CURRENT_WEBINAR,
    payload: webinar,
  }
}
