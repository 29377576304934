import React from 'react'
import { useTranslation } from 'react-i18next'
import style from './styles.module.scss'
import { Row, Col } from 'antd'
import { useSettingValue } from 'services/query/setting'

const Footer: React.FC = ({}) => {
  const { t } = useTranslation()

  return (
    <section className={style['footer']}>
      <Row>
        <Col>
          <img className="logo-brand" src="/images/logo.png" alt="brand" />
        </Col>
      </Row>
      <Row className="link-top">
        <Col>
          <a target="_blank" href={useSettingValue('link.help')} rel="noreferrer">
            {t('footer.assistance')}
          </a>
          <a target="_blank" href={useSettingValue('link.contactInfo')} rel="noreferrer">
            {t('footer.contactUs')}
          </a>
          <a target="_blank" href={useSettingValue('link.whoWeAre')} rel="noreferrer">
            {t('footer.whoWeAre')}
          </a>
        </Col>
      </Row>
      <Row className="link-bottom">
        <Col>
          <a style={{ cursor: 'default' }} href="#">
            {t('footer.copyright')}
          </a>
        </Col>
        <Col>
          <a target="_blank" href={useSettingValue('link.termsAndConditions')} rel="noreferrer">
            {t('footer.conditions')}
          </a>
        </Col>
        <span className="trat">-</span>
        <Col>
          <a target="_blank" href={useSettingValue('link.userPolicyA')} rel="noreferrer">
            {t('footer.goToPrivacy')}
          </a>
        </Col>
        <span className="trat">-</span>
        <Col>
          <a target="_blank" href={useSettingValue('link.cookieInfo')} rel="noreferrer">
            {t('footer.goToCookie')}
          </a>
        </Col>
      </Row>
    </section>
  )
}
Footer.displayName = 'Footer'

export default Footer
