import React from 'react'
import style from './styles.module.scss'

interface Props {
  src: string
}

const ThumbnailCourse: React.FC<Props> = ({ src }) => {
  return (
    <section className={style['thumbnail-course']}>
      <img className="thumb" src={src} alt="thumbnail" />
    </section>
  )
}

ThumbnailCourse.displayName = 'ThumbnailCourse'

export default ThumbnailCourse
