import { initializeApp } from 'firebase/app'
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import App from './containers/App'
import { Provider } from 'react-redux'
import configureStore from 'store'

import './index.less'
import 'scss/index.scss'
import reportWebVitals from './reportWebVitals'
import { config, initLanguage } from 'core'
import { isAlive } from 'api/ping'
import ServerNotAvailablePage from 'pages/ServerNotAvailablePage'
import { BrowserRouter } from 'react-router-dom'

const store = configureStore()
const labelSource = process.env.REACT_APP_LABELS_SOURCE
const locale = process.env.REACT_APP_LOCALE || 'en'

isAlive()
  .then(() => initLanguage(locale, labelSource))
  .then(() => {
    initializeApp(config.firebaseConfig)
    const auth = getAuth()
    return setPersistence(auth, browserLocalPersistence)
  })
  .then(() => {
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnMount: false,
          retry: false,
          // used when there are no more active instances of this query
          cacheTime: 5 * 60 * 1000,
        },
      },
    })
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <Suspense fallback="">
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </Suspense>
          </QueryClientProvider>
        </Provider>
      </React.StrictMode>,
      document.getElementById('root')
    )

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals()
  })
  .catch(() => {
    //API down, add a static error page
    ReactDOM.render(<ServerNotAvailablePage />, document.getElementById('root'))
  })
