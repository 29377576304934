import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Select, Tabs } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EventType, EventOrder, CourseStatus } from 'types/enums'
import { CourseFilter as CourseFilterType, Category } from 'types/store'

import style from './styles.module.scss'

interface Props {
  categories: Category[]
  filter: CourseFilterType
  onChange: (filer: CourseFilterType) => void
  onReset: () => void
}

const CourseFilter: React.FC<Props> = ({ categories, filter, onChange, onReset }) => {
  const { t } = useTranslation()
  const { order, category } = filter
  const [searchValue, setSearchValue] = useState<string>(null)

  useEffect(() => {
    setSearchValue(filter.search)
  }, [filter.search])

  const onTabChange = (type: EventType) => {
    onChange({ ...filter, type })
  }
  const onOrderByChange = (order: EventOrder) => {
    onChange({ ...filter, order })
  }
  const onCategoryCodeChange = (code: Category['code']) => {
    onChange({ ...filter, category: code })
  }
  const onStatusChange = (status: CourseStatus) => {
    onChange({ ...filter, status })
  }
  const onSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value)
  }, [])
  const onSearchOutlinedClick = () => {
    onChange({ ...filter, search: searchValue?.trim() ?? null })
  }
  const onSearchKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.code || event.key
    if (keyCode == 'Enter') {
      onChange({ ...filter, search: searchValue?.trim() ?? null })
      return false
    }
  }

  return (
    <Col md={24} className={classNames([style['course-filter'], 'pb-50 ml-50'])}>
      <Tabs activeKey={filter.type} onChange={onTabChange}>
        <Tabs.TabPane
          className="ml-10"
          tab={t('menu.webinars')}
          key={EventType.Webinar}
        ></Tabs.TabPane>
        <Tabs.TabPane tab={t('menu.courses')} key={EventType.Course}>
          <Row align="bottom">
            <Col md={4}>
              <Row>
                <Select
                  bordered={false}
                  placeholder={t('filter.placeholderOrderBy')}
                  value={order}
                  onChange={onOrderByChange}
                >
                  <Select.Option className="bg-select" value={EventOrder.ByEnrollmentDate}>
                    {t('order.byEnrollmentDate')}
                  </Select.Option>
                  <Select.Option className="bg-select" value={EventOrder.Alphabetic}>
                    {t('order.alphabetic')}
                  </Select.Option>
                  <Select.Option className="bg-select" value={EventOrder.AlphabeticInverted}>
                    {t('order.alphabeticInverted')}
                  </Select.Option>
                </Select>
              </Row>
            </Col>
            <Col md={4}>
              <Row>
                <Col md={12}>
                  <Select
                    bordered={false}
                    placeholder={t('filter.placeholderCategories')}
                    value={category}
                    onChange={onCategoryCodeChange}
                  >
                    {categories.map((category) => (
                      <Select.Option
                        className="bg-select"
                        key={category.code}
                        value={category.code}
                      >
                        {category.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                {/* <Col md={8}>
                  <Select
                    placeholder={t('filter.placeholderExpiration')}
                    value={category}
                    onChange={onCategoryCodeChange}
                  >
                    <Select.Option key="??" value="??">
                      ??
                    </Select.Option>
                  </Select>
                </Col> */}
                {/* <Col md={8}>
                  <Select
                    placeholder={t('filter.placeholderStatus')}
                    value={status}
                    onChange={onStatusChange}
                  >
                    <Select.Option value={CourseStatus.InProgress}>{t('filter.statusPending')}</Select.Option>
                    <Select.Option value={CourseStatus.NotStarted}>
                      {t('filter.statusNotStarted')}
                    </Select.Option>
                  </Select>
                </Col> */}
              </Row>
            </Col>
            <Col md={3}>
              <Row justify="center">
                <Button size="small" type="text" className="reset pr-30" onClick={onReset}>
                  {t('general.reset')}
                </Button>
              </Row>
            </Col>
            <Col md={6}>
              <Input
                className="input-header"
                placeholder={t('general.search')}
                prefix={<SearchOutlined onClick={onSearchOutlinedClick} />}
                value={searchValue}
                onChange={onSearchChange}
                onKeyPress={onSearchKeyPress}
              />
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </Col>
  )
}

CourseFilter.displayName = 'CourseFilter'

export default CourseFilter
