import React from 'react'

import {LearningPathStripSkeleton} from 'components/LearningPathStrip'
import {useLoadEnrolledCourses, useLoadLearningPathList} from 'services/query/course'
import NoContent from "../../components/NoContent";
import {useTranslation} from "react-i18next";
import QuizItem from "../../components/QuizItem";
import QuizStrip from "../../components/QuizStrip";

interface Props {
  flatMode: boolean
}

const DashboardQuizzesContainer: React.FC<Props> = ({flatMode}) => {
  const learningPathsQuery = useLoadLearningPathList()
  const {isFetched, isLoading, isError, data: learningPathList} = learningPathsQuery

  const {t} = useTranslation()
  const {
    isFetched: isFetchedCourse,
    isLoading: isLoadingCourse,
    isError: isErrorCourse,
    data: courses
  } = useLoadEnrolledCourses()

  if (isError || isErrorCourse) {
    return null
  }

  if (!isFetched || isLoading || (!isFetchedCourse || isLoadingCourse)) {
    return (
      <>
        <LearningPathStripSkeleton/>
      </>
    )
  }

  const learningPathWithQuizzes = learningPathList.filter(lp => lp.allCoursesCompleted && lp.hasQuiz && !lp.isQuizExpired && !lp.successfulAttempt)
  const courseWithQuizzes = courses.filter(c => c.progressPercentage >= 1 && c.hasQuiz && !c.isQuizExpired && !c.successfulAttempt)
  // console.log('lp quizzes', learningPathWithQuizzes)
  // console.log('course quizzes', courseWithQuizzes)


  const elements = [...courseWithQuizzes, ...learningPathWithQuizzes]

  if (learningPathWithQuizzes.length <= 0 && courseWithQuizzes.length <= 0) {
    return <NoContent text={t('dashboard.noQuizzes')}/>
  }

  const displayFlatItems = (courses, paths) => {
    return [
      ...courses.map((c) => <QuizItem key={c.code} element={c} type={"COURSE"}/>
      ),
      ...paths.map((lp) => <QuizItem key={lp.code} element={lp} type={"LEARNING_PATH"}/>
      )
    ]
  }

  const displayStrip = (courses, paths) => {
    return [
      ...courses.map((c) => <QuizStrip key={c.code} title={c.title} code={c.code} dueDate={c.dueDate} courses={[]}
                                       type={"COURSE"}/>
      ),
      ...paths.map((lp) => <QuizStrip key={lp.code} title={lp.title} code={lp.code} dueDate={lp.dueDate} courses={lp.courses}
                                      type={"LEARNING_PATH"}/>
      )
    ]
  }

  return (
    <>
      {
        flatMode
          ? displayFlatItems(courseWithQuizzes, learningPathWithQuizzes)
          : displayStrip(courseWithQuizzes, learningPathWithQuizzes)
      }
    </>
  )
}

DashboardQuizzesContainer.displayName = 'DashboardQuizzesContainer'

export default DashboardQuizzesContainer
