import React, { useState, ChangeEvent } from 'react'
import {
  Roster,
  RosterHeader,
  RosterGroup,
  useRosterState,
  RosterAttendeeType,
} from 'amazon-chime-sdk-component-library-react'

import { useNavigate } from '../providers/NavigationProvider'
import RosterAttendeeWrapper from '../components/RosterAttendeeWrapper'
import {useTranslation} from "react-i18next";

const MeetingRoster = () => {
  const { t } = useTranslation()
  const { roster } = useRosterState()
  const [filter, setFilter] = useState('')
  const { closeRoster } = useNavigate()

  let attendees = Object.values(roster)

  if (filter) {
    attendees = attendees.filter((attendee: RosterAttendeeType) =>
      attendee?.name?.toLowerCase().includes(filter.trim().toLowerCase())
    )
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value)
  }

  const attendeeItems = attendees.map((attendee: RosterAttendeeType) => {
    const { chimeAttendeeId } = attendee || {}
    attendee.name = (attendee as any).data
    return <RosterAttendeeWrapper key={chimeAttendeeId} attendeeId={chimeAttendeeId} />
  })

  return (
    <Roster className="roster">
      <RosterHeader
        searchValue={filter}
        onSearch={handleSearch}
        onClose={closeRoster}
        title={t('webinar.present')}
        badge={attendees.length}
      />
      <RosterGroup>{attendeeItems}</RosterGroup>
    </Roster>
  )
}

export default MeetingRoster
