import React, { useEffect } from 'react'
import { VideoTileGrid, UserActivityProvider } from 'amazon-chime-sdk-component-library-react'

import { StyledLayout, StyledContent } from './Styled'
import NavigationControl from '../../containers/Navigation/NavigationControl'
import { useNavigate } from '../../providers/NavigationProvider'
import MeetingDetails from '../../containers/MeetingDetails'
import useMeetingEndRedirect from '../../hooks/useMeetingEndRedirect'
import { Layout } from '../../types'
import { VideoTileGridProvider } from '../../providers/VideoTileGridProvider'
import { useMeetingState } from '../../providers/MeetingStateProvider'
import MeetingControls from '../../containers/MeetingControls'
import { useSelector } from 'react-redux'
import { currentEditionSelector } from '../../store/Course/selectors'
import { WebinarEdition } from '../../types/store'
import { updateEditionCompletedByCode } from '../../api/course'
import { useCurrentUserId, useLoadWebinarByCode } from '../../services/query'

const MeetingView = () => {
  useMeetingEndRedirect()
  const userId = useCurrentUserId()

  const { showNavbar, showRoster } = useNavigate()
  const { layout } = useMeetingState()
  const currentEdition: WebinarEdition = useSelector(currentEditionSelector)
  const {
    isFetched,
    isLoading,
    isError,
    data: webinar,
  } = useLoadWebinarByCode(currentEdition.courseCode)

  if (isError) {
    return null
  }

  if (!isFetched || isLoading) {
    return null
  }

  const isWebinarTeacher = webinar.teacher.id === userId
  const thirtyMillis = 30000
  const progress = 30

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!isWebinarTeacher) {
        const completionData = await updateEditionCompletedByCode(
          userId,
          +currentEdition.courseCode,
          +currentEdition.code,
          { timestamp: new Date().toISOString(), progress: progress }
        )
      }
    }, thirtyMillis)

    return () => clearInterval(interval)
  }, [])

  return (
    <UserActivityProvider>
      <VideoTileGridProvider>
        <StyledLayout showNav={showNavbar} showRoster={showRoster}>
          <StyledContent>
            <VideoTileGrid
              layout={layout === Layout.Gallery ? 'standard' : 'featured'}
              className="videos"
              noRemoteVideoView={<MeetingDetails />}
            />
            <MeetingControls />
          </StyledContent>
          <NavigationControl />
        </StyledLayout>
      </VideoTileGridProvider>
    </UserActivityProvider>
  )
}

export default MeetingView
