import React, { useEffect, useState } from 'react'
import WebinarEditions from 'components/WebinarEditions'
import { Course, WebinarEdition } from 'types/store'
import { useTranslation } from 'react-i18next'
import {
  useLoadCurrentUser,
  useLoadWebinarByCode,
  useLoadWebinarFutureEditionList,
} from 'services/query'
import { Button, Row } from 'antd'
import { useSelector } from 'react-redux'
import { currentEditionSelector } from '../../store/Course/selectors'
import { subscribeWebinar } from '../../api/webinar'
import { editionStatuses } from '../../constants'
import { agreementSelector } from '../../store/App/selectors'
import AgreementPage from '../../pages/AgreementPage/AgreementPage'

interface Props {
  code: Course['code']
  onEditionSelect: (edition: WebinarEdition) => void
  onWebinarShow: () => void
}

const WebinarEditionListContainer: React.FC<Props> = ({ code, onEditionSelect, onWebinarShow }) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  const [freeSeats, setFreeSeats] = useState(null)

  let canStart = false

  const {
    isFetched: isWebinarFetched,
    isLoading: isWebinarLoading,
    isError: isWebinarError,
    data: webinar,
  } = useLoadWebinarByCode(code)

  const [loading, setLoading] = useState(false)
  const currentEdition = useSelector(currentEditionSelector)
  const userAgreement = useSelector(agreementSelector)

  const {
    isFetched: isUserFetched,
    isLoading: isUserLoading,
    isError: isUserError,
    data: user,
  } = useLoadCurrentUser()

  const [isEnrolled, setIsEnrolled] = useState(
    !!user.editions.find((edition) => currentEdition && edition.code === currentEdition.code)
  )
  const [buttonLabel, setButtonLabel] = useState(
    isEnrolled ? t('general.subscribed') : t('general.subscribe')
  )

  const [agreed, setAgreed] = useState<boolean>(null)

  const isWebinarTeacher = user.id === webinar.teacher.id

  const { isFetched, isLoading, isError, data: editions } = useLoadWebinarFutureEditionList(code)

  useEffect(() => {
    setIsEnrolled(
      !!user.editions.find((edition) => currentEdition && edition.code === currentEdition.code)
    )
    if (currentEdition)
      setFreeSeats(currentEdition.freeSeats)
  }, [currentEdition])

  useEffect(() => {
    setButtonLabel(isEnrolled ? t('general.subscribed') : t('general.subscribe'))
  }, [isEnrolled])

  useEffect(() => {
    setAgreed(userAgreement.webinarRecording)
  }, [userAgreement])

  if (isError || isUserError || isWebinarError) {
    return null
  }

  if (
    !isFetched ||
    !isUserFetched ||
    !isWebinarFetched ||
    isLoading ||
    isUserLoading ||
    isWebinarLoading
  ) {
    return null
  }
  if (currentEdition) canStart = currentEdition.date.valueOf() < Date.now()

  const onClick = async () => {
    if (agreed) {
      setLoading(false)
      await subscribeWebinar(code, currentEdition.code, user.id)
      user.editions.push(currentEdition)
      setFreeSeats(freeSeats - 1)
      setIsEnrolled(true)
      setButtonLabel(t('general.subscribed'))
      setLoading(false)
    } else {
      setVisible(true)
    }
  }

  return (
    <>
      <AgreementPage showOnlyWebinarRecordingPolicy={true} isVisible={visible} />
      <Row>
        <WebinarEditions editions={editions} onEditionSelect={onEditionSelect} freeSeats={freeSeats} />

        {currentEdition &&
          currentEdition.status === editionStatuses.notStarted &&
          !isWebinarTeacher &&
          !canStart && (
            <>
              <Row className="btn">
                <Button
                  type="primary"
                  className="subscribe"
                  onClick={onClick}
                  disabled={isEnrolled}
                  loading={loading}
                >
                  {buttonLabel}
                </Button>
              </Row>
            </>
          )}

        {currentEdition &&
          ((isWebinarTeacher && canStart && currentEdition.status !== editionStatuses.ended) ||
            (isEnrolled && currentEdition.status === editionStatuses.started)) && (
            <Row className="btn">
              <Button
                type="primary"
                className="subscribe"
                onClick={onWebinarShow}
                loading={loading}
              >
                {isWebinarTeacher ? t('webinar.launchEdition') : t('webinar.joinEdition')}
              </Button>
            </Row>
          )}
      </Row>
    </>
  )
}

WebinarEditionListContainer.displayName = 'WebinarEditionListContainer'

export default WebinarEditionListContainer
