import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { config } from './config'
import { getLabelRequest, labelParser } from './labelHelper'

//https://github.com/i18next/i18next/issues/1287
//Require to be bound to work correctly everywhere
const t = i18n.t.bind(i18n)
export { t }

//https://github.com/i18next/i18next-http-backend
const backendOptionsServer = {
  loadPath: config.api.vocabulary(),
  allowMultiLoading: false,
  crossDomain: false,
  withCredentials: false,
  // comment this method for loading from /public feed
  parse: function (data: string) {
    return labelParser(data)
  },
  // comment this method for loading from /public feed
  request: getLabelRequest(),
  overrideMimeType: false,
  requestOptions: {
    // used for fetch, can also be a function (payload) => ({ method: 'GET' })
    mode: 'cors',
    credentials: 'same-origin',
    cache: 'default',
  },
  reloadInterval: false,
}

const backendOptionsLocal = {
  loadPath: config.api.localVocabulary(),
  allowMultiLoading: false,
  crossDomain: false,
  withCredentials: false,
  overrideMimeType: false,
  requestOptions: {
    // used for fetch, can also be a function (payload) => ({ method: 'GET' })
    mode: 'cors',
    credentials: 'same-origin',
    cache: 'default',
  },

  reloadInterval: false,
}

const getBackendOptions = (source: string) => {
  switch (source) {
    case 'local':
      return backendOptionsLocal
    default:
      return backendOptionsServer
  }
}

export function initLanguage(locale: string, source: string) {
  const backendOptions = getBackendOptions(source)
  const key = 'NO_TRANSLATIONS'
  if (JSON.parse(localStorage.getItem(key))) {
    backendOptions.loadPath = '/'
  }

  return (
    i18n
      // load translation using http -> see /public/locales
      //(i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
      // learn more: https://github.com/i18next/i18next-http-backend
      .use(Backend)
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      .use(LanguageDetector)
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        fallbackLng: locale,
        supportedLngs: [locale],
        backend: backendOptions,
        debug: true,
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
      })
  )
}
