import {config} from 'core'
import {
  BlockchainCourseCertificationBaseData,
  BlockchainCourseCertificationData,
  CourseDetailData,
  CourseSearchData,
  EnrolledCourseListData,
  GetAllCoursesParams,
  LearningPathProgressionData,
  UserCompletableCreateData,
} from 'types/api'
import {getAxios} from './apiService'
import {getApiError, getAuthenticationHeader} from './common'
import {LearningPath} from "../types/store";

const axios = getAxios()

export async function getCourseList(
  params: Omit<GetAllCoursesParams, 'courseType'> = {}
): Promise<CourseSearchData> {
  try {
    const {headers} = await getAuthenticationHeader()
    const response = await axios.get(config.api.getCourses(), {
      headers,
      params: {...params, courseType: 'OnDemand'} as GetAllCoursesParams,
    })
    return response.data as CourseSearchData
  } catch (error) {
    throw getApiError(error)
  }
}

export async function getCourseDetailById(id: CourseDetailData['id']) {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.get(config.api.getCourseById(id), headers)
    return response.data as CourseDetailData
  } catch (error) {
    throw getApiError(error)
  }
}

export async function getBscCertificateByTransactionHash(transactionHash: string) {
  try {
    const response = await axios.get(config.api.getBscCertificateByTransactionHash(transactionHash))
    return response.data as BlockchainCourseCertificationData
  } catch (error) {
    throw getApiError(error)
  }
}

export async function getEnrolledCoursesByUserId(
  userId: number
): Promise<EnrolledCourseListData[]> {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.get(config.api.getEnrolledCoursesByUserId(userId), headers)
    return response.data as EnrolledCourseListData[]
  } catch (error) {
    throw getApiError(error)
  }
}

export async function putEnrolledCourseByUserId(courseId: number, userId: number): Promise<void> {
  try {
    const headers = await getAuthenticationHeader()
    await axios.put(config.api.putEnrolledCourseByUserId(courseId, userId), null, headers)
  } catch (error) {
    throw getApiError(error)
  }
}

export async function updateLectureCompletedByCode(
  lectureId: number,
  userId: number,
  data: UserCompletableCreateData
): Promise<void> {
  try {
    const headers = await getAuthenticationHeader()
    await axios.post(config.api.postCompletedLectureByUserId(lectureId, userId), data, headers)
  } catch (error) {
    throw getApiError(error)
  }
}

export async function updateEditionCompletedByCode(
  userId: number,
  webinarId: number,
  editionId: number,
  data: UserCompletableCreateData
): Promise<void> {
  try {
    const headers = await getAuthenticationHeader()
    await axios.post(config.api.postCompletedEdition(userId, webinarId, editionId), data, headers)
  } catch (error) {
    throw getApiError(error)
  }
}

export async function getLearningPathsByUser(
  userId: number
): Promise<LearningPathProgressionData[]> {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.get(config.api.getLearningPathsByUser(userId), headers)
    return response.data as LearningPathProgressionData[]
  } catch (error) {
    throw getApiError(error)
  }
}

export async function getLearningPathDetailById(userId: number, learningPathId: number) {
  try {
    const headers = await getAuthenticationHeader()
    const response = await axios.get(config.api.getLearningPathById(userId, learningPathId), headers)
    return response.data as LearningPathProgressionData
  } catch (error) {
    throw getApiError(error)
  }
}

export async function createBscTransaction(
  courseId: number,
  userId: number,
  blockchainCourseCertificationBaseData: BlockchainCourseCertificationBaseData
) {
  try {

    const headers = await getAuthenticationHeader()
    const response = await axios.post(config.api.postBscTransaction(courseId, userId), blockchainCourseCertificationBaseData, headers);
    return response.data as string;
  } catch (error) {
    throw getApiError(error)
  }
}

export async function createLearningPathBscTransaction(
  learningPathId: number,
  userId: number,
  blockchainCourseCertificationBaseData: BlockchainCourseCertificationBaseData
) {
  try {

    const headers = await getAuthenticationHeader()
    const response = await axios.post(config.api.postLearningPathBscTransaction(learningPathId, userId), blockchainCourseCertificationBaseData, headers);
    return response.data as string;
  } catch (error) {
    throw getApiError(error)
  }
}
