import React, { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import CourseFilter from 'containers/CourseFilter'
import CourseHeader from 'containers/CourseHeader'
import Courses from 'containers/Courses'
import style from './styles.module.scss'
import { filterFromQuery } from 'services/navigation/course'
import { EventType } from 'types/enums'
import WebinarGridContainer from 'containers/WebinarGrid'

const CoursesOverallPage: React.FC = () => {
  const { search } = useLocation()
  const filter = useMemo(() => filterFromQuery(search), [search])
  const { code } = useParams()

  return (
    <section className={style['courses-page']}>
      <section className="app-container my-30">
        <CourseHeader />
        <CourseFilter defaultCategoryCode={code} />
        {(() => {
          switch (filter.type) {
            case EventType.Webinar:
              return <WebinarGridContainer />
            case EventType.Course:
            default:
              return <Courses />
          }
        })()}
      </section>
    </section>
  )
}

export default CoursesOverallPage
