// import proper moment translation for each new language
import 'moment/locale/it'

import { apiProvider } from 'config/api'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
const platform = 'WEB'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

/* eslint-disable no-useless-escape */
export const VALIDATE_PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/g
export const EMPTY_DATE = '0001-01-01T00:00:00.000Z'

export const config = (function config() {
  const { location } = window
  const provider = apiProvider(apiBaseUrl)
  let language: string = null
  return {
    api: provider.api,
    getLanguage: () => language,
    getDateTimeFormat: () => 'DD/MM/YYYY',
    setLanguage: (lng: string) => {
      provider.setLanguage(lng)
      language = lng
    },
    apiBaseUrl,
    appBaseUrl: `${location.protocol}//${location.host}`,
    platform,
    firebaseConfig,
  }
})()

export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY
