import React from 'react'

import LearningPathStrip, {LearningPathStripSkeleton} from 'components/LearningPathStrip'
import LearningPathListItem from 'components/LearningPathListItem'
import NoLearningPath from 'components/NoLearningPath'
import {useLoadLearningPathList} from 'services/query/course'

interface Props {
  flatMode: boolean
}

const DashboardLearningPathsContainer: React.FC<Props> = ({flatMode}) => {
  const learningPathsQuery = useLoadLearningPathList()
  const {isFetched, isLoading, isError, data: learningPathList} = learningPathsQuery

  if (isError) {
    return null
  }

  if (!isFetched || isLoading) {
    return (
      <>
        <LearningPathStripSkeleton/>
      </>
    )
  }

  if (learningPathList.length <= 0) {
    return <NoLearningPath/>
  }
  return (
    <>
      {flatMode
        ? learningPathList.map((learningPath) => (
          <LearningPathListItem key={learningPath.code} learningPath={learningPath}/>
        ))
        : learningPathList.map(({code, title, dueDate, successfulAttempt, allCoursesCompleted, isQuizExpired, hasQuiz, courses}) => (
          <LearningPathStrip key={code} title={title} dueDate={dueDate} courses={courses} code={code} successfulAttempt={successfulAttempt}
                             allCoursesCompleted={allCoursesCompleted} isQuizExpired={isQuizExpired} hasQuiz={hasQuiz}/>
        ))}
    </>
  )
}

DashboardLearningPathsContainer.displayName = 'DashboardLearningPathsContainer'

export default DashboardLearningPathsContainer
