import {Card, Col, Row, Typography} from 'antd'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, Link} from 'react-router-dom'
import CourseLink, {CourseLinkType} from 'components/CourseLink'
import routes from 'config/routes'
import {Course} from 'types/store'
import style from './styles.module.scss'
import WebinarLink from "../WebinarLink";

const {Paragraph, Text} = Typography

interface Props {
  course: Course
}

const CourseCard: React.FC<Props> = ({course}) => {
  const {code, title, description, thumbnailImg, category, progressPercentage, duration} = course
  const {t} = useTranslation()

  const durationSeconds = duration % 60
  const durationMinutes = ((duration - durationSeconds) / 60) % 60
  const durationHours = Math.floor((duration - durationMinutes * 60) / 3600)

  return (
    <Card
      className={style['course-card']}
      cover={
        thumbnailImg ? (
          <img alt="cover" src={thumbnailImg}/>
        ) : (
          <img alt="cover" src={'/images/course-fallback.png'} style={{objectFit: 'contain'}}/>
        )
      }
    >
      <Card.Meta
        className="mb-16"
        title={
          <Col>
            {course.courseType === 'Webinar' ? (
              <Link to={generatePath(routes.webinarDetail.url, {code})}>
                <Paragraph className="title">{title}</Paragraph>
              </Link>
            ) : (
              <Link to={generatePath(routes.courseDetail.url, {code})}>
                <Paragraph className="title">{title}</Paragraph>
              </Link>
            )}
            <Text>
              {t('courses.duration', {
                p0: durationHours,
                p1: `${durationMinutes}`.padStart(2, '0'),
                count: durationHours,
              })}
            </Text>
          </Col>
        }
        description={<Paragraph ellipsis={{rows: 2}}>{description}</Paragraph>}
      />
      <Row className="category mb-16 mt-10">
        <Text strong className="mr-5">
          {t('general.category')}:
        </Text>
        <Text>{category.name}</Text>
      </Row>
      <Row justify="center">
        {(() => {
          if (progressPercentage === null) {
            return <div className="progressionImage-placeholder"/>
          }
          if (progressPercentage <= 0)
            return <img className="progressionImage" src="/images/icons/progression-0.png"/>
          if (progressPercentage <= 0.25)
            return <img className="progressionImage" src="/images/icons/progression-1.png"/>
          if (progressPercentage <= 0.5)
            return <img className="progressionImage" src="/images/icons/progression-2.png"/>
          if (progressPercentage < 1)
            return <img className="progressionImage" src="/images/icons/progression-3.png"/>
          return <img className="progressionImage" src="/images/icons/progression-4.png"/>
        })()}
      </Row>
      {course.courseType === 'Webinar' ?
        <WebinarLink type={CourseLinkType.discover} className="mt-50" course={course}/> :
        <CourseLink type={CourseLinkType.discover} className="mt-50" course={course}/>}
    </Card>
  )
}

CourseCard.displayName = 'CourseCard'

export default CourseCard
