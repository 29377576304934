import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CourseModuleList from 'components/CourseModuleList'
import * as courseAction from 'store/Course/actions'
import { currentLessonSelector } from 'store/Course/selectors'
import { CourseDetail, Lesson, Module } from 'types/store'
import {useLoadCourseModuleList, useLoadEnrolledCourses} from 'services/query'

interface Props {
  code: CourseDetail['code']
  hasQuiz: CourseDetail['hasQuiz']
  isQuizExpired: CourseDetail['isQuizExpired']
}

const CourseModuleListContainer: React.FC<Props> = ({ code, hasQuiz, isQuizExpired}) => {
  const dispatch = useDispatch()
  const { isFetched, isLoading, isError, data: moduleList } = useLoadCourseModuleList(code)
  const {isFetched: isEnrolledFetched, isLoading: isEnrolledLoading, isError: isEnrolledError, data: enrolledCourses} = useLoadEnrolledCourses()

  const course = enrolledCourses.find(course => course.code === code)

  const isCourseCompleted = course?.progressPercentage == 1 || false
  const isQuizCompleted = course?.successfulAttempt || false

  const currentLesson = useSelector(currentLessonSelector)
  const onLessonSelect = (lesson: Lesson) => dispatch(courseAction.setCurrentLesson(lesson))

  const currentModule: Module | null = useMemo(() => {
    if (!currentLesson) {
      return null
    }
    return moduleList.find((module) => {
      const lessonIndex = module.lessons.findIndex((lesson) => {
        return lesson.code === currentLesson.code
      })
      return lessonIndex >= 0
    })
  }, [currentLesson?.code, moduleList])

  useEffect(() => {
    if (moduleList.length > 0) {
      for (const module of moduleList) {
        for (const lesson of module.lessons) {
          if (!lesson.completed) {
            onLessonSelect(lesson)
            return
          }
        }
      }
    }
  }, [moduleList])

  if (isError || isEnrolledError) {
    return null
  }

  if (!isFetched || isLoading || !isEnrolledFetched || isEnrolledLoading) {
    return null
  }

  return (
    <CourseModuleList
      modules={moduleList}
      currentModuleCode={currentModule?.code ?? null}
      currentLessonCode={currentLesson?.code ?? null}
      courseCode={code}
      hasQuiz={hasQuiz}
      isQuizExpired={isQuizExpired}
      hasCompletedCourse={isCourseCompleted}
      hasCompletedQuiz={isQuizCompleted}
      onLessonSelect={onLessonSelect}
    />
  )
}

CourseModuleListContainer.displayName = 'CourseModuleListContainer'

export default CourseModuleListContainer
