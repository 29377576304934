import React from 'react'
import { useTranslation } from 'react-i18next'
import style from './styles.module.scss'
import { Webinar } from 'types/store'

interface Props {
  webinar: Webinar
}

const Thumbnail: React.FC<Props> = ({ webinar }) => {
  const { t } = useTranslation()

  return (
    <section className={style['thumbnail']}>
      <img src={webinar.thumbnailImg || '/images/course-fallback.png'} alt="thumbnail" />
    </section>
  )
}

Thumbnail.displayName = 'Thumbnail'

export default Thumbnail
