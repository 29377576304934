import React, { Component, ErrorInfo } from 'react'
import Error500 from '../Error500'

//THIS COMPONENT SHOULD BE
class ErrorBoundary extends Component {
  public state = {
    redirect: '',
    hasError: false,
  }
  public static getDerivedStateFromError() {
    return { hasError: true }
  }
  // set the types for error  and info
  public componentDidCatch(error: Error, info: ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, info)
    //clear local store
    localStorage.clear()
  }
  public render() {
    if (this.state.hasError) {
      return <Error500 />
    }

    return this.props.children
  }
}

export default ErrorBoundary
