import { EventOrder } from 'types/enums'
import { Course } from 'types/store'

export const sortByEventOrder = (list: Course[], order: EventOrder): Course[] => {
  const safeClone = Array.from(list)
  switch (order) {
    case EventOrder.Alphabetic:
      return safeClone.sort((a, b) => a.title.localeCompare(b.title))
    case EventOrder.AlphabeticInverted:
      return safeClone.sort((a, b) => b.title.localeCompare(a.title))
    case EventOrder.ByCreationDate:
      return safeClone.sort((a, b) =>
        a.creationDate < b.creationDate ? -1 : a.creationDate > b.creationDate ? 1 : 0
      )
    case EventOrder.ByEnrollmentDate:
      return safeClone.sort((a, b) =>
        a.enrollmentDate < b.enrollmentDate ? -1 : a.enrollmentDate > b.enrollmentDate ? 1 : 0
      )
    default:
      return safeClone
  }
}
