import { Space, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import style from './styles.module.scss'

const NoLearningPath: React.FC = ({}) => {
  const { t } = useTranslation()

  return (
    <Space className={style['no-learning-path']}>
      <img src="/images/icons/error_outline.svg" alt="Attention" />
      <Typography.Title level={3}>{t('learningPaths.noLearning')}</Typography.Title>
    </Space>
  )
}

NoLearningPath.displayName = 'NoLearningPath'

export default NoLearningPath
