import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'

import routes from 'config/routes'
import Header from 'components/Header'
import { filterToQuery } from 'services/navigation/course'
import { useLoadCategoryList } from 'services/query/user'
import { useLoadCurrentUser } from 'services/query/user'
import { useLoadCompanyById } from 'services/query/company'

const HeaderContainer: React.FC = () => {
  useLoadCategoryList() // right now leave it here in order to preserve category query-cache
  const userQuery = useLoadCurrentUser()
  const { data: user } = userQuery
  const { data: company } = useLoadCompanyById(user.companyId)
  const navigate = useNavigate()
  const onSearch = useCallback((text) => {
    navigate({
      pathname: routes.coursesOverall.url,
      search: filterToQuery({ search: text }),
    })
  }, [])

  return <Header name={user?.name ?? ''} companyLogo={company.thumbnailUrl} onSearch={onSearch} />
}

HeaderContainer.displayName = 'HeaderContainer'

export default HeaderContainer
