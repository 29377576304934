import React from 'react'
import { useParams } from 'react-router'
import CourseDetailContainer from 'containers/CourseDetail'

const CourseDetail: React.FC = () => {
  const { code } = useParams()
  return <CourseDetailContainer code={code} />
}

export default CourseDetail
