/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux'

import { STATE_KEY as APP_KEY } from 'store/App/constants'
import { STATE_KEY as COURSE_KEY } from 'store/Course/constants'
import app from 'store/App/reducer'
import course from 'store/Course/reducer'

const RootReducer = combineReducers({
  [APP_KEY]: app,
  [COURSE_KEY]: course,
})

export default RootReducer
