import { useQuery } from 'react-query'

import {
  getReservedUsersByCourseIdAndEditionId,
  getWebinarDetailById,
  getWebinarEditionList,
  getWebinarList
} from 'api/webinar'
import * as queryKeys from 'config/queryKeys'
import {
  mapFromWebinarCode,
  mapWebinarDetail,
  mapWebinarEditionList, mapWebinarFutureEditionList,
  mapWebinarList,
} from 'services/map/webinar'
import { Webinar, Course } from 'types/store'
import { webinarDetailPlaceholder } from 'services/map/common'
import { mapUser } from '../map/user'

export function useLoadWebinarList() {
  const queryFunction = async () => {
    const courseSearch = await getWebinarList()
    return mapWebinarList(courseSearch.courses)
  }
  return useQuery(queryKeys.webinarList(), queryFunction, {
    placeholderData: [],
  })
}

export function useLoadWebinarByCode(code: Webinar['code']) {
  const queryFunction = async () => {
    const webinarDetail = await getWebinarDetailById(mapFromWebinarCode(code))
    return mapWebinarDetail(webinarDetail)
  }
  return useQuery(queryKeys.webinarDetailByCode(code), queryFunction, {
    placeholderData: webinarDetailPlaceholder,
  })
}

export function useLoadWebinarEditionList(code: Webinar['code']) {
  const queryFunction = async () => {
    const webinarEditionList = await getWebinarEditionList(mapFromWebinarCode(code))
    return mapWebinarEditionList(webinarEditionList)
  }
  return useQuery(queryKeys.webinarEditionList(code), queryFunction, {
    placeholderData: [],
  })
}
export function useLoadWebinarFutureEditionList(code: Webinar['code']) {
  const queryFunction = async () => {
    const webinarEditionList = await getWebinarEditionList(mapFromWebinarCode(code))
    return mapWebinarFutureEditionList(webinarEditionList)
  }
  return useQuery(queryKeys.webinarEditionList(code), queryFunction, {
    placeholderData: [],
  })
}

export function useLoadReservedUsersByCourseIdAndEditionId(
  courseCode: Course['code'],
  webinarCode: Webinar['code']
) {
  const queryFunction = async () => {
    const userList = await getReservedUsersByCourseIdAndEditionId(+courseCode, +webinarCode)
    return userList.map((user) => mapUser(user))
  }
  return useQuery(
    queryKeys.usersByCourseCodeAndWebinarCode(courseCode, webinarCode),
    queryFunction,
    {
      placeholderData: [],
    }
  )
}
