import React from 'react'
import {
  ControlBar,
  AudioInputVFControl,
  AudioInputControl,
  ContentShareControl,
  AudioOutputControl,
  ControlBarButton,
  useUserActivityState,
  Dots,
  VideoInputControl,
} from 'amazon-chime-sdk-component-library-react'

import EndMeetingControl from '../EndMeetingControl'
import { useNavigate } from '../../providers/NavigationProvider'
import { StyledControls } from './Styled'
import { useMeetingState } from '../../providers/MeetingStateProvider'
import { VideoFiltersCpuUtilization } from '../../types'
import VideoInputTransformControl from '../../components/MeetingControls/VideoInputTransformControl'

const MeetingControls: React.FC = () => {
  const { toggleNavbar, closeRoster, showRoster } = useNavigate()
  const { isUserActive } = useUserActivityState()
  const { isWebAudioEnabled, videoTransformCpuUtilization } = useMeetingState()
  const videoTransformsEnabled =
    videoTransformCpuUtilization !== VideoFiltersCpuUtilization.Disabled

  const handleToggle = (): void => {
    if (showRoster) {
      closeRoster()
    }
    toggleNavbar()
  }

  return (
    <StyledControls className="controls" active={!!isUserActive}>
      <ControlBar className="controls-menu" layout="undocked-horizontal" showLabels>
        <ControlBarButton
          className="mobile-toggle"
          icon={<Dots />}
          onClick={handleToggle}
          label="Menu"
        />
        {isWebAudioEnabled ? <AudioInputVFControl /> : <AudioInputControl />}
        {videoTransformsEnabled ? <VideoInputTransformControl /> : <VideoInputControl />}
        <ContentShareControl />
        <AudioOutputControl />
        <EndMeetingControl />
      </ControlBar>
    </StyledControls>
  )
}

export default MeetingControls
