import {Collapse, Row, Typography} from 'antd'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {CheckCircleOutlined, PlayCircleOutlined} from '@ant-design/icons'
import {Course, Lesson, Module} from 'types/store'
import style from './styles.module.scss'
import {generatePath, Link} from "react-router-dom";
import routes from "../../config/routes";

const {Title} = Typography
const {Panel} = Collapse

interface Props {
  modules: Module[]
  /** null means no lesson selected */
  currentModuleCode: Module['code'] | null
  currentLessonCode: Lesson['code'] | null
  courseCode: Course['code']
  hasQuiz: boolean | null
  isQuizExpired: boolean | null
  hasCompletedCourse: boolean | null
  hasCompletedQuiz: boolean | null
  onLessonSelect: (lesson: Lesson) => void
}

const CourseModuleList: React.FC<Props> = ({
                                             modules,
                                             currentModuleCode,
                                             currentLessonCode,
                                             courseCode,
                                             hasQuiz,
                                             hasCompletedCourse,
                                             hasCompletedQuiz,
                                             isQuizExpired,
                                             onLessonSelect
                                           }) => {
  const {t} = useTranslation()

  if (modules.length <= 0) {
    return (
      <section className={classNames(style['course-module-list'], 'app-container')}>
        <Typography.Title level={4}>{t('courses.noModules')}</Typography.Title>
      </section>
    )
  }
  return (
    <section className={classNames(style['course-module-list'], 'app-container')}>
      <Title className="py-30" level={4}>
        {t('courses.courseContent')}:
      </Title>
      <Collapse
        // key is used to remount the `Collapse` in order to reselect the `defaultActiveKey`
        key={currentModuleCode}
        className="collapse"
        defaultActiveKey={currentModuleCode}
        expandIconPosition="right"
      >
        {modules.map((module) => {
          const {code, title, lessons} = module
          return (
            <Panel className="panel" header={<Title level={5}>{title}</Title>} key={code}>
              {lessons.length <= 0 ? (
                <Typography.Title level={5}>{t('courses.noModuleLectures')}</Typography.Title>
              ) : (
                lessons.map((lesson, lessonIndex) => {
                  const {code, title, completed} = lesson
                  return (
                    <Row
                      key={code}
                      className="lesson-row"
                      role="button"
                      onClick={() => onLessonSelect(lesson)}
                    >
                      {completed ? <CheckCircleOutlined/> : <PlayCircleOutlined/>}
                      <Title
                        className="lesson-title"
                        level={5}
                        underline={lesson.code === currentLessonCode}
                      >
                        {lessonIndex + 1}: {title}
                      </Title>
                    </Row>
                  )
                })
              )}
            </Panel>
          )
        })}
        {
          hasQuiz && !isQuizExpired &&
          <Panel className="panel" header={<Title level={5}> {hasCompletedCourse ? (hasCompletedQuiz ? "Quiz completato!" : "Fai il Quiz") : "Completa il corso per affrontare il quiz"}</Title>}
                 key='quiz' collapsible={hasCompletedCourse ? (hasCompletedQuiz ? "disabled" : "header") : "disabled" } /*collapsible={(!hasCompletedCourse || (hasCompletedCourse && !hasCompletedQuiz)) ? 'disabled' : 'header'}*/>
            <Link to={generatePath(routes.courseQuiz.url, {code: courseCode})}>

            <Row
              key={'quiz'}
              className="lesson-row"
              role="button"
            >
              {hasCompletedQuiz ? <CheckCircleOutlined/> : <PlayCircleOutlined/>}
              <Title
                className="lesson-title"
                level={5}
                underline={true}
              >
                {hasCompletedCourse ? (hasCompletedQuiz ? "Quiz completato!" : "Fai il Quiz") : "Completa il corso per affrontare il quiz"}
              </Title>
            </Row>
            </Link>
          </Panel>
        }
        {
          hasQuiz && isQuizExpired && <div>
            {t('courses.quizExpired')}
          </div>
        }

      </Collapse>
    </section>
  )
}

CourseModuleList.displayName = 'CourseModuleList'

export default CourseModuleList
