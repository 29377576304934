import React from 'react'

import { Webinar } from 'types/store'

import { Row, Col } from 'antd'

import style from './styles.module.scss'
import WebinarCard from 'components/WebinarCard'

interface Props {
  webinars: Array<Webinar>
}

const WebinarGrid: React.FC<Props> = ({ webinars }) => {
  return (
    <Row className={style['webinar-grid']} gutter={[30, 30]}>
      {webinars.map((item) => {
        return (
          <Col md={8} lg={6} xs={24} key={item.code}>
            <WebinarCard item={item} key={item.code} />
          </Col>
        )
      })}
    </Row>
  )
}

WebinarGrid.displayName = 'WebinarGrid'

export default WebinarGrid
