import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'antd'
import {CheckboxValueType} from 'antd/es/checkbox/Group'

interface Props {
  isVisible?: boolean
  onDismiss: () => void
}

const StillWatchingPage: React.FC<Props> = ({
                                              isVisible,
                                              onDismiss,
                                            }) => {
  const [visible, setVisible] = useState(isVisible)

  useEffect(() => {
    setVisible(isVisible)
  }, [isVisible])


  const handleOk = async () => {
    onDismiss()
    setVisible(false)
  }


  return (
    <>
      <Modal
        maskClosable={false}
        centered
        width='70%'
        visible={visible}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            Continua
          </Button>,
        ]}
      >
        <div className='text-center'>
          <h1 className='still-watching-title'>Stai ancora guardando?</h1>
        </div>
      </Modal>
    </>
  )
}

export default StillWatchingPage
