import React from 'react'
import { Menu } from 'antd'
import { useTranslation } from 'react-i18next'

import {DownOutlined, FileUnknownOutlined, HeartOutlined, QuestionOutlined, UserOutlined} from '@ant-design/icons'
import { MenuAction } from '../types'
import routes from 'config/routes'
import { Link } from 'react-router-dom'
import { useSettingValue } from 'services/query'
import IconFont from 'core/IconFont'

interface Props {
  onItemClick: (key: MenuAction) => void
}

const UserMenu: React.FC<Props> = ({ onItemClick }) => {
  const { t } = useTranslation()

  return (
    <Menu
      className="menu-drop"
      style={{ padding: '18px', border: 'none', width: '258px' }}
      onClick={(e) => onItemClick(e.key as MenuAction)}
    >
      <Menu.Item
        style={{ borderBottom: '1px solid #7d7d7d', marginTop: '0px', marginBottom: '0px' }}
        key={MenuAction.Dashboard}
      >
        <Link to={routes.dashboard.url}>
          <IconFont
            type="icon-dashboard"
            style={{ fontSize: '25px', marginRight: '10px', paddingTop: '5px' }}
          />
          {t('menu.dashboard')}
        </Link>
      </Menu.Item>

      <Menu.Item
        style={{ borderBottom: '1px solid #7d7d7d', marginTop: '0px', marginBottom: '0px' }}
        key={MenuAction.Quiz}
      >
        <Link to={routes.quiz.url}>
          <FileUnknownOutlined  style={{ fontSize: '25px', marginRight: '10px', paddingTop: '5px' }}/>
          {/*<IconFont*/}
          {/*  type="icon-quizzes"*/}
          {/*  style={{ fontSize: '25px', marginRight: '10px', paddingTop: '5px' }}*/}
          {/*/>*/}
          {t('menu.quiz')}
        </Link>
      </Menu.Item>

      <Menu.Item
        style={{ borderBottom: '1px solid #7d7d7d', display: 'none' }}
        key={MenuAction.Favourites}
      >
        {t('menu.favorites')}
      </Menu.Item>

      <Menu.Item
        style={{ borderBottom: '1px solid #7d7d7d', marginTop: '0px', marginBottom: '0px' }}
        key={MenuAction.Certifications}
      >
        <Link to={routes.certifications.url}>
          <IconFont
            type="icon-certifications"
            style={{ fontSize: '25px', marginRight: '10px', paddingTop: '5px' }}
          />
          {t('menu.certifications')}
        </Link>
      </Menu.Item>

      <Menu.Item
        style={{ borderBottom: '1px solid #7d7d7d', marginTop: '0px', marginBottom: '0px' }}
        key={MenuAction.UserProfile}
      >
        <IconFont
          type="icon-profile"
          style={{ fontSize: '25px', marginRight: '10px', paddingTop: '5px' }}
        />
        <Link to={routes.userPage.url}>{t('menu.profile')}</Link>
      </Menu.Item>

      <Menu.Item
        style={{ borderBottom: '1px solid #7d7d7d', marginTop: '0px', marginBottom: '0px' }}
        key={MenuAction.Help}
      >
        <IconFont
          type="icon-help"
          style={{ fontSize: '25px', marginRight: '10px', paddingTop: '5px' }}
        />
        <a target="_blank" href={useSettingValue('link.help')} rel="noreferrer">
          {t('menu.help')}
        </a>
      </Menu.Item>

      <Menu.Item
        onClick={(e) => onItemClick(e.key as MenuAction)}
        key={MenuAction.Logout}
        style={{ marginTop: '0px', marginBottom: '0px' }}
      >
        <IconFont
          type="icon-arrow-exit"
          style={{ fontSize: '25px', marginRight: '10px', paddingTop: '5px' }}
        />
        {t('menu.logout')}
      </Menu.Item>
    </Menu>
  )
}

UserMenu.displayName = 'UserMenu'

export default UserMenu
