import { ErrorCode } from 'types/enums'
export class ApplicationError extends Error implements ApplicationError {
  id: number

  message: string

  text: string

  constructor(id: number, text: string) {
    super()
    this.id = id
    this.text = text
    this.name = 'ApplicationError'
    this.message = this.toString()
  }

  public toString = (): string => `(${this.id}) - ${this.text}`
}

export const ApiErrorType = 'ApiError'

export const isApiError = (response): boolean => {
  try {
    const apiError = response as ApiError
    const { type } = apiError
    return type && type === ApiErrorType;


  } catch (error) {
    return false
  }
}

export const isGraphQlError = (response): boolean => {
  try {
    return !!(response as GraphQlError).errors;

  } catch (error) {
    return false
  }
}

//GG-2020-10-02: maybe there are also other conditions, some api endpoints could
//send different error messages
export const isAuthenticationError = (response): boolean => {
  const K_ACCESS_DENIED = 'AccessDeniedError'
  const K_UNAUTHORIZED = 401

  if (isApiError(response)) {
    return response.status === K_UNAUTHORIZED
  }

  if (!isGraphQlError(response)) {
    const { errors } = response as GraphQlError
    return errors.some((error) => {
      return error.name === K_ACCESS_DENIED
    })
  }

  return false
}

export const getApplicationError = (error: ErrorCode, text: string): ApplicationError => {
  return new ApplicationError(error, text)
}
