import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getApplicationError } from 'core'
import * as appActions from 'store/App/actions'
import { useLoadSettings } from 'services/query/setting'
import { ErrorCode } from 'types/enums'
import { bootstrapLoadingSelector } from 'store/App/selectors'
import { UseQueryResult } from 'react-query'

const BootStrap: React.FC = () => {
  const dispatch = useDispatch()
  const isBootstrapLoading = useSelector(bootstrapLoadingSelector)
  const queries: UseQueryResult[] = []
  /* Add your bootstrap queries here */
  queries.push(useLoadSettings())

  const isSomeQueryLoading = queries.reduce((acc, query) => acc || query.isLoading, false)
  const allQueriesFetched = queries.reduce((acc, query) => acc && query.isFetched, true)
  const queryInError = queries.find((query) => query.isError)
  useEffect(() => {
    if (!isBootstrapLoading || isSomeQueryLoading) {
      return
    }
    if (queryInError) {
      const errorMessage = (queryInError.error as Error)?.message ?? 'Error during bootstrap'
      const error = getApplicationError(ErrorCode.BootstrapFailed, errorMessage)
      dispatch(appActions.setApplicationError(error))
      return
    }
    if (allQueriesFetched) {
      dispatch(appActions.bootstrap())
    }
  }, [isSomeQueryLoading, allQueriesFetched])
  return null
}

BootStrap.displayName = 'BootStrap'
export default BootStrap
