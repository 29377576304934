import React from 'react'
import { StyledLayout } from './Styled'

import MeetingFormSelector from '../../containers/MeetingFormSelector'

const LaunchWebinar = () => (
  <StyledLayout>
    <MeetingFormSelector />
  </StyledLayout>
)

export default LaunchWebinar
