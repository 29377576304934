import { Col, Divider, Row, Typography } from 'antd'
import classNames from 'classnames'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import Description from 'components/Description'
import { CourseDetail as CourseDetailType } from 'types/store'
import InfoCourse from './InfoCourse'
import ThumbnailCourse from './ThumbnailCourse'
import style from './styles.module.scss'

interface Props {
  course: CourseDetailType
  isRollingOut: boolean
  onRollOut: (course: CourseDetailType) => void
}

const CourseDetail: React.FC<Props> = ({ course, isRollingOut, onRollOut }) => {
  const { t } = useTranslation()
  const { title, descriptionTitle, description, nameOfTheTeacher } = course


  return (
    <section className={classNames('app-container', style['course-detail'])}>
      <Row className="mt-50">
        <Col>
          <Typography.Title level={3}>{title}</Typography.Title>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          {nameOfTheTeacher && (
            <Typography.Title className="author" level={5}>
              {t('courses.authorCourse', { p0: nameOfTheTeacher })}
            </Typography.Title>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={15}>
          <ThumbnailCourse src={course.thumbnailImg || '/images/course-fallback.png'} />
        </Col>
        <Col span={7}>
          <InfoCourse course={course} isRollingOut={isRollingOut} onRollOut={onRollOut} />
        </Col>
      </Row>
      <Row>
        <Col span={18}>
          <Description title={descriptionTitle} body={description} />
        </Col>
      </Row>
      <Row className="pb-30">
        <Col md={1} />
        {course.modules.length <= 0 ? (
          <Col span={17}>
            <Row>
              <Typography.Title level={4}>{t('courses.noModules')}</Typography.Title>
            </Row>
          </Col>
        ) : (
          <Col span={17}>
            <Row>
              <Typography.Title level={4}>{t('general.content')}:</Typography.Title>
            </Row>
            <Divider className="module-divider" />
            {course.modules.map((module) => (
              <Fragment key={module.code}>
                <Row>
                  <Col md={14}>
                    <Typography.Title className="module-title" level={5}>
                      {module.title}
                    </Typography.Title>
                  </Col>
                  {module.lessons.length <= 0 ? (
                    <Col md={10}>
                      <Typography.Title level={5}>{t('courses.noModuleLectures')}</Typography.Title>
                    </Col>
                  ) : (
                    <Col md={10}>
                      {module.lessons.map((lesson) => (
                        <Row key={lesson.code}>
                          <Typography.Text className="lesson-title">{lesson.title}</Typography.Text>
                        </Row>
                      ))}
                    </Col>
                  )}
                </Row>
                <Divider className="module-divider" />
              </Fragment>
            ))}
          </Col>
        )}
      </Row>
    </section>
  )
}

CourseDetail.displayName = 'CourseDetail'

export default CourseDetail
