declare let window
const {localStorage} = window

const key = 'USE_API_MOCKS'

export const apiProvider = function provider(baseUrl: string) {
  let language: string = null

  const useApiMocksStorage = localStorage.getItem(key)
  if (useApiMocksStorage === null) {
    localStorage.setItem(key, 'false')
  }
  return {
    api: {
      //server resources
      vocabulary: () => `/to-be-done`,
      //local resources
      localVocabulary: () => `/translations/{{lng}}/labels.json`,

      //user api
      login: () => `${baseUrl}/auth/cognito/login`,
      register: () => `${baseUrl}/auth/cognito/registration`,
      refreshToken: () => `${baseUrl}/auth/refresh-token`,
      registration: () => `${baseUrl}/register`,

      getCategories: () => `${baseUrl}/categories`,
      getCompanies: () => `/mocks/companies.json`,
      getCompanyById: (id: number) => `${baseUrl}/companies/${id}`,
      getCourses: () => `${baseUrl}/courses`,
      getCourseById: (id: number) => `${baseUrl}/courses/${id}`,
      getBscCertificateByTransactionHash: (transactionHash: string) => `${baseUrl}/bsc/transactions/${transactionHash}`,
      getEnrolledCoursesByUserId: (userId: number) =>
        `${baseUrl}/users/${userId}/enrolled-courses`,
      geFullQuizByCourseId: (courseId: number) => `${baseUrl}/courses/${courseId}/quiz/full`,
      geFullQuizByLearningPathId: (learningPathId: number) => `${baseUrl}/learning-paths/${learningPathId}/quiz/full`,
      getReservedUsersByCourseIdAndEditionId: (courseId: number, editionId: number) =>
        `${baseUrl}/courses/${courseId}/editions/${editionId}/reserved-users`,
      putSubscribeWebinarByWebinarIdAndEditionIdAndUserId: (
        webinarId: number,
        editionId: number,
        userId: number
      ) => `${baseUrl}/courses/${webinarId}/editions/${editionId}/reserved-users/${userId}`,

      getAttendeeNameByWebinarCodeAndEditionCodeAndAttendeeId: (
        webinarId: number,
        editionId: number,
        attendeeId: string
      ) => `${baseUrl}/courses/${webinarId}/editions/${editionId}/reserved-users/${attendeeId}`,


      postQuizAttempt: (
        courseId: number,
        quizId: number,
        userId: number
      ) => `${baseUrl}/courses/${courseId}/quizzes/${quizId}/users/${userId}/attempts`,

      postLearningPathQuizAttempt: (
        learningPathId: number,
        quizId: number,
        userId: number
      ) => `${baseUrl}/learning-paths/${learningPathId}/quizzes/${quizId}/users/${userId}/attempts`,

      postBscTransaction: (courseId: number, userId: number) => `${baseUrl}/bsc/courses/${courseId}/users/${userId}`,
      postLearningPathBscTransaction: (learningPathId: number, userId: number) => `${baseUrl}/bsc/learning-paths/${learningPathId}/users/${userId}`,

      putEnrolledCourseByUserId: (courseId: number, userId: number) =>
        `${baseUrl}/users/${userId}/enrolled-courses/${courseId}`,
      postCompletedLectureByUserId: (lectureId: number, userId: number) =>
        `${baseUrl}/users/${userId}/completed-lectures/${lectureId}`,
      getWebinars: () => `${baseUrl}/courses`,
      getWebinarById: (webinarId: number) => `${baseUrl}/courses/${webinarId}`,
      getEditions: (webinarId: number) => `${baseUrl}/courses/${webinarId}/editions`,
      launchEditionChime: (webinarId: number, editionId: number) =>
        `${baseUrl}/courses/${webinarId}/editions/${editionId}/launch`,
      joinEditionChime: (webinarId: number, editionId: number) =>
        `${baseUrl}/courses/${webinarId}/editions/${editionId}/join`,
      endEditionChime: (webinarId: number, editionId: number) =>
        `${baseUrl}/courses/${webinarId}/editions/${editionId}/end`,

      postCompletedEdition: (userId: number, webinarId: number, editionId: number) =>
        `${baseUrl}/users/${userId}/courses/${webinarId}/completed-editions/${editionId}`,

      getAllCompanyBusinessUnits: (id: string) => `${baseUrl}/companies/${id}/business-units`,
      getCompanyBusinessUnitByCode: (id: string, businessUnitCode: string) =>
        `${baseUrl}/companies/${id}/business-units/${businessUnitCode}`,
      getUsers: () => `${baseUrl}/users`,
      getMyUser: () => `${baseUrl}/users/me`,
      editUserAgreement: () => `${baseUrl}/users/me/agreement`,
      getUserAgreement: () => `${baseUrl}/users/me/agreement`,
      getUserById: (userId: number) => `${baseUrl}/users/${userId}`,
      getLearningPathsByUser: (userId: number) => `${baseUrl}/users/${userId}/learning-paths`,
      getLearningPathById: (userId: number, learningPathId: number) => `${baseUrl}/users/${userId}/learning-paths/${learningPathId}`,
      getSettings: () => `${baseUrl}/configuration`,
    },
    getLanguage: () => language,
    setLanguage: (lng: string): void => {
      language = lng
    },
  }

}
