import React from 'react'

import WebinarCarousel from 'components/WebinarCarousel'
import { useLoadWebinarList } from 'services/query'

const WebinarCarouselContainer: React.FC = () => {
  const { isFetched, isLoading, isError, data: webinarList } = useLoadWebinarList()
  if (isError) {
    return null
  }

  if (!isFetched || isLoading || isError) {
    return null
  }

  if (webinarList.length <= 0) {
    return null
  }
  return <WebinarCarousel webinars={webinarList} />
}

WebinarCarouselContainer.displayName = 'WebinarCarouselContainer'

export default WebinarCarouselContainer
