import React from 'react'
import {useLoadLearningPathFullQuiz, useLoadLearningPathList} from 'services/query'
import {LearningPath} from 'types/store'
import {Spinner} from "amazon-chime-sdk-component-library-react";
import {Button, Typography} from "antd";
import {generatePath, Link} from "react-router-dom";
import routes from "../../config/routes";
import {useTranslation} from "react-i18next";
import LearningPathQuiz from "../../components/LearningPathQuiz";

interface Props {
  code: LearningPath['code']
}

const LearningPathQuizContainer: React.FC<Props> = ({code}) => {
  const {t} = useTranslation()

  const {
    isFetched: isLearningPathFetched,
    isLoading: isLearningPathLoading,
    isError: isLearningPathError,
    data: learningPaths
  } = useLoadLearningPathList()

  const {
    data: quiz,
    isError: isQuizError,
    isLoading: isQuizLoading,
    isFetched: isQuizFetched
  } = useLoadLearningPathFullQuiz(+code)

  if (isQuizLoading || !isQuizFetched || !isLearningPathFetched || isLearningPathLoading)
    return <Spinner width="1.5rem" height="1.5rem"/>

  if (isQuizError || isLearningPathError)
    return null

  const learningPath = learningPaths.find(lp => lp.code === code)

  const isLearningPathCompleted = learningPath.allCoursesCompleted
  const isQuizCompleted = learningPath.successfulAttempt
  const {isQuizExpired, quizExpirationDate} = learningPath

  console.log('+++', learningPath)

  const {nrOfQuestions} = quiz

  const questions = []

  quiz.questions.forEach((q, index) => {
    if (index < nrOfQuestions)
      questions.push(q)
  })

  quiz.questions = questions


  const message = quiz ?
    isLearningPathCompleted ?
      (isQuizCompleted ? `Hai già completato il quiz del percorso "${learningPath.title}"` : "") : `Devi completare il percorso "${learningPath.title}" per poter accedere al quiz` : `Non esiste un quiz per il percorso ${learningPath.title}`


  return (
    <>
      {
        isLearningPathCompleted && !isQuizCompleted && !isQuizExpired ? quiz &&
          <>
            {quizExpirationDate != null && <p>
              {t('courses.quizExpirationDate', {p0: quizExpirationDate.toString().split('T')[0]})}
            </p>
            }
            <LearningPathQuiz quizData={quiz}/>
          </>
          :
          (<Typography.Title level={2}>{message}</Typography.Title>)
      }
      {
        isQuizExpired &&
        (<Typography.Title level={2}>
          {t('courses.quizExpired')}
        </Typography.Title>)
      }
      {
        isLearningPathCompleted && isQuizCompleted &&
        <Button className={"mt-50"} type="primary" block>
          <Link to={generatePath(routes.learningPathCertificate.url, {code: "" + code})}>
            {t('courses.completed')}
          </Link>
        </Button>
      }


    </>
  )
}

LearningPathQuizContainer.displayName = 'LearningPathQuizContainer'

export default LearningPathQuizContainer
