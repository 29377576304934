import React from 'react'

import { Flex, PrimaryButton } from 'amazon-chime-sdk-component-library-react'

import { useMeetingState } from '../../providers/MeetingStateProvider'

const MeetingDetails = () => {
  const { toggleTheme, theme } = useMeetingState()

  return (
    <Flex container layout="fill-space-centered">
      <Flex>
        <PrimaryButton
          label={theme === 'light' ? 'Dark mode' : 'Light mode'}
          onClick={toggleTheme}>
        </PrimaryButton>
      </Flex>
    </Flex>
  )
}

export default MeetingDetails
