import { ApplicationError } from 'core'
import * as K from './constants'
import { AppActionTypes } from './types'
import { UserAgreement } from '../../types/store'

export function bootstrap(): AppActionTypes {
  return {
    type: K.BOOTSTRAP,
  }
}

export function logout(): AppActionTypes {
  return {
    type: K.LOGOUT,
  }
}

export function setApplicationError(error: ApplicationError): AppActionTypes {
  return {
    type: K.SET_APPLICATION_ERROR,
    payload: error,
  }
}

export function setApplicationHeader(header: boolean): AppActionTypes {
  return {
    type: K.SET_APPLICATION_HEADER,
    payload: header,
  }
}

export function setUserAgreement(data: UserAgreement): AppActionTypes {

  return {
    type: K.SET_USER_AGREEMENT,
    payload: data,
  }
}
