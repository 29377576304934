import React from 'react'
import { useTranslation } from 'react-i18next'
import style from './styles.module.scss'
import { Form, Input, Typography } from 'antd'

interface Props {
  name: string
  surname: string
  email: string
}

const UserPanel: React.FC<Props> = ({ name, surname, email }) => {
  const { t } = useTranslation()

  return (
    <section className={style['user-profile']}>
      <div className="app-container">
        <Typography.Title className="panel-title" level={4}>
          {t('userProfile.employeeProfile')}
        </Typography.Title>

        <Form className="user-panel-form" initialValues={{ remember: true }}>
          <Typography.Paragraph className="details-title">
            {t('userProfile.userDetails')}
          </Typography.Paragraph>

          <Form.Item className="name" name="name">
            <Input disabled type="text" placeholder={name} />
          </Form.Item>

          <Form.Item className="surname" name="surname">
            <Input disabled type="text" placeholder={surname} />
          </Form.Item>

          <Form.Item className="role" name="role">
            <Input disabled type="email" placeholder={email} />
          </Form.Item>

          <Form.Item className="linkedin" name="linkedin">
            <Input type="text" placeholder={t('userProfile.linkedinPlaceholder')} />
          </Form.Item>
        </Form>
      </div>
    </section>
  )
}

UserPanel.displayName = 'UserProfile'

export default UserPanel
