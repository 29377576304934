import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CourseVideo, { CourseVideoPlaceholder } from 'components/CourseVideo'
import { setCurrentLesson } from 'store/Course/actions'
import { useLoadCourseByCode, useLoadCourseLessonList, useMutateCourseLesson } from 'services/query'
import { currentLessonSelector } from 'store/Course/selectors'
import { CourseDetail } from 'types/store'

interface Props {
  course: CourseDetail
}

const CourseVideoContainer: React.FC<Props> = ({ course }) => {
  const dispatch = useDispatch()
  const {code} = course
  const currentLesson = useSelector(currentLessonSelector)
  const { data: lessonList } = useLoadCourseLessonList(code)

  const mutateLesson = useMutateCourseLesson(code)

  const setLessonCompleted = useCallback(() => {
    if (!currentLesson?.code) {
      return
    }
    mutateLesson({ lessonCode: currentLesson.code, progress: 1 })
    const currIndex = lessonList.findIndex((lesson) => lesson.code === currentLesson.code)
    if (currIndex < lessonList.length - 1) {
      const nextLesson = lessonList[currIndex + 1]
      dispatch(setCurrentLesson(nextLesson))
    }
  }, [code, currentLesson?.code])

  const updateProgress = useCallback(
    (progress: number) => {
      if (!currentLesson?.code) {
        return
      }
      if (currentLesson.completed) {
        // dont flag the progress if the lesson is already completed
        return
      }
      mutateLesson({ lessonCode: currentLesson.code, progress })
    },
    [code, currentLesson?.code]
  )

  if (!currentLesson || !currentLesson.videoUrl) {
    return <CourseVideoPlaceholder />
  }

  return (
    <CourseVideo
      url={currentLesson.videoUrl}
      title={course.title}
      checkUserAttention={course.checkUserAttention}
      caption={currentLesson.title}
      onProgress={updateProgress}
      onCompleted={setLessonCompleted}
    />
  )
}

CourseVideoContainer.displayName = 'CourseVideoContainer'

export default CourseVideoContainer
