import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import routes from 'config/routes'
import WelcomeHeader from 'components/WelcomeHeader'
import { filterToQuery } from 'services/navigation/course'
import { useLoadCurrentUser } from 'services/query/user'

const WelcomeHeaderContainer: React.FC = () => {
  const navigate = useNavigate()
  const userQuery = useLoadCurrentUser()

  const onSearch = useCallback((text) => {
    navigate({
      pathname: routes.coursesOverall.url,
      search: filterToQuery({ search: text }),
    })
  }, [])

  const { isFetched, isLoading, isError, data: user } = userQuery

  if (isError) {
    return null
  }

  if (!isFetched || isLoading) {
    return null
  }

  return <WelcomeHeader name={user?.name ?? ''} onSearch={onSearch} />
}
WelcomeHeaderContainer.displayName = 'WelcomeHeaderContainer'

export default WelcomeHeaderContainer
