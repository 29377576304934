import { CourseStatus } from 'types/enums'
import {
  Address,
  Category,
  Company,
  Contact,
  Course,
  CourseDetail,
  Progression,
  UserRole,
  Webinar,
  WebinarDetail,
} from 'types/store'

export const mapBoolean = (value: boolean | null): boolean => {
  return value
}

export const mapDate = (value: string | null): Date => {
  const timestamp = Date.parse(value)
  if (isNaN(timestamp)) {
    return null
  }
  return new Date(timestamp)
}

export const mapString = (value: string | null): string => {
  if (!value) {
    return ''
  }
  return value
}

export const mapNumber = (value: number | null): number => {
  if (!value) {
    return 0
  }
  return Number(value)
}

export const categoryPlaceholder: Category = {
  code: '',
  name: '',
  description: '',
  thumbnailImg: '',
  color: 'transparent',
}

export const coursePlaceholder: Course = {
  code: '',
  title: '',
  thumbnailImg: '',
  mandatory: false,
  started: false,
  rolledOut: false,
  checkUserAttention: false,
  category: categoryPlaceholder,
  descriptionTitle: '',
  description: '',
  duration: 0,
  dueDate: null,
  creationDate: null,
  enrollmentDate: null,
  progressPercentage: null,
  courseType: null,
  successfulAttempt: null,
  hasQuiz: null,
  isQuizExpired: null,
  quizExpirationDate: null,
  transactionHash: null,
}

export const courseDetailPlaceholder: CourseDetail = {
  ...coursePlaceholder,
  teacher: {
    id: 0,
    email: '',
    name: '',
    surname: '',
    businessUnit: {
      code: '',
      name: '',
    },
    roles: [UserRole.STANDARD_USER],
  },
  nameOfTheTeacher: '',
  lessonCount: 0,
  hasQuiz: false,
  modules: [],
}

export const blockchainCourseCertificationDataPlaceholder = {
  courseName: "",
  userName: "",
  userSurname: "",
  email: "",
  transactionHash: ""
}

export const webinarPlaceholder: Webinar = {
  code: '',
  title: '',
  teacher: {
    id: 0,
    email: '',
    name: '',
    surname: '',
    businessUnit: {
      code: '',
      name: '',
    },
    roles: [UserRole.STANDARD_USER],
  },
  nameOfTheTeacher: '',
  thumbnailImg: '',
  status: CourseStatus.NotStarted,
}

export const progressionPlaceholder: Progression = {
  completedLessons: 0,
  completedCourses: 0,
  watchedWebinars: 0,
  completedWebinars: 0,
  attendedSeconds: 0,
}

export const webinarDetailPlaceholder: WebinarDetail = {
  ...webinarPlaceholder,
  descriptionTitle: '',
  description: '',
}

export const addressDefault = {
  street: '',
  number: '',
  zip: '',
  city: '',
  country: '',
} as Address

export const contactDefault = {
  name: '',
  surname: '',
  email: '',
  telephone: '',
} as Contact

export const companyDefault = {
  id: -1,
  name: '',
  vat: '',
  taxCode: '',
  legalAddress: addressDefault,
  billingAddress: addressDefault,
  primaryContact: contactDefault,
  notes: '',
} as Company
