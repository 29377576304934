import {Button, PageHeader, Row} from 'antd'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Course} from 'types/store'
import style from './styles.module.scss'
import {generatePath, Link} from "react-router-dom";
import routes from "../../config/routes";

interface Props {
  code: string
  title: string
  dueDate: Date | null
  courses: Course[]
  type: "LEARNING_PATH" | "COURSE"
}

const QuizStrip: React.FC<Props> = ({
                                      code,
                                      title,
                                      dueDate,
                                      courses,
                                      type
                                    }) => {
  const {t} = useTranslation()

  return (
    <section className={style['learning-path-strip']}>
      <Row justify='space-between' align='middle'>

        {type === 'LEARNING_PATH' && <PageHeader
          title={t('courses.stripTitle', {p0: title, p1: courses.length, count: courses.length})}
          subTitle={
            dueDate && t('courses.stripDueDate', {p0: moment(dueDate).format('DD/MM/YYYY')})
          }
        />}

        {type === 'COURSE' && <PageHeader
          title={title}
          subTitle={
            dueDate && t('courses.stripDueDate', {p0: moment(dueDate).format('DD/MM/YYYY')})
          }
        />}

        <Button type="primary">
          {type === 'LEARNING_PATH' && <Link to={generatePath(routes.learningPathQuiz.url, {code: code})}>
            {t('courses.quiz')}
          </Link>}

          {type === 'COURSE' && <Link to={generatePath(routes.courseQuiz.url, {code: code})}>
            {t('courses.quiz')}
          </Link>}
        </Button>
      </Row>

      {/*<Row gutter={[30, 30]}>*/}
      {/*  {courses.map((course) => (*/}
      {/*    <Col key={course.code} xs={24} md={12} lg={6} className="course-card-container">*/}
      {/*      <CourseCard course={course}/>*/}
      {/*    </Col>*/}
      {/*  ))}*/}
      {/*</Row>*/}
    </section>
  )
}

QuizStrip.displayName = 'LearningPathStrip'

export default QuizStrip
