import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const Seo: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Helmet>
      <link rel="icon" href="/logo192.png" sizes="32x32" />
      <link rel="icon" href="/logo512.png" sizes="192x192" />
      <link rel="apple-touch-icon" href="/logo512.png" />
      <title>{t('general.headTitle')}</title>
    </Helmet>
  )
}

export default Seo
