import { Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Masonry from 'react-masonry-css'
import { Category } from 'types/store'
import CategoryCard from './CategoryCard'
import style from './styles.module.scss'

interface Props {
  categories: Category[]
  onCategoryClick: (category: Category) => void
}

const CategoryMosaic: React.FC<Props> = ({ categories, onCategoryClick }) => {
  const { t } = useTranslation()

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  }

  return (
    <>
      <Typography.Title className="course-category" level={2}>
        {t('home.coursesByCategory')}
      </Typography.Title>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className={style['my-masonry-grid']}
        columnClassName="my-masonry-grid_column"
      >
        {categories &&
          categories.map((category: Category) => (
            <CategoryCard
              key={category.code}
              category={category}
              onCategoryClick={onCategoryClick}
            />
          ))}
      </Masonry>
    </>
  )
}

CategoryMosaic.displayName = 'CategoryMosaic'

export default CategoryMosaic
