import * as K from './constants'
import { AppActionTypes, AppState } from './types'

// The initial state of the Reducer
export const initialState: AppState = {
  bootstrapLoading: true,
  user: null,
  userAgreement: null,
  error: null,
  header: true,
}

const reducer = (state = initialState, action: AppActionTypes): AppState => {
  switch (action.type) {
    case K.BOOTSTRAP:
      return {
        ...state,
        bootstrapLoading: false,
      }
    case K.LOGOUT:
      return {
        ...state,
        user: initialState.user,
        error: initialState.error,
      }
    case K.SET_APPLICATION_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case K.SET_APPLICATION_HEADER:
      return {
        ...state,
        header: action.payload,
      }
    case K.SET_USER_AGREEMENT:
      return {
        ...state,
        userAgreement: action.payload,
      }
    default:
      return state
  }
}

export default reducer
