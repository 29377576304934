import React from 'react'
import { useTranslation } from 'react-i18next'

import { Webinar } from 'types/store'

import { Typography, Carousel } from 'antd'

import style from './styles.module.scss'
import WebinarCard from 'components/WebinarCard'

interface Props {
  webinars: Array<Webinar>
}

const WebinarCarousel: React.FC<Props> = ({ webinars }) => {
  const { t } = useTranslation()
  const carouselSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    draggable: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  }

  return (
    <section className={style['webinar-carousel']}>
      <div className="app-container mt-70">
        <Typography.Title className="title pb-10"> {t('general.webinar')}</Typography.Title>
      </div>
      <Carousel {...carouselSettings}>
        {webinars.map((item) => {
          return <WebinarCard item={item} key={item.code} />
        })}
      </Carousel>
    </section>
  )
}

WebinarCarousel.displayName = 'WebinarCarousel'

export default WebinarCarousel
