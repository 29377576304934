import React from 'react'
import UserProfile from 'components/User/UserProfile'
import { useLoadCurrentUser } from 'services/query/user'

import style from './styles.module.scss'

const UserPage: React.FC = () => {
  const userQuery = useLoadCurrentUser()
  const { data: user } = userQuery

  return (
    <section className={style['user-page']}>
      <div className="app-container">
        <UserProfile
          name={user?.name ?? ''}
          surname={user?.surname ?? ''}
          email={user?.email ?? ''}
        />
      </div>
    </section>
  )
}

export default UserPage
