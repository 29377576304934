function createStructured(labels, value, object) {
  const property = labels.pop()
  if (labels.length === 0) {
    object[property] = value
    return
  }

  if (Object.prototype.hasOwnProperty.call(object, property)) {
    createStructured(labels, value, object[property])
  } else {
    object[property] = {}
    createStructured(labels, value, object[property])
  }
}

export const labelParser = (data: string) => {
  if (!data) {
    return {}
  }
  const parsed = JSON.parse(data)

  const normalized = parsed.data.config_fe_text_resources.reduce((prev, current) => {
    prev[current.key] = current.value
    return prev
  }, {})

  const structured = {}

  Object.keys(normalized).forEach((key) => {
    try {
      const value = normalized[key]
      const labels = key.split('.').reverse()
      return createStructured(labels, value, structured)
    } catch (e) {
      console.log(`labelParser exception: label=${key}`, e)
    }
  })

  return structured
}

// const postRequest = (url, data) => {
//   return fetch(url, {
//     method: 'POST',
//     body: JSON.stringify(data),
//     //    headers: new Headers(authHeader.headers),
//   })
// }

export const getLabelRequest = () => {
  return (options, url, payload, callback) => {
    // TBD
    // const data = {
    //   query: `query {
    //     config_fe_text_resources {
    //       id
    //       key
    //       value
    //     }
    //   }`,
    // }
    // const getData = async () => {
    //   try {
    //     const response: Response = await postRequest(url, data)
    //     if (!response.ok) {
    //       return callback(response.statusText || 'Error', { status: response.status })
    //     }
    //     const text = await response.text()
    //     const output = {
    //       status: response.status,
    //       data: text,
    //     }
    //     callback(null, output)
    //   } catch (error) {
    //     return callback
    //   }
    // }
    // getData()
  }
}
