import React from 'react'
import { Card, Skeleton } from 'antd'
import style from './styles.module.scss'
import classNames from 'classnames'

const CourseCard: React.FC = () => {
  return (
    <Card className={style['course-card']} cover={<Skeleton.Image className="img-skeleton" />}>
      <Skeleton active />
      <Skeleton.Button active className={classNames(['mt-50', 'button-skeleton'])} />
    </Card>
  )
}

CourseCard.displayName = 'CourseCard'

export default CourseCard
