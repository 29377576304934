import { Button, Progress, Col, Typography } from 'antd'
import routes from 'config/routes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { Course, LearningPath } from 'types/store'
import style from './styles.module.scss'

const { Title } = Typography

interface Props {
  learningPath: LearningPath
}

const LearningPathListItem: React.FC<Props> = ({ learningPath }) => {
  const { title, courses } = learningPath
  const navigate = useNavigate()
  const { t } = useTranslation()
  const onClick = () => {
    const firstNotCompleted = learningPath.courses.find((course) => course.progressPercentage < 1)
    if (!firstNotCompleted) {
      return
    }
    if (firstNotCompleted.courseType === 'Webinar') {
      navigate(generatePath(routes.webinarDetail.url, { code: firstNotCompleted.code }))
    } else {
      navigate(generatePath(routes.courseDetail.url, { code: firstNotCompleted.code }))
    }
  }

  const { totalDuration, progressDuration } = courses.reduce(
    (acc, course: Course) => {
      const { duration, progressPercentage } = course
      acc.totalDuration += duration
      acc.progressDuration += duration * progressPercentage
      return acc
    },
    { totalDuration: 0, progressDuration: 0 }
  )
  const progressPercentage = Math.round((progressDuration / totalDuration) * 100) / 100

  return (
    <Col className={style['learning-path-list-item']}>
      <div>
        <Title level={5}>{title}</Title>
        <div className="info-list">
          <Progress percent={progressPercentage * 100} />
          {(() => {
            if (progressPercentage === 0)
              return (
                <Button type="primary" block onClick={onClick}>
                  {t('learningPaths.start')}
                </Button>
              )
            if (progressPercentage < 1)
              return (
                <Button type="primary" block onClick={onClick}>
                  {t('learningPaths.continue')}
                </Button>
              )
          })()}
        </div>
      </div>
    </Col>
  )
}

LearningPathListItem.displayName = 'CourseList'

export default LearningPathListItem
