import { Typography } from 'antd'
import React from 'react'
import { Category } from 'types/store'

interface Props {
  category: Category
  onCategoryClick: (category: Category) => void
}

const CategoryCard: React.FC<Props> = ({ category, onCategoryClick }) => {
  const onClick = () => onCategoryClick(category)
  return (
    <div
      className="mosaic-container"
      style={{ backgroundColor: category.color }}
      role="button"
      onClick={onClick}
    >
      <img className="category-image" src={category.thumbnailImg} alt={category.name} />
      <div className="text-centered">
        <Typography.Title level={2}>{category.name}</Typography.Title>
      </div>
    </div>
  )
}

CategoryCard.displayName = 'CategoryCard'

export default CategoryCard
