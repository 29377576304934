import React from 'react'
import { Tabs, Typography } from 'antd'

import { useTranslation } from 'react-i18next'
import style from './styles.module.scss'
import { WebinarDetail as WebinarDetailType } from 'types/store'
import ReactMarkdown from 'react-markdown'

interface Props {
  webinar: WebinarDetailType
}

const Info: React.FC<Props> = ({ webinar }) => {
  const { TabPane } = Tabs
  const { t } = useTranslation()

  return (
    <Tabs className={style['info']} defaultActiveKey="0">
      <TabPane tab={t('general.description')} key="0">
        <Typography.Title level={2}>{webinar.title}</Typography.Title>
        <Typography.Paragraph className="description">
          {t('general.with')}
          {webinar.teacher.name} {webinar.teacher.surname}
        </Typography.Paragraph>
        <Typography.Paragraph className="description text">
          <ReactMarkdown>{webinar.descriptionTitle}</ReactMarkdown>
        </Typography.Paragraph>
        <Typography.Paragraph className="description text">
          <ReactMarkdown>{webinar.description}</ReactMarkdown>
        </Typography.Paragraph>
        {/* <Typography.Paragraph className="description"> {t('general.teacher')}</Typography.Paragraph>
        <Typography.Paragraph className="description ">{webinar.description}</Typography.Paragraph> */}
      </TabPane>
      {/* <TabPane tab={t('general.program')} key="1">
        {t('general.program')}
      </TabPane> */}
    </Tabs>
  )
}

Info.displayName = 'Info'

export default Info
