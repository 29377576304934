import React from 'react'

import MeetingRoster from '../MeetingRoster'
import Navigation from '.'
import { useNavigate } from '../../providers/NavigationProvider'

const NavigationControl = () => {
  const { showNavbar, showRoster } = useNavigate()

  return (
    <>
      {showNavbar ? <Navigation /> : null}
      {showRoster ? <MeetingRoster /> : null}
    </>
  )
}

export default NavigationControl
