import React from 'react'
import LearningPathListContainer from 'containers/LearningPathList'
import WelcomeHeaderContainer from 'containers/WelcomeHeader'
import KeepClimbingContainer from 'containers/KeepClimbing'
import WebinarCarouselContainer from 'containers/WebinarCarousel'
import CategoryMosaicContainer from 'containers/CategoryMosaic'

const HomePage: React.FC = () => {
  return (
    <section>
      <WelcomeHeaderContainer />
      <section className="app-container my-30">
        <LearningPathListContainer />
      </section>

      <WebinarCarouselContainer />
      <section className="app-container my-30">
        <KeepClimbingContainer />
      </section>
      <section className="app-container my-30">
        <CategoryMosaicContainer />
      </section>
    </section>
  )
}

export default HomePage
