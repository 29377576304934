import {Button, Col, Divider, Row, Typography} from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CourseLink, { CourseLinkType } from 'components/CourseLink'
import { CourseDetail } from 'types/store'
import style from './styles.module.scss'
import {generatePath, Link} from "react-router-dom";
import routes from "../../../config/routes";

interface Props {
  course: CourseDetail
  isRollingOut: boolean
  onRollOut: (course: CourseDetail) => void
}

const InfoCourse: React.FC<Props> = ({ course, isRollingOut, onRollOut }) => {
  const { duration, category, lessonCount, hasQuiz, successfulAttempt, progressPercentage} = course
  const { t } = useTranslation()
  const durationSeconds = duration % 60
  const durationMinutes = ((duration - durationSeconds) / 60) % 60
  const durationHours = Math.floor((duration - durationMinutes * 60) / 3600)

  const totalDurationLiteral = t('courses.durationHM', {
    p0: durationHours,
    p1: `${durationMinutes}`.padStart(2, '0'),
  })
  return (
    <section className={style['info-course']}>
      <Row className="ml-20">
        <Col md={24}>
          <CourseLink
            type={CourseLinkType.rollOut}
            course={course}
            loading={isRollingOut}
            onRollOut={onRollOut}
          />
        </Col>
      </Row>
      {
        hasQuiz && progressPercentage >=1 &&
        <>
          <Divider />
          <Row className="ml-20">
            <Col md={24}>
              <Button type="primary" block>
                <Link to={generatePath(routes.courseVideo.url, { code: course.code })}>
                  {t('Vai alle lezioni')}
                </Link>
              </Button>
            </Col>
          </Row>
        </>
      }
      <Row className=" ml-20 mt-20">
        <Col md={24}>
          <Typography.Paragraph>
            {t('courses.durationLesson', {
              count: lessonCount,
              p0: lessonCount,
              p1: totalDurationLiteral,
            })}
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row className="ml-20 mt-10">
        <Col md={24}>
          <Typography.Paragraph>
            {t('general.category')}: {category.name}
          </Typography.Paragraph>
        </Col>
      </Row>
    </section>
  )
}

InfoCourse.displayName = 'InfoCourse'

export default InfoCourse
