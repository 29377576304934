import { LoadingState } from 'types/enums'
import { WebinarEdition } from './webinar'

export enum UserRole {
  ADMIN = 'ADMIN',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  COMPANY_PM = 'COMPANY_PM',
  COMPANY_TEACHER = 'COMPANY_TEACHER',
  COMPANY_USER = 'COMPANY_USER',
  STANDARD_TEACHER = 'STANDARD_TEACHER',
  STANDARD_USER = 'STANDARD_USER',
}
export interface Tokens {
  token: string | null
  refreshToken: string | null
}

export interface UserState {
  tokens: Tokens
  state: LoadingState
}

export interface BusinessUnit {
  code: string
  name: string
}

export interface User {
  id: number
  companyId: number
  email: string
  name: string
  surname: string
  businessUnit: BusinessUnit
  roles: UserRole[]
  editions: WebinarEdition[]
  userAgreement?: UserAgreement
}

export interface UserAgreement {
  userPolicy: boolean
  visitorPolicyA: boolean
  visitorPolicyB: boolean
  termsAndConditions: boolean
  webinarRecording: boolean
}

export interface Address {
  street: string
  number: string
  zip: string
  city: string
  county: string
  country: string
}

export interface Contact {
  name: string
  surname: string
  email: string
  telephone: string
}

export interface Company {
  id: number
  name: string
  vat: string
  taxCode: string
  legalAddress: Address
  billingAddress: Address
  primaryContact: Contact
  notes: string
  thumbnailUrl: string
}

export interface Progression {
  completedLessons: number
  completedCourses: number
  watchedWebinars: number
  completedWebinars: number
  attendedSeconds: number
}
