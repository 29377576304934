import { notification } from 'antd'
import Debug from 'debug'
import {
  AuthError,
  AuthErrorCodes,
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PasswordPanel from 'components/User/PasswordPanel'

const debug = Debug('app:containers:ChangePasswordContainer')

const ChangePasswordContainer: React.FC = () => {
  const { t } = useTranslation()

  const showError = (error?: Error) => {
    let description
    const authError = (error as AuthError).code?.startsWith('auth/') ? (error as AuthError) : null
    if (authError) {
      debug(`Error from firebase ${authError.code}`)
      switch (authError.code) {
        case AuthErrorCodes.USER_DISABLED:
          description = t('login.errors.disabled')
          break
        case AuthErrorCodes.INVALID_EMAIL:
          description = t('login.errors.email')
          break
        case AuthErrorCodes.UNVERIFIED_EMAIL:
        case AuthErrorCodes.UNVERIFIED_EMAIL:
        case AuthErrorCodes.USER_DELETED: // aka user-not-found
        case AuthErrorCodes.NULL_USER:
          description = t('login.errors.emailVerify')
          break
        case AuthErrorCodes.INVALID_PASSWORD:
          description = t('login.errors.password')
          break
        default:
          description = t('login.errors.unknown')
      }
    } else {
      description = t('login.errors.unknown')
    }

    notification.config({
      placement: 'topRight',
      bottom: 50,
      duration: 3,
    })
    notification.error({
      message: t('notification.type.error'),
      description,
    })
  }

  const showSuccess = () => {
    notification.config({
      placement: 'topRight',
      bottom: 50,
      duration: 3,
    })
    notification.success({
      message: t('login.success.changePassword'),
    })
  }

  const onChangePasswordRequested = async (oldPassword: string, newPassword: string) => {
    const auth = getAuth()

    const user = auth.currentUser
    try {
      const credential = EmailAuthProvider.credential(user.email, oldPassword)
      await reauthenticateWithCredential(user, credential)
      await updatePassword(user, newPassword)
      showSuccess()
    } catch (error) {
      showError(error)
    }
  }

  return <PasswordPanel onChangeRequested={onChangePasswordRequested} />
}

ChangePasswordContainer.displayName = 'ChangePasswordContainer'

export default ChangePasswordContainer
